import { useState } from 'react';

export default function UseToken() {

  const getAccessToken = () => {
    const tokenString = localStorage.getItem('accessToken');
    const userToken = JSON.parse(tokenString);
    return userToken
  };
  const getIdToken = () => {
    const tokenString = localStorage.getItem('idToken');
    const userToken = JSON.parse(tokenString);
    return userToken
  };
  const getEmail = () => {
    return localStorage.getItem('email');
  }
  const getHawkeyeAccessToken = () => {
    const tokenString = localStorage.getItem('hawkeyeAccessToken');
    const userToken = JSON.parse(tokenString);
    return userToken
  };

  const getOutputHawkeyeAccessToken = () => {
    const tokenString = localStorage.getItem('outputHawkeyeAccessToken');
    const userToken = JSON.parse(tokenString);
    return userToken
  };

  const [accessToken, setAccessToken] = useState(getAccessToken());
  const [idToken, setIdToken] = useState(getIdToken());
  const [hawkeyeAccessToken, setHawkeyeAccessToken] = useState(getHawkeyeAccessToken());
  const [outputHawkeyeAccessToken, setOutputHawkeyeAccessToken] = useState(getOutputHawkeyeAccessToken());

  const [email, setEmail] = useState(getEmail());


  const saveAccessToken = (userAccessToken, userIdToken, userEmail) => {
    const name = 'AFA78268'
    localStorage.setItem('accessToken', JSON.stringify(userAccessToken));

    setAccessToken(userAccessToken);
  };
  const saveIdToken = (userIdToken) => {
    localStorage.setItem('idToken', JSON.stringify(userIdToken));
    setIdToken(userIdToken);
  };
  const saveHawkeyeAccessToken = (userAccessToken) => {
    localStorage.setItem('hawkeyeAccessToken', JSON.stringify(userAccessToken));
    setAccessToken(userAccessToken);
  };

  const saveOutputHawkeyeAccessToken = (userAccessToken) => {
    localStorage.setItem('outputHawkeyeAccessToken', JSON.stringify(userAccessToken));
    setAccessToken(userAccessToken);
  };
  
  return {
    setAccessToken: saveAccessToken,
    setIdToken: saveIdToken,
    setHawkeyeAccessToken: saveHawkeyeAccessToken,
    setOutputHawkeyeAccessToken: saveOutputHawkeyeAccessToken,
    accessToken,
    idToken,
    hawkeyeAccessToken,
    outputHawkeyeAccessToken,
  }
}