import {
  CCard,
  CCardImage,
  CCardText,
  CCol,
  CContainer,
  CRow,
  CToast,
  CToastBody,
} from "@coreui/react";
import { Title } from "../components/costnomics/Title";
import { PainPoints } from "../components/costnomics/PainPoints";
import { ContactUs } from "../components/costnomics/ContactUs";
import { Navbar } from "../components/costnomics/marketingNavbar/Navbar";

import { FAQ } from "../components/costnomics/Reusable/FAQ";
import { Pricing } from "./Pricing";
import { Features } from "../components/costnomics/Features";
import { useNavigate } from "react-router-dom";
import { BookADemo } from "../components/costnomics/Reusable/BookADemoButton";
import { useState } from "react";
import { Solutions } from "../components/costnomics/Solutions";
import ImageSlider from "../components/costnomics/Reusable/ImageSlider";
const faqs = [
  [
    "What is CostNomics™?",
    "CostNomics™ is a SaaS product that helps Snowflake customers lower their costs, save time, and optimize performance.",
  ],
  [
    "How does CostNomics™ help with cost management?",
    "CostNomics™ captures over 40 key performance indicators (KPIs) related to resource consumption, performance bottlenecks, ongoing costs, and configuration gaps in your Snowflake account setup. It provides insights and recommendations for optimizing costs, improving performance, and making informed decisions.",
  ],
  [
    "Can CostNomics™ help with budget planning?",
    "Yes, CostNomics™ simplifies budget planning by leveraging rule-based configurations and document templates. It allows organizations to forecast costs, allocate resources effectively, and plan budgets for future Snowflake implementations.",
  ],
  [
    "Is CostNomics™ suitable for all sizes of organizations?",
    "Yes, CostNomics™ is designed to benefit organizations of all sizes using Snowflake. Whether you are a small business or an enterprise, CostNomics helps you effectively manage costs and optimize resource utilization within your Snowflake environment.",
  ],
  [
    "How do I get started with CostNomics™?",
    "To get started with CostNomics™, you can reach out to the CostNomics™ team or visit their website to learn more about the onboarding process, pricing, and any required setup steps. They will guide you through the implementation and provide the necessary support to help you maximize the benefits of the CostNomics™ solution.",
  ],
];
export const Home = () => {
  const navigate = useNavigate();
  const [toastVisible, setToastVisible] = useState(false);
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  return (
    <>
      <Navbar
        setToastVisible={setToastVisible}
        setErrorToastVisible={setErrorToastVisible}
      />

      <CContainer
        className="m-0 p-0"
        fluid
        style={{ backgroundColor: "white", overflow: "hidden" }}
      >
        <CContainer
          id="Home"
          className="d-flex justify-content-center bg-color background-img m-0 p-0"
          fluid
          style={{ overflow: "hidden" }}
        >
          <CRow
            className="d-flex justify-content-center align-items-center text-center height-100"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CCol sm={12} className="landing-text">
              <CCard className="bg-transparent border-0 text-center">
                <CCardText className="m-0 p-0 title-text title-text-gradiant-color text-align-center animate-charcter">
                  CostNomics™
                </CCardText>
                <CCardText className="m-0 p-0  pb-2 title-text title-text-gradiant-color text-align-center">
                  Unlock Snowflake Cost Efficiency
                </CCardText>

                <CCardText className="m-0 p-0 ps-1  description-text text-align-center">
                  Streamlined budget planning and deep insights for optimized
                  Snowflake expenses.
                </CCardText>
              </CCard>
              <CRow className="align-items-center mt-4 text-align-center">
                <CCol
                  sm={12}
                  className="d-flex justify-content-center text-align-center "
                >
                  <BookADemo
                    lightShadow={true}
                    setToastVisible={setToastVisible}
                    setErrorToastVisible={setErrorToastVisible}
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CContainer>

        <CContainer
          id="features"
          fluid
          className="m-0 p-0 features-bg"
          style={{ backgroundColor: "#070648" }}
        >
          <Title title={"Features"} />
          <Features />
        </CContainer>

        <CContainer className="problems-bg" id="problems" fluid>
          <Title
            title={"Do these Pain-Points Feel Familiar?"}
            color="text-black"
          />
          <PainPoints />
        </CContainer>

        {/* solutions */}
        <CContainer
          className="pb-5"
          style={{ backgroundColor: "#070648" }}
          id="solutions"
          fluid
        >
          <Title title="Solutions Provided by CostNomics™" />

          <Solutions />
        </CContainer>
        <CContainer id="pricing" className="pricing-bg m-0" fluid>
          <Title title={"Pricing"} />
          <Pricing />
        </CContainer>
        <CContainer
          className="faq-background m-0"
          style={{
            background: "rgb(9,52,125)",
            background:
              "radial-gradient(circle, rgba(9,52,125,1) 7%, rgba(9,53,126,1) 15%, rgba(9,49,120,1) 25%, rgba(9,10,67,1) 67%)",
          }}
          fluid
        >
      <Title title={"Frequently asked questions"}/>
          <FAQ data={faqs} />
        </CContainer>

        <CContainer
          id="contact-us"
          style={{ width: "100%" }}
          className="m-0 p-0"
          fluid
        >
          <ContactUs
            setToastVisible={setToastVisible}
            setErrorToastVisible={setErrorToastVisible}
          />
        </CContainer>
        {/* <Footer /> */}

        <CToast
          visible={toastVisible}
          color="success"
          className="text-white align-items-center toast-style"
          delay={4000}
          style={{
            position: "fixed",
            top: "70px",
            right: "20px",
            zIndex: "2500",
          }}
        >
          <div className="d-flex">
            <CToastBody className="toast-body">
              Received! We'll be in touch soon.
            </CToastBody>
          </div>
        </CToast>
        <CToast
          visible={errorToastVisible}
          color="danger"
          className="text-white align-items-center error-toast-style"
          delay={4000}
          style={{
            position: "fixed",
            top: "70px",
            right: "20px",
            zIndex: "2500",
          }}
        >
          <div className="d-flex">
            <CToastBody className="toast-body">
              Something went wrong.
            </CToastBody>
          </div>
        </CToast>
      </CContainer>
    </>
  );
};
