import { CCardText, CCol } from "@coreui/react";

export const DocumentTitle = ({ title }) => {
  return (
    <CCardText
      className="document-text"
      style={{ fontSize: "20px", paddingLeft: 10 }}
    >
      {title}
    </CCardText>
  );
};
