import React from "react";
import { CCard, CCardText } from "@coreui/react";
import { CodeView } from "../component/CodeView";
import { DocumentTitle } from "../component/DocumentTitle";
import { DocumentationNote } from "../component/DocumentationNote";

function UninstallationStepsCandour() {
  return (
    <>
      {/* <CCardText className="document-text"> Uninstallation Steps.</CCardText> */}
      <DocumentTitle title={"Uninstallation"} />
      <CCard style={{ padding: 10, width: "600px" }}>
        <CCardText>
          This section contains steps to remove Candour native app.
        </CCardText>
      </CCard>
      <DocumentationNote
        text={`Replace <WAREHOUSE_NAME> with the name of the warehouse.
Replace <APPLICATION_NAME> with the application installed name.
Replace <ROLE_NAME> with the name of the role created.
Replace <USER_NAME> with the name of the user created.`}
      />

      <CodeView
        rows={5}
        codeText={`USE ROLE ACCOUNTADMIN;
DROP WAREHOUSE <WAREHOUSE_NAME>; 
DROP APPLICATION <APPLICATION_NAME>;
DROP ROLE <ROLE_NAME>;
DROP USER <USER_NAME>;
        `}
      />
    </>
  );
}
export default UninstallationStepsCandour;
