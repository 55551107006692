import React from "react";
import { NavLink } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CImage,
  CCol,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from "@coreui/icons";
import BreadCrumb from "./BreadCrumb";

// import { AppBreadcrumb } from './index'
// import { AppHeaderDropdown } from './header/index'
import logo from "../../../assets/documentationAssets/images/Atgeir-New-Logo_Light.svg";

const Navbar = ({ visible, setVisible }) => {
  //   const dispatch = useDispatch()
  //   const sidebarShow = useSelector((state) => state.sidebarShow)

  return (
    <CHeader
      position="sticky"
      className="mb-2 p-0 border-0"
      style={{ zIndex: 2000 }}
    >
      <CContainer
        className="py-2 position-relative "
        style={{
          backgroundColor: "rgb(1,36,94)",
          zIndex: 3000,
        }}
        fluid
      >
        <CHeaderBrand
          to="/"
          style={{
            position: "fixed",
            left: 0,
            zIndex: 4500,
          }}
        >
          <CImage src={logo} alt="Logo" width={140} />
        </CHeaderBrand>
        {/* <CHeaderNav
          
          
        > */}
        <CCol
          className="d-none d-md-flex justify-content-center text-white"
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            zIndex: 3000,
            fontSize: "22px",
            fontWeight: "600",
            letterSpacing: "1px",
          }}
        >
          DataGeir™ CostNomics™
        </CCol>
        {/* </CHeaderNav> */}

        <CHeaderNav
          style={{
            marginTop: "2.5px",
            marginLeft: "auto",
            position: "relative",
            zIndex: 4000,
          }}
        >
          <CNavItem>
            <CNavLink href="/">
              <i
                class="fa-solid fa-house fa-xl"
                style={{ color: "#ffffff" }}
              ></i>
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
      </CContainer>
      <CContainer fluid className="border-0 d-flex align-items-center">
        <CHeaderToggler
          className=""
          onClick={() => setVisible(!visible)}
          //   onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CCol>
          <BreadCrumb />
        </CCol>
      </CContainer>
    </CHeader>
  );
};

export default Navbar;
