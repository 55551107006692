import { cilNotes } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCollapse,
  CFormInput,
  CSpinner,
} from "@coreui/react";
import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { ghcolors } from "react-syntax-highlighter/dist/esm/styles/prism";
export const CodeView = ({ codeText, disableShowMore, example, titleText }) => {
  const [showMore, setShowMore] = useState(false);
  const [copy, setCopy] = useState(false);

  const handleCopy = () => {
    setCopy(true);
    navigator.clipboard.writeText(codeText);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };
  return (
    <>
      <CCard
        className="text-center mb-4 mt-1 visible-scroll"
        style={{
          maxWidth: "100%",
          fontSize: "16px",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          borderRadius: "10px",
        }}
      >
        <CCardHeader
          className="bg-white border-bottom p-1 m-0 "
          style={{
            height: "35px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <div
            className={`d-flex align-items-center me-2 cursor-pointer ${
              example || titleText != undefined
                ? "justify-content-between"
                : "justify-content-end"
            }`}
          >
            {titleText != undefined ? (
              <div
                className="ps-3 text-dark fw-500"
                style={{ letterSpacing: "0.5px" }}
              >
                {titleText}
              </div>
            ) : null}
            {example ? (
              <div
                className="ps-3 text-info fw-500"
                style={{ letterSpacing: "0.5px" }}
              >
                Example
              </div>
            ) : null}
            <div
              onClick={() => handleCopy()}
              className="d-flex align-items-center "
            >
              {copy ? (
                <div className="d-flex align-items-center gap-1">
                  <div>
                    {" "}
                    <i
                      className="fa-solid fa-clipboard-check"
                      style={{ color: "#198718" }}
                    ></i>
                  </div>
                  <div style={{ fontSize: "12px", fontWeight: "600" }}>
                    Copied!
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center gap-1">
                  <div>
                    <i className="fa-solid fa-clipboard"></i>
                  </div>
                  <div style={{ fontSize: "12px", fontWeight: "600" }}>
                    Copy Code
                  </div>
                </div>
              )}
            </div>
          </div>
        </CCardHeader>
        <CCardBody className={`p-0 ${disableShowMore ? "pt-3" : "pt-2"} ps-2`}>
          <CCardText
            style={{ width: "100%" }}
            className="p-0 m-0 visible-scroll "
          >
            <SyntaxHighlighter
              id="scrollbar-new"
              className="visible-scroll"
              customStyle={{
                border: "none",
                margin: "0px",
                padding: "0px",
                paddingRight: "5px",
                paddingBottom: disableShowMore ? "15px" : "8px",
                maxHeight: showMore ? null : "300px", // Adjust the height
                width: "100%",
                // Add scroll when content exceeds the height
              }}
              language="sql"
              style={ghcolors}
              showLineNumbers
            >
              {codeText}
            </SyntaxHighlighter>
          </CCardText>
        </CCardBody>
        {!disableShowMore && (
          <CCardFooter
            className="bg-white border-top p-0 m-0 ps-3 d-flex align-items-center gap-2 cursor-pointer font-weight-bold"
            style={{
              height: "30px",
              fontSize: "12px",
              fontWeight: "600",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
            onClick={() => (disableShowMore ? false : setShowMore(!showMore))}
          >
            {showMore ? (
              <i class="fa-solid fa-angle-up"></i>
            ) : (
              <i class="fa-solid fa-angle-down"></i> // Change to angle-down when collapsed
            )}
            {showMore ? "Show Less" : "Show More"}
          </CCardFooter>
        )}
      </CCard>
    </>
  );
};
