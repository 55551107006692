import {
  cilLockLocked,
  cilMagnifyingGlass,
  cilBook,
  cilLink,
  cilFolderOpen,
  cilDollar,
  cilUser,
  cibGmail,
  cilBuilding,
  cilAddressBook,
  cilTag,
  cilChartLine,
  cilLibrary,
  cilCloudUpload,
  cilLocationPin,
} from "@coreui/icons";

const loginFields = [
  {
    labelText: "Email address",
    labelFor: "email-address",
    id: "email-address",
    name: "email",
    type: "email",
    //autoComplete:"email",
    isRequired: true,
    placeholder: "Email address",
    icon: cilUser,
    autofocus: true,
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    //autoComplete:"current-password",
    isRequired: true,
    placeholder: "Password",
    icon: cilLockLocked,
  },
];

const signupFields = [
  {
    labelText: "Name",
    labelFor: "name",
    id: "name",
    name: "name",
    type: "text",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "Enterprise Name",
    icon: cilBuilding,
    autofocus: true,
  },
  {
    labelText: "Owner",
    labelFor: "owner",
    id: "owner",
    name: "owner",
    type: "text",
    //autoComplete:"owner",
    isRequired: true,
    placeholder: "Owner Name",
    icon: cilUser,
  },
  {
    labelText: "Address",
    labelFor: "address",
    id: "address",
    name: "name",
    type: "text",
    //autoComplete:"address",
    isRequired: true,
    placeholder: "Enterprise Address",
    icon: cilLocationPin,
  },
  {
    labelText: "Mobile Number",
    labelFor: "mobile",
    id: "mobile",
    name: "mobile",
    type: "Number",
    autoComplete: "mobile-number",
    isRequired: true,
    placeholder: "Mobile Number",
    icon: cibGmail,
  },
  {
    labelText: "Email address",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email-address",
    isRequired: true,
    placeholder: "Email Address (username)",
    icon: cibGmail,
  },

  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "new-password",
    isRequired: true,
    placeholder: "Password",
    icon: cilLockLocked,
  },
  {
    labelText: "Confirm Password",
    labelFor: "confirm password",
    id: "confirm password",
    name: "confirm password",
    type: "password",
    autoComplete: "confirm-password",
    isRequired: true,
    placeholder: "Confirm Password",
    icon: cilLockLocked,
  },
];
const accountSetupFields = [
  {
    labelText: "Name",
    labelFor: "name",
    id: "name",
    name: "name",
    type: "text",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "Enterprise Name",
    icon: cilBuilding,
  },
  {
    labelText: "Owner",
    labelFor: "owner",
    id: "owner",
    name: "owner",
    type: "text",
    //autoComplete:"owner",
    isRequired: true,
    placeholder: "Owner Name",
    icon: cilUser,
  },

  {
    labelText: "Address",
    labelFor: "address",
    id: "address",
    name: "name",
    type: "text",
    //autoComplete:"address",
    isRequired: true,
    placeholder: "Enterprise Address",
    icon: cilLocationPin,
  },
  {
    labelText: "Mobile Number",
    labelFor: "mobile",
    id: "mobile",
    name: "mobile",
    type: "Number",
    autoComplete: "mobile-number",
    isRequired: true,
    placeholder: "Mobile Number",
    icon: cibGmail,
  },
];
const productFields = [
  {
    labelText: "Owner name",
    labelFor: "owner",
    id: "owner",
    name: "owner",
    type: "text",
    inputType: "text",
    //autoComplete:"owner",
    isRequired: true,
    placeholder: "Data product owner name",
    icon: cilBuilding,
  },
  {
    labelText: "Email address",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "text",
    inputType: "text",
    isRequired: true,
    placeholder: "Email Address (username)",
    icon: cibGmail,
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    inputType: "text",
    isRequired: true,
    placeholder: "Password",
    icon: cilLockLocked,
  },
  {
    labelText: "Name",
    labelFor: "name",
    id: "name",
    name: "name",
    inputType: "text",
    type: "text",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "Product name",
    icon: cilBuilding,
  },
  {
    labelText: "Visibility",
    labelFor: "visibility",
    id: "visibility",
    name: "visibility",
    inputType: "select",
    type: "select",
    //autoComplete:"visibility",
    isRequired: true,
    placeholder: "Product visibility",
    icon: cilMagnifyingGlass,
  },
  {
    labelText: "Status",
    labelFor: "status",
    id: "status",
    name: "status",
    inputType: "select",
    type: "select",
    //autoComplete:"status",
    isRequired: true,
    placeholder: "Product status",
    icon: cilChartLine,
  },
  {
    labelText: "Type",
    labelFor: "type",
    id: "type",
    name: "type",
    inputType: "select",
    type: "select",
    //autoComplete:"type",
    isRequired: true,
    placeholder: "Product type",
    icon: cilBuilding,
  },
  {
    labelText: "Value proposition",
    labelFor: "valueprop",
    id: "valueprop",
    name: "valueprop",
    inputType: "text",
    type: "text",
    //autoComplete:"valueprop",
    isRequired: true,
    placeholder: "Product's value proposition",
    icon: cilDollar,
  },
  {
    labelText: "Description",
    labelFor: "description",
    id: "description",
    name: "description",
    inputType: "text",
    type: "text",
    //autoComplete:"description",
    isRequired: true,
    placeholder: "Product description",
    icon: cilBook,
  },
  {
    labelText: "Categories",
    labelFor: "categories",
    id: "categories",
    name: "categories",
    inputType: "text",
    type: "text",
    //autoComplete:"categories",
    isRequired: true,
    placeholder: "Product categories",
    icon: cilLibrary,
  },
  {
    labelText: "Tags",
    labelFor: "tags",
    id: "tags",
    name: "tags",
    inputType: "text",
    type: "text",
    //autoComplete:"tags",
    isRequired: true,
    placeholder: "Tags",
    icon: cilTag,
  },
  {
    labelText: "Version",
    labelFor: "version",
    id: "version",
    name: "version",
    inputType: "text",
    type: "text",
    //autoComplete:"version",
    isRequired: true,
    placeholder: "Product version",
    icon: cilCloudUpload,
  },
  {
    labelText: "Url",
    labelFor: "url",
    id: "url",
    name: "url",
    inputType: "text",
    type: "text",
    //autoComplete:"url",
    isRequired: true,
    placeholder: "Product's brand logo url",
    icon: cilLink,
  },
  {
    labelText: "Brand slogan",
    labelFor: "brandSlogan",
    id: "brandSlogan",
    name: "brandSlogan",
    inputType: "text",
    type: "text",
    //autoComplete:"brandSlogan",
    isRequired: true,
    placeholder: "Product's Brand slogan",
    icon: cilTag,
  },
];

const domainFields = [
  {
    labelText: "Domain Name",
    labelFor: "name",
    id: "name",
    name: "name",
    type: "text",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "Domain Name",
    icon: cilBuilding,
  },
  {
    labelText: "Description",
    labelFor: "description",
    id: "description",
    name: "description",
    type: "text",
    //autoComplete:"description",
    isRequired: true,
    placeholder: "Domain Description",
    icon: cilBook,
  },
  {
    labelText: "Owner Name",
    labelFor: "owner",
    id: "owner",
    name: "owner",
    type: "text",
    //autoComplete:"owner",
    isRequired: true,
    placeholder: "Domain Owner Name",
    icon: cilUser,
  },

  {
    labelText: "Domain Email Address",
    labelFor: "email-address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email-address",
    isRequired: true,
    placeholder: "Email Address (username)",
    icon: cibGmail,
  },

  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "new-password",
    isRequired: true,
    placeholder: "Password",
    icon: cilLockLocked,
  },

  {
    labelText: "Confirm Password",
    labelFor: "Confirm Password",
    id: "confirm password",
    name: "confirm password",
    type: "password",
    autoComplete: "new-password",
    isRequired: true,
    placeholder: "Confirm Password",
    icon: cilLockLocked,
  },
];
const dataSourceUserFields = [
  {
    labelText: "Owner Name",
    labelFor: "owner",
    id: "owner",
    name: "owner",
    type: "text",
    //autoComplete:"owner",
    isRequired: true,
    placeholder: "Data Source User Name",
    icon: cilUser,
  },

  {
    labelText: "Email Address",
    labelFor: "email-address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email-address",
    isRequired: true,
    placeholder: "Email Address (username)",
    icon: cibGmail,
  },

  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "new-password",
    isRequired: true,
    placeholder: "Password",
    icon: cilLockLocked,
  },

  {
    labelText: "Confirm Password",
    labelFor: "Confirm Password",
    id: "confirm password",
    name: "confirm password",
    type: "password",
    autoComplete: "new-password",
    isRequired: true,
    placeholder: "Confirm Password",
    icon: cilLockLocked,
  },
];
const enterpriseFields = [
  {
    labelText: "Name",
    labelFor: "owner",
    id: "owner",
    name: "owner",
    type: "text",
    //autoComplete:"owner",
    isRequired: true,
    placeholder: "Enterprise User Name",
    icon: cilUser,
  },
  {
    labelText: "User Email Address",
    labelFor: "email-address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email-address",
    isRequired: true,
    placeholder: "Email Address (username)",
    icon: cibGmail,
  },

  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "new-password",
    isRequired: true,
    placeholder: "Password",
    icon: cilLockLocked,
  },

  {
    labelText: "Confirm Password",
    labelFor: "Confirm Password",
    id: "confirm password",
    name: "confirm password",
    type: "password",
    autoComplete: "new-password",
    isRequired: true,
    placeholder: "Confirm Password",
    icon: cilLockLocked,
  },
];
const validDomainFields = [
  {
    labelText: "Domain Name",
    labelFor: "name",
    id: "name",
    name: "name",
    type: "text",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "Domain Name",
    icon: cilBuilding,
  },
  {
    labelText: "Description",
    labelFor: "description",
    id: "description",
    name: "description",
    type: "text",
    //autoComplete:"description",
    isRequired: true,
    placeholder: "Domain Description",
    icon: cilBook,
  },
  {
    labelText: "Owner Name",
    labelFor: "owner",
    id: "owner",
    name: "owner",
    type: "text",
    //autoComplete:"owner",
    isRequired: true,
    placeholder: "Domain Owner Name",
    icon: cilUser,
  },

  {
    labelText: "Domain Email Address",
    labelFor: "email-address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email-address",
    isRequired: true,
    placeholder: "Email Address (username)",
    icon: cibGmail,
  },
];
const validDataSourceUserFields = [
  {
    labelText: "Owner Name",
    labelFor: "owner",
    id: "owner",
    name: "owner",
    type: "text",
    //autoComplete:"owner",
    isRequired: true,
    placeholder: "Data Source User Name",
    icon: cilUser,
  },

  {
    labelText: "Email Address",
    labelFor: "email-address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email-address",
    isRequired: true,
    placeholder: "Email Address (username)",
    icon: cibGmail,
  },
];

const validEnterpriseFields = [
  {
    labelText: "Name",
    labelFor: "owner",
    id: "owner",
    name: "owner",
    type: "text",
    //autoComplete:"owner",
    isRequired: true,
    placeholder: "Enterprise User Name",
    icon: cilUser,
  },
  {
    labelText: "User Email Address",
    labelFor: "email-address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email-address",
    isRequired: true,
    placeholder: "Email Address (username)",
    icon: cibGmail,
  },
];
const configureFields = [
  {
    labelText: "Name",
    labelFor: "name",
    id: "name",
    name: "name",
    type: "text",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "Data Source name",
    icon: cilBuilding,
  },
];
const snowflakeFields = [
  {
    labelText: "SnowflakeConnection",
    description: "Snowflake Connection Config",
    labelFor: "name",
    id: "name",
    name: "name",
    type: "text",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "",
    icon: cilBuilding,
  },
  {
    labelText: "Username",
    description:
      "Username to connect to Snowflake. This user should have privileges to read all the metadata in Snowflake.",
    labelFor: "name",
    id: "name",
    name: "name",
    type: "text",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "",
    icon: cilBuilding,
  },
  {
    labelText: "Password",
    description: "Password to connect to Snowflake.",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "text",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "",
    icon: cilBuilding,
  },
  {
    labelText: "Account",
    description:
      "If the Snowflake URL is https://xyz1234.us-east-1.gcp.snowflakecomputing.com, then the account is xyz1234.us-east-1.gcp'        ",
    labelFor: "account",
    id: "account",
    name: "account",
    type: "text",
    //autoComplete:"account",
    isRequired: true,
    placeholder: "",
    icon: cilBuilding,
  },
  {
    labelText: "Role",
    description: "Snowflake Role.",
    labelFor: "role",
    id: "role",
    name: "role",
    type: "text",
    //autoComplete:"account",
    isRequired: true,
    placeholder: "",
    icon: cilBuilding,
  },
  {
    labelText: "Database",
    description:
      "Database of the data source. This is optional parameter, if you would like to restrict the metadata reading to a single database.",
    labelFor: "database",
    id: "database",
    name: "database",
    type: "text",
    //autoComplete:"database",
    isRequired: true,
    placeholder: "",
    icon: cilBuilding,
  },
  {
    labelText: "Warehouse",
    description: "Snowflake warehouse.",
    labelFor: "warehouse",
    id: "warehouse",
    name: "warehouse",
    type: "text",
    //autoComplete:"warehouse",
    isRequired: true,
    placeholder: "",
    icon: cilBuilding,
  },
  {
    labelText: "Query Tag",
    description: "Session query tag used to monitor usage on snowflake",
    labelFor: "query",
    id: "query",
    name: "query",
    type: "text",
    //autoComplete:"query",
    isRequired: true,
    placeholder: "",
    icon: cilBuilding,
  },
  {
    labelText: "Private Key",
    description: "Connection to Snowflake instance via Private Key",
    labelFor: "pkey",
    id: "pkey",
    name: "pkey",
    type: "text",
    //autoComplete:"pkey",
    isRequired: true,
    placeholder: "",
    icon: cilBuilding,
  },
  {
    labelText: "Snowflake Passphrase Key",
    description: "Snowflake Passphrase Key used with Private Key",
    labelFor: "sPassphrase",
    id: "sPassphrase",
    name: "sPassphrase",
    type: "text",
    //autoComplete:"sPassphrase",
    isRequired: true,
    placeholder: "",
    icon: cilBuilding,
  },
];
const licenseFields = [
  {
    labelText: "Number of SnowFlake Accounts",
    labelFor: "Accounts",
    id: "Accounts",
    name: "Accounts",
    type: "number",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "Enter Count",
    // icon: cilBuilding,
  },
  // {
  //   labelText: "Duration of License",
  //   labelFor: "Duration",
  //   id: "Duration",
  //   name: "Duration",
  //   type: "number",
  //   //autoComplete:"name",
  //   isRequired: true,
  //   placeholder: "Enter Number of Months",
  //   // icon: cilBuilding,
  // },
  {
    labelText: "Number of Enterprise Users",
    labelFor: "Users",
    id: "Users",
    name: "Users",
    type: "number",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "Enter Count",
    // icon: cilBuilding,
  },
  {
    labelText: "Number of Domain Owners",
    labelFor: "Domain",
    id: "Domain",
    name: "Domain",
    type: "number",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "Enter Count",
    // icon: cilBuilding,
  },
  {
    labelText: "Number of Data Source users per Domain",
    labelFor: "DSUser",
    id: "DSUser",
    name: "DSUser",
    type: "number",
    //autoComplete:"name",
    isRequired: true,
    placeholder: "Enter Count",
    // icon: cilBuilding,
  },
];

export {
  loginFields,
  signupFields,
  domainFields,
  productFields,
  configureFields,
  snowflakeFields,
  accountSetupFields,
  validDomainFields,
  validEnterpriseFields,
  enterpriseFields,
  validDataSourceUserFields,
  dataSourceUserFields,
  licenseFields,
};
