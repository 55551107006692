import React from 'react'
import {
    CCard,
    CContainer,
    CCardTitle,
    CCardText,
    CRow, CCol,
    CCardImage, 
} from '@coreui/react'
function AnomalyDocumentation() {
  return (
    <>
     <CCardText className="document-text" id="anomaly-detection">
          {" "}
          Anomaly Detection in a table.
        </CCardText>
        <CCardText >
          This section contains steps along with example allowing user to implement
          the anomaly detection feature in a table of their choice.
        </CCardText>
        {/* TO ADD DROPDOWN HERE */}

        <CCard style={{ padding: 10 }}>
          
          
          <CCardText>
             <b>ANOMALY KPI</b> Anomaly detection stored procedures runs for a given table and column inside the table and determines if a certain entry in the table has an outlier value for the provided column and correspondingly updates an extra column named ‘Outlier’ as 1 if the value is an outlier and 0 if it is not.
There is no alteration done to the source table, rather the anomaly is calculated and data is inserted into another destination table which is a copy of the source table with an extra column named ‘Outlier’.
<br/>
<br/>
<b>Note :</b> User creates the destination table with the same table definition, adding one extra column ‘OUTLIER’ of NUMBER data type.
 <br />
 <b> Example of a user created destination table</b> : 
 <br/>
 <CCardText className="costnomics-doc-text">
   1) create table {'<'}destination_table_name{'>'} like {'<'}source_table_name{'>'} ; <br/>
   2) alter table {'<'}destination_table_name{'>'} add column ANOMALY number;<br/>
</CCardText>
 <b> Structure of calling the Stored Procedure :</b> : 
 <br/>
 <CCardText className="costnomics-doc-text">
   call COSTNOMICS_SETUP.ANOMALY_DETECT( <br/>
<br/>
{"<"}Parameter 1 ( INPUT OBJECT ) {'>'} ,<br/>
{"<"}Parameter 2 ( OUTLIER FRACTION ) {'>'} <br/>
<br/>
);
</CCardText>


            <b><u>Param 1: ANOMALY INPUT (VARIANT). </u></b><br />
            <b> Structure of an Input Object</b><br />
                <CCardText >
            
          
            <pre>
                { " { "}<br/>
               <i> <b> 'Name of the table to Perform the anomaly detection on'</b> </i>: { " { "}<br/>
                            {"     "}'input_anomaly_column': <i> <b> 'Name of the Column to Perform the anomaly detection on'</b> </i>,<br/>
                            {"     "}'output_table': <i> <b> 'Name of the output table that you created to store the result'</b> </i><br/>
                {"}"},<br/>
            {"}"}<br/>
            <b> Example of an Input Object</b><br />
            { " { "}<br/>
                'COSTNOMICS_APP.credit_consumption_by_warehouse': { " { "}<br/>
                    {"     "}'input_anomaly_column': 'CREDITS_USED_COMPUTE_SUM',<br/>
                    {"     "}'output_table': 'COSTNOMICS_APP.credit_consumption_wh_anomaly'<br/>
                {"}"}<br/>

            {"}"} <br/>
            </pre>
          </CCardText>
          <b><u>Param 2: OUTLIER_FRACTION (FLOAT)</u></b>   <br />
          Outlier fraction or contamination percentage is the % of outliers you want to see in the dataset. <br/>
          Range lies between 0.1 and 0.5 corresponding to percentage of outliers that you want to see(10% to 50%).
          </CCardText>
          <b><u>Calling the Procedure:</u></b>   <br />
          <CCardText className="costnomics-doc-text">
            use database {"<"}DB_NAME{">"}; <br />
            
            <pre> 
              call COSTNOMICS_SETUP.ANOMALY_DETECT( <br/>
            { " { "}<br/>
            'COSTNOMICS_APP.CREDIT_CONSUMPTION_BY_USER': { " { "}<br/>
            {"     "}'input_anomaly_column': 'APPROXIMATE_CREDITS_USED',<br/>
            {"     "}'output_table': 'COSTNOMICS_APP.credit_consumption_user_anomaly'<br/>
            {"}"},<br/>
            'COSTNOMICS_APP.credit_consumption_by_warehouse': { " { "}<br/>
                {"     "}'input_anomaly_column': 'CREDITS_USED_COMPUTE_SUM',<br/>
                {"     "}'output_table': 'COSTNOMICS_APP.credit_consumption_wh_anomaly'<br/>
            {"}"}<br/>

{"}"}, <br/>
<b>0.1</b> <br/>
);
            </pre>

          </CCardText>
        </CCard>
    </>
  )
}

export default AnomalyDocumentation