import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
} from "@coreui/react";
// import CostnomicsImg from 'src/assets/images/CostNomics_Logo_doc.png'
// import companyLogo from 'src/assets/images/Atgeir_Logo.png'

function PreInstallationSetup() {
  return (
    <>
      <CCardText className="document-text"> Pre-Installation Setup.</CCardText>
      <CCard style={{ padding: 10 }}>
        <CCardText>
          This section contains steps that native app users should execute
          before subscribing to the native application from Snowflake
          marketplace.{" "}
        </CCardText>
        <CCardText className="mb-0">
          1) Create a dedicated Warehouse for CostNomics™ App.
        </CCardText>
        <CCardText className="mb-0" style={{ padding: 15 }}>
          <b>
            <u>
              NOTE: Users Can Choose Any Naming Convention That Suits Their
              Organization Policies. But Ensure That The Same Is Used Across In
              Other Statements As Well. For Representation Sake, We Will Use The
              Name “Costnomics_wh”. Users Can Always Replace It With the Name Of
              Their Choice In All Setup Statements.
            </u>
          </b>
        </CCardText>
        <CCardText className="costnomics-doc-text mb-0">
          CREATE WAREHOUSE COSTNOMICSAPP_WH WITH WAREHOUSE_SIZE = 'XSMALL'
          WAREHOUSE_TYPE = 'STANDARD' AUTO_SUSPEND = 60 AUTO_RESUME = TRUE
          MIN_CLUSTER_COUNT = 1 MAX_CLUSTER_COUNT = 2 SCALING_POLICY =
          'STANDARD' COMMENT = 'Warehouse for CostNomics Native App';{" "}
        </CCardText>{" "}
        <br />
        <CCardText className="mb-0">
          2) Create Role for CostNomics™ Native App.
        </CCardText>
        <CCardText className="mb-0" style={{ padding: 15 }}>
          <b>
            <u>
              NOTE: Please Do Not Change The Role Name As It Is Required By
              Internal Code. The Role Name Is “datageir_costnomics_prod_role”.
            </u>
          </b>
        </CCardText>
        <CCardText className="costnomics-doc-text">
          CREATE ROLE DATAGEIR_COSTNOMICS_PROD_ROLE COMMENT='ROLE FOR COSTNOMICS
          NATIVE APP';
        </CCardText>
        <CCardText>
          3) Execute the following command to give Grant on the Warehouse
          “costnomics_wh” to the Role “datageir_costnomics_prod_role” which you
          have created in the previous steps.
        </CCardText>
        <CCardText className="costnomics-doc-text">
          GRANT USAGE ON WAREHOUSE COSTNOMICSAPP_WH TO ROLE
          DATAGEIR_COSTNOMICS_PROD_ROLE;
        </CCardText>
        <CCardText>
          4) Execute the following command to give Execute task Grant to the
          Role “datageir_costnomics_prod_role” on the Snowflake Account.
        </CCardText>
        <CCardText className="costnomics-doc-text">
          GRANT EXECUTE TASK ON ACCOUNT TO ROLE DATAGEIR_COSTNOMICS_PROD_ROLE;
        </CCardText>
        <CCardText>
          5) Execute the following command to give Grant on SNOWFLAKE Database
          to the Role “datageir_costnomics_prod_role”.
        </CCardText>
        <CCardText className="costnomics-doc-text">
          GRANT IMPORTED PRIVILEGES ON DATABASE SNOWFLAKE TO ROLE
          DATAGEIR_COSTNOMICS_PROD_ROLE;
        </CCardText>
        <CCardText>
          6) Execute the following command to give an additional Grant for tag
          on ACCOUNT to the Role “datageir_costnomics_prod_role”.
        </CCardText>
        <CCardText className="costnomics-doc-text">
          GRANT APPLY TAG ON ACCOUNT TO ROLE DATAGEIR_COSTNOMICS_PROD_ROLE;
        </CCardText>
        <CCardText style={{ padding: 15 }}>
          <b>
            <u>
              YOU HAVE COMPLETED ALL PRE-INSTALLATION STEPS. GO TO MARKETPLACE
              AND SUBSCRIBE TO COSTNOMICS™ APP. THEN, RUN POST-INSTALLATION
              SETUP AS PER SECTION BELOW.
            </u>
          </b>
        </CCardText>
      </CCard>
    </>
  );
}
export default PreInstallationSetup;
