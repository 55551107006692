import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pending_count: 0,
  accepted_count: 0,
  rejected_count: 0,
};

export const sideBarTabCountSlice = createSlice({
  name: "sideBarTabCountSlice",
  initialState,
  reducers: {
    update_pending: (state, action) => {
      state.pending_count = action.payload;
    },
    update_accepted: (state, action) => {
      state.accepted_count = action.payload;
    },
    update_rejected: (state, action) => {
      state.rejected_count = action.payload;
    },
  },
});

export const { update_pending, update_accepted, update_rejected } =
  sideBarTabCountSlice.actions;

export default sideBarTabCountSlice.reducer;
