import {
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CSpinner,
} from "@coreui/react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const background1 =
  "linear-gradient(90deg, rgba(255,104,0,1) 0%, rgba(252,176,69,1) 98%)";
const background2 =
  "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,128,128,1) 98%)";
export const BookADemo = (props) => {
  const [email, setEmail] = useState("");
  const [buttonText, setButtonText] = useState("Book a demo");
  const [background, setBackground] = useState(background1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleOnClick = async () => {
    props.setToastVisible(false);
    props.setErrorToastVisible(false);
    setLoading(true);
    try {
      if (email !== "") {
        if (validateEmail(email)) {
          const body = `{\n  "data": [\n    [\n      0,\n      "book_demo",\n      "costnomics_support@atgeirsolutions.com",\n      "{\\n\\"REQ_EMAIL\\": \\"${email}\\"\\n}"\n    ]\n  ]}`;

          let postData = JSON.stringify({
            body,
          });
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://8c5hijp35g.execute-api.us-east-2.amazonaws.com/prod",
            headers: {
              "x-api-key": "AMyRNTBy1l1oVeSBkxct52dalIp3W83J1olwiio5",
              "content-type": "application/json",
            },
            data: postData,
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.statusCode == 200) {
                
                setLoading(false);
                props.setToastVisible(true);
              } else {
                props.setErrorToastVisible(true);
                setLoading(false);
              }
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        } else {
          setLoading(false);
          setButtonText("Enter a valid email");
          setBackground(background2);
        }
      } else {
        setLoading(false);
        setButtonText("Enter a email");
        setBackground(background2);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  return (
    <>
      <CInputGroup
        size="lg"
        className={`m-2 border-circle  ${
          props.lightShadow ? "glowing-btn" : "glowing-btn2"
        } `}
        style={{
          borderRadius: "50px",
          padding: "0px",
          width: "350px",
        }}
      >
        <CFormInput
          placeholder="Enter your work email"
          aria-label="Email"
          value={email}
          type="email"
          aria-describedby="basic-addon2"
          onChange={(e) => {
            setEmail(e.target.value);
            setButtonText("Book a demo");
            setBackground(background1);
          }}
          style={{
            fontSize: "16px",
            borderBottomLeftRadius: "50px",
            borderTopLeftRadius: "50px",
            padding: "18px",
            backgroundColor: "#01245e",
            color: "hsl(186 100% 69%)",
            boxShadow: "none",
          }}
          disabled={loading}
        />

        <CInputGroupText
          id="basic-addon2"
          className="cursor-pointer glowing-txt d-flex align-items-center gap-2 "
          style={{
            fontSize: "16px",
            borderBottomRightRadius: "50px",
            borderTopRightRadius: "50px",
            padding: "18px",
            paddingLeft: "8px",
            paddingRight: "12px",
            boxShadow: "none !important",
            color: "white",
            fontWeight: "700",
            opacity: 0.9,
            letterSpacing: "1px",
            background: "rgb(248,134,56)",
            background: `${background}`,
          }}
          onClick={() => (loading ? null : handleOnClick())}
        >
          {loading ? <CSpinner size="sm" color="light" /> : null} {buttonText}
        </CInputGroupText>
      </CInputGroup>
    </>
  );
};
