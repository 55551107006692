import { CCard, CCardImage, CCardText, CCol, CRow } from "@coreui/react"
import GraphIcon from "../../assets/marketingAssets/icons/Product-Engineering-ICN-07.svg";
import CalIcon from "../../assets/marketingAssets/icons/Pertinent-Challenges-Icn-02.svg";
import SpeedMeterIcon from "../../assets/marketingAssets/icons/Data-Engg-Atgeir-Advantage-ICN-01.svg";
import MignifierIcon from "../../assets/marketingAssets/icons/Product-Engineering-ICN-05.svg";
import CostLowIcon from "../../assets/marketingAssets/icons/Advanced-Analytics-ICN-05.svg";
import PCSettingIcon from "../../assets/marketingAssets/icons/Data-Engg-Atgeir-Advantage-ICN-03.svg";
export const Solutions=()=>{
    return (
        <CRow className="mt-5 pt-4 px-5  d-flex gap-4 align-items-center">
        <CCol sm={12} md={12}>
          <CRow>
            <CCol
              sm={4}
              md={4}
              className="pb-3 pe-2 solution-col-1"
            >
              <CCard className="bg-transparent border-0 ">
                <CCardImage
                  orientation="top"
                  src={GraphIcon}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
                <CCardText
                  className="text-start pt-2"
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#EDEDED",
                  }}
                >
                  Data warehouses, queries, tools, and users that are
                  responsible for consuming the most credits over a
                  specified period of time.
                </CCardText>
              </CCard>
            </CCol>
            <CCol
              sm={4}
              md={4}
              className="pb-3 solution-col-2"
            //   style={{
            //     borderBottom: "1px solid #DDDDFF",
            //     borderRight: "1px solid #DDDDFF",
            //   }}
            >
              <CCard className="bg-transparent border-0">
                <CCardImage
                  orientation="top"
                  src={CalIcon}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
                <CCardText
                  className="text-start pt-2"
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#EDEDED",
                  }}
                >
                  Identifying total costs associated with Snowflake Cloud
                  Data Platform features
                </CCardText>
              </CCard>
            </CCol>
            <CCol
              sm={4}
              md={4}
              className="pb-3 solution-col-3"
          
            >
              <CCard className="bg-transparent border-0">
                <CCardImage
                  orientation="top"
                  src={SpeedMeterIcon}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
                <CCardText
                  className="text-start pt-2"
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#EDEDED",
                  }}
                >
                  Performance bottlenecks that might be causing excess
                  consumption.
                </CCardText>
              </CCard>
            </CCol>
          </CRow>
          <CRow>
            <CCol
              sm={4}
              md={4}
              className="pt-3 pe-2 solution-col-4"
            >
              <CCard className="bg-transparent border-0 ">
                <CCardImage
                  orientation="top"
                  src={MignifierIcon}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
                <CCardText
                  className="text-start pt-2"
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#EDEDED",
                  }}
                >
                  Identifying component that requires re-configuration to
                  leverage the appropriate Snowflake features.
                </CCardText>
              </CCard>
            </CCol>
            <CCol
              sm={4}
              md={4}
              className="pt-3 solution-col-4"
            >
              <CCard className="bg-transparent border-0">
                <CCardImage
                  orientation="top"
                  src={CostLowIcon}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
                <CCardText
                  className="text-start pt-2"
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#EDEDED",
                  }}
                >
                  Component Wise Cost Consumption for a new project on
                  Snowflake
                </CCardText>
              </CCard>
            </CCol>
            <CCol sm={4} md={4} className="pt-3">
              <CCard className="bg-transparent border-0">
                <CCardImage
                  orientation="top"
                  src={PCSettingIcon}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
                <CCardText
                  className="text-start pt-2"
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#EDEDED",
                  }}
                >
                  Component Wise Future Cost Consumption for next month,
                  quarter or year for an exiting project on Snowflake.
                </CCardText>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    )
}