import React, { useState } from "react";
// import { useSelector, useDispatch } from 'react-redux'

import {
  CCol,
  CImage,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import logo from "../../../assets/documentationAssets/images/Atgeir-New-Logo_Light.svg";

// import { AppSidebarNav } from './AppSidebarNav'

// import { logoNegative } from 'src/assets/brand/logo-negative'
// import { sygnet } from 'src/assets/brand/sygnet'

// sidebar nav config
import navigation from "./_nav";
import { SideBarNav } from "./SideBarNav";

const SideBar = ({ visible, setVisible }) => {
  // const [visible,setVisible]=useState(true)
  //   const dispatch = useDispatch()
  //   const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  //   const sidebarShow = useSelector((state) => state.sidebarShow)

  return (
    <CSidebar
      position="fixed"
      unfoldable={false}
      colorScheme={"white"}
      visible={visible}
      className="sidebar-font"
    >
    
      <CSidebarBrand
        to="/"
        style={{
          backgroundColor: "rgb(1,36,94)",
        }}
      >
        <CCol
          style={{
            position: "relative",
            left: 0,
            // top: "4.5px",
          }}
        >
          <CImage src={logo} alt="Logo" width={140} />
        </CCol>
      </CSidebarBrand>
      <CSidebarNav >
        <SideBarNav items={navigation} />
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => setVisible(!visible)}
      /> */}
    </CSidebar>
  );
};

export default React.memo(SideBar);
