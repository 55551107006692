import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
} from "@coreui/react";
// import CostnomicsImg from 'src/assets/images/CostNomics_Logo_doc.png'
// import companyLogo from 'src/assets/images/Atgeir_Logo.png'
import { Dropdown } from "@coreui/coreui";

function Examples() {
  return (
    <>
      <CCardText className="document-text" id="kpi_calls">
        {" "}
        Examples.
      </CCardText>
      <CCardText>
        This section contains some example SQL statements that DashBoard tools
        and Web UI can use.
      </CCardText>
      {/* TO ADD DROPDOWN HERE */}

      <CCard style={{ padding: 10 }}>
        <CCardText className="document-text">
          <b>KPI call Examples</b>
        </CCardText>
        <CCardText>
          1) <b>CREDIT_USAGE KPI</b> returns the monthly credit usage for the
          current year. <br />
          Parameter : Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB_NAME>"}; <br />
          CALL CODE.CREDIT_USAGE('APP');
        </CCardText>
        <CCardText>
          2) <b>WAREHOUSE_CREDITS_TAGGING KPI </b>returns the total credits used
          by the warehouse.
          <br />
          Parameter 1: Schema name (varchar). <br />
          Parameter 2: Value (START DATE, END DATE, TAG) as variant.
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE{" <DB_NAME>"};<br />
          CALL CODE.WAREHOUSE_CREDITS_TAGGING('APP',
          {"{ 'START': '2023-02-19', 'END': '2023-03-21'}"}); <br />
          {/* CALL CODE.WAREHOUSE_CREDITS('APP',
          {
            " {'START': '2023-03-01', 'END': '2023-03-21','TAG':'COSTNOMICS_TAG'}"
          }
          ); */}
        </CCardText>
        <CCardText>
          3. <b>MOST_EXPENSIVE_QUERIES_TAGGING KPI </b> returns the most
          expensive queries.
          <br />
          Parameter 1: Schema name (varchar).
          <br />
          Parameter 2: Value (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB_NAME>"}; <br />
          CALL CODE.MOST_EXPENSIVE_QUERIES_TAGGING('APP',
          {"{ 'START': '2023-02-19', 'END': '2023-03-21'}"});
          <br />
          {/* CALL CODE.MOST_EXPENSIVE_QUERIES_TAGGING('APP',
          {
            "{ 'START': '2023-03-01', 'END': '2023-03-21','TAG':'COSTNOMICS_TAG'}"
          }
          ); */}
        </CCardText>
        <CCardText>
          4. <b>BILLING_BY_SERVICE_TYPE_TAGGING KPI</b> gives the billing
          information by service type(WH Compute,Storage) in your environment.{" "}
          <br />
          Parameter 1: Schema name (varchar).
          <br />
          Parameter 2: Value (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB_NAME>"};<br />
          CALL CODE.BILLING_BY_SERVICE_TYPE_TAGGING('APP',
          {"{ 'START': '2023-02-19', 'END': '2023-03-21'}"});
          <br />
          {/* CALL CODE.BILLING_BY_SERVICE_TYPE_TAGGING('APP',
          {
            "{ 'START': '2023-02-19', 'END': '2023-03-21','TAG':'COSTNOMICS_TAG'}"
          }
          ); */}
        </CCardText>
        <CCardText>
          5. <b>WAREHOUSE_WISE_QUERY_COUNT KPI</b> returns the warehouse name
          and its query count in your environment.
          <br />
          Parameter 1: Schema name (varchar).
          <br />
          Parameter 2: Value (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB_NAME>"};<br /> CALL
          CODE.WAREHOUSE_WISE_QUERY_COUNT_TAGGING('APP',
          {"{ 'START': '2023-02-19', 'END': '2023-03-21' }"});
          <br /> 
          {/* CALL CODE.WAREHOUSE_WISE_QUERY_COUNT('APP',
          {
            "{ 'START': '2023-03-01', 'END': '2023-03-21','TAG':'COSTNOMICS_TAG'}"
          }
          ); */}
        </CCardText>
        <CCardText>
          6. <b> AVERAGE_COST_PER_QUERY_BY_WAREHOUSE_TAGGING KPI</b> returns the
          average cost per query by warehouse in your environment. <br />
          Parameter 1: Schema name (varchar).
          <br />
          Parameter 2: Value (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB_NAME>"};<br />
          CALL CODE.AVERAGE_COST_PER_QUERY_BY_TAGGING('APP',
          {"{ 'START': '2023-02-19', 'END': '2023-03-21'}"});
          <br />
          {/* CALL CODE.AVERAGE_COST_PER_QUERY_BY_TAGGING('APP',
          {
            "{ 'START': '2023-02-19', 'END': '2023-03-21','TAG':'COSTNOMICS_TAG'}"
          }
          ); */}
        </CCardText>

        <CCardText>
          7. <b>DOLLARS_CONSUMED KPI </b> returns the total dollars spent per
          month in your environment. <br />
          Parameter 1: Schema name (varchar).
          <br />
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB_NAME>"};<br />
          CALL CODE.DOLLARS_CONSUMED('APP');
          <br />
        </CCardText>

        <CCardText>
          8. <b>CREDIT_USAGE_YEAR KPI </b> returns the total credits consumed
          per month in your environment. <br />
          Parameter 1: Schema name (varchar).
          <br />
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB_NAME>"};<br />
          CALL CODE.CREDIT_USAGE_YEAR('APP');
          <br />
        </CCardText>

        <CCardText>
          9. <b>COMPUTE_BILLED KPI </b> returns the compute billed per month in
          your environment. <br />
          Parameter 1: Schema name (varchar).
          <br />
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB_NAME>"};<br />
          CALL CODE.COMPUTE_BILLED('APP');
          <br />
        </CCardText>

        <CCardText>
          10. <b>DATA_INGESTED KPI </b> returns the total data ingested per
          month in MB for your environment.
          <br />
          Parameter 1: Schema name (varchar).
          <br />
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB_NAME>"};<br />
          CALL CODE.DATA_INGESTED('APP');
          <br />
        </CCardText>

        {/* <CCardText>
          11. <b>TAG_CREDITS KPI </b> returns the total credits for a tag in MB
          for your environment. Param 1: Schema name (varchar).
          <br />
          Param 1: Schema name (varchar).
          <br />
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB_NAME>"}; <br />
          CALL CODE.TAG_CREDITS('APP',
          {"{ 'START': '2023-02-19', 'END': '2023-03-21'}"});
          <br />
          CALL CODE.TAG_CREDITS('APP',
          {
            "{ 'START': '2023-02-19', 'END': '2023-03-21','TAG':'COSTNOMICS_TAG'}"
          }
          );
        </CCardText> */}
      </CCard>

      {/* <CCardText className="document-text" id="kpi_calls">
        {" "}
        Examples.
      </CCardText>
      <CCardText >
        This section contains some example SQL statements that Dash Board
        tools and Web UI can use.
      </CCardText>
      TO ADD DROPDOWN HERE

      <CCard style={{ padding: 10 }}>
        <CCardText className="document-text">
          <b>KPI call Examples</b>
        </CCardText>
        <CCardText>
          1) <b>CREDIT_USAGE KPI</b> returns the monthly credit usage for the current
          year. <br />
          Param : Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; CALL
          costnomics_setup.CREDIT_USAGE('costnomics_app');
        </CCardText>
        <CCardText>
          2) <b>WAREHOUSE_CREDITS KPI</b> returns the total credits used by
          warehouse. <br />
          Param 1: Schema name (varchar). <br />
          Param 2: DATASET (START DATE, END DATE, TAG) as variant.
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL costnomics_setup.WAREHOUSE_CREDITS('costnomics_app', {"{"}
          'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL costnomics_setup.WAREHOUSE_CREDITS('costnomics_app', {"{"}
          'START': '2022-08-01', 'END': '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>
        <CCardText>
          3. <b>MOST_EXPENSIVE_QUERIES KPI</b> returns the most expensive queries.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL costnomics_setup.MOST_EXPENSIVE_QUERIES('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL costnomics_setup.MOST_EXPENSIVE_QUERIES('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>
        <CCardText>
          4. <b>BILLING_BY_SERVICE_TYPE KPI</b> gives the billing information by service type(WH Compute,Storage) in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.BILLING_BY_SERVICE_TYPE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.BILLING_BY_SERVICE_TYPE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>
        <CCardText>
          5. <b>WAREHOUSE_WISE_QUERY_COUNT KPI</b> returns the warehouse name and its query count in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.WAREHOUSE_WISE_QUERY_COUNT('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.WAREHOUSE_WISE_QUERY_COUNT('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>
        <CCardText>
          6. <b>AVERAGE_COST_PER_QUERY_BY_WAREHOUSE KPI</b> returns the average cost per query by warehouse in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.AVERAGE_COST_PER_QUERY_BY_WAREHOUSE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.AVERAGE_COST_PER_QUERY_BY_WAREHOUSE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          7. <b>DOLLARS_CONSUMED KPI</b> returns the total dollars spent per month in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.DOLLARS_CONSUMED('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          8. <b>CREDIT_USAGE_YEAR KPI</b> returns the total credits consumed per month in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.CREDIT_USAGE_YEAR('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          9. <b>COMPUTE_BILLED KPI</b> returns the compute billed per month in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.COMPUTE_BILLED('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          10. <b>DATA_INGESTED KPI</b> returns the total data ingested per month in MB for your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.DATA_INGESTED('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          11. <b>CREDIT_CONSUMPTION_BY_WAREHOUSE KPI</b> returns the credits consumed by warehouses.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG ) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.CREDIT_CONSUMPTION_BY_WAREHOUSE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.CREDIT_CONSUMPTION_BY_WAREHOUSE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          12. <b>CREDIT_CONSUMPTION_BY_WAREHOUSE_LAST_SEVEN_DAYS KPI</b> returns the credit consumption by warehouses for the last seven days.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.CREDIT_CONSUMPTION_BY_WAREHOUSE_LAST_SEVEN_DAYS('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
        </CCardText>

        <CCardText>
          13. <b>CREDIT_CONSUMPTIONS_BY_USER KPI</b> returns the credits consumed by user.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG ) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.CREDIT_CONSUMPTIONS_BY_USER('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.CREDIT_CONSUMPTIONS_BY_USER('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          14. <b>PER_HOUR_QUERY_COUNT_FOR_LAST_DAY KPI</b> returns the per hour query count for the previous day.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.PER_HOUR_QUERY_COUNT_FOR_LAST_DAY('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          15. <b>PARTNER_TOOLS_CREDIT_CONSUMPTION KPI</b> returns the credits consumed by partner tools.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.PARTNER_TOOLS_CREDIT_CONSUMPTION('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.PARTNER_TOOLS_CREDIT_CONSUMPTION('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          16. <b>QUERIES_BY_EXECUTION_BUCKETS KPI</b> returns queries by execution buckets.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG ) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.QUERIES_BY_EXECUTION_BUCKETS('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.QUERIES_BY_EXECUTION_BUCKETS('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          17. <b>HEAVY_SCANNERS_USERS KPI</b> returns the top 10 users scanning the most data.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.HEAVY_SCANNERS_USERS('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.HEAVY_SCANNERS_USERS('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          18. <b>DATA_INGEST_METHOD KPI</b> returns data ingest methods used like snowpipe or copy, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG ) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.DATA_INGEST_METHOD('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.DATA_INGEST_METHOD('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          18. <b>CACHE_SCANNED_BY_WAREHOUSE KPI</b> returns the cache scanned by warehouses in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG ) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.CACHE_SCANNED_BY_WAREHOUSE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.CACHE_SCANNED_BY_WAREHOUSE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          19. <b>FULL_TABLE_SCANS_BY_USERS KPI</b> returns the list of users having executed full table scans.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG ) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.FULL_TABLE_SCANS_BY_USERS('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.FULL_TABLE_SCANS_BY_USERS('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          20. <b>TOP_TEN_SPILLERS KPI</b> returns the list of top ten data spillers, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.TOP_TEN_SPILLERS('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.TOP_TEN_SPILLERS('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          21. <b>CREDIT_CONSUMPTION_BY_SERVICE_TYPE KPI</b> returns credit consumption by service type.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.CREDIT_CONSUMPTION_BY_SERVICE_TYPE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.CREDIT_CONSUMPTION_BY_SERVICE_TYPE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          22. <b>MOST_EXPENSIVE_QUERIES_BY_PARTITIONS_SCANNED KPI</b> returns the most expensive queries by partitions scanned.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.MOST_EXPENSIVE_QUERIES_BY_PARTITIONS_SCANNED('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.MOST_EXPENSIVE_QUERIES_BY_PARTITIONS_SCANNED('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          23. <b>MOST_EXPENSIVE_QUERIES_BY_BYTES_SCANNED KPI</b> returns the most expensive queries by bytes scanned.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.MOST_EXPENSIVE_QUERIES_BY_BYTES_SCANNED('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.MOST_EXPENSIVE_QUERIES_BY_BYTES_SCANNED('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          24. <b>TOP_TEN_LOCAL_STORAGE_SPILLING KPI</b> returns the top ten local storage spilling queries.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.TOP_TEN_LOCAL_STORAGE_SPILLING('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.TOP_TEN_LOCAL_STORAGE_SPILLING('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          25. <b>AVERAGE_EXECUTION_TIME_FOR_ROLE KPI</b> returns the average execution time for different roles, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.AVERAGE_EXECUTION_TIME_FOR_ROLE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.AVERAGE_EXECUTION_TIME_FOR_ROLE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          26. <b>QUERY_COUNT_FOR_EACH_ROLE KPI</b> returns the query count for each role in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.QUERY_COUNT_FOR_EACH_ROLE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.QUERY_COUNT_FOR_EACH_ROLE('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          27. <b>WAREHOUSES_BY_CLOUD_SERVICE_USAGE_Graph KPI</b> returns warehouses by their cloud service usage.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.WAREHOUSES_BY_CLOUD_SERVICE_USAGE_Graph('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.WAREHOUSES_BY_CLOUD_SERVICE_USAGE_Graph('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          28. <b>FAILED_LOGINS KPI</b> returns the number of failed login attempts.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.FAILED_LOGINS('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.FAILED_LOGINS('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          29. <b>NO_OF_DB_IN_ACCOUNT KPI</b> returns the number of databases, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.NO_OF_DB_IN_ACCOUNT('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.NO_OF_DB_IN_ACCOUNT('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          30. <b>NO_OF_TABLES_IN_ACCOUNT KPI</b> returns the number of tables, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.NO_OF_TABLES_IN_ACCOUNT('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.NO_OF_TABLES_IN_ACCOUNT('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          31. <b>NO_OF_TABLES_IN_ACCOUNT KPI</b> returns the number of tables, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.NO_OF_TABLES_IN_ACCOUNT('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          32. <b>AVERAGE_QUERY_EXECUTION_TIME KPI</b> returns the average query execution time, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.AVERAGE_QUERY_EXECUTION_TIME('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.AVERAGE_QUERY_EXECUTION_TIME('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          33. <b>TOTAL_QUERY_COUNT KPI</b> returns the total number of queries executed, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.TOTAL_QUERY_COUNT('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.TOTAL_QUERY_COUNT('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          34. <b>CONFIGURATION_PROBLEMS_IN_WAREHOUSES KPI</b> returns the configuration problems, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
          <br />
          Param 2: DATASET (START DATE, END DATE, TAG) variant
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.CONFIGURATION_PROBLEMS_IN_WAREHOUSES('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END': '2022-08-17'{"}"}); <br />
          CALL datageir_dev_share.costnomics_setup.CONFIGURATION_PROBLEMS_IN_WAREHOUSES('costnomics_app',
          {"{"} 'START': '2022-08-01', 'END':
          '2022-08-17','TAG':'COSTNOMICS_TAG'{"}"});
        </CCardText>

        <CCardText>
          35. <b>USER_SEGMENTATION KPI</b> returns the number of roles in each warehouse, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.USER_SEGMENTATION('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          36. <b>IDLE_USERS_COUNT KPI</b> returns the number of idle users, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.IDLE_USERS_COUNT('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          37. <b>IDLE_ROLES_COUNT KPI</b> returns the number of idle roles, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.IDLE_ROLES_COUNT('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          38. <b>STALE_TABLE_STREAMS_COUNT KPI</b> returns the number of stale table streams created in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.STALE_TABLE_STREAMS_COUNT('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          39. <b>FAILED_TASKS_COUNT KPI</b> returns the number of failed tasks, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.FAILED_TASKS_COUNT('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          40. <b>LONG_RUNNING_TASKS_COUNT KPI</b> returns the number of long running tasks, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.LONG_RUNNING_TASKS_COUNT('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          41. <b>RECOMMENDATIONS_QUERY KPI</b> returns the list of recommendations for your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.RECOMMENDATIONS_QUERY('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          42. <b>FORECASTED_VS_ACTUAL_DOLLARS_USED_WEEKLY KPI</b> gives weekly forecasted dollars against actual dollars used in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.FORECASTED_VS_ACTUAL_DOLLARS_USED_WEEKLY('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          43. <b>FORECASTED_VS_ACTUAL_DOLLARS_USED_YEARLY KPI</b> gives yearly forecasted dollars against actual dollars used in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.FORECASTED_VS_ACTUAL_DOLLARS_USED_YEARLY('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          44. <b>FORECASTED_VS_ACTUAL_DOLLARS_USED_TRENDS_WEEKLY KPI</b> gives weekly trends for forecasted dollars against actual dollars used in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.FORECASTED_VS_ACTUAL_DOLLARS_USED_TRENDS_WEEKLY('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          45. <b>FORECASTED_VS_ACTUAL_DOLLARS_USED_TRENDS_YEARLY KPI</b> gives yearly trends for forecasted dollars against actual dollars used in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.FORECASTED_VS_ACTUAL_DOLLARS_USED_TRENDS_YEARLY('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          46. <b>FORECAST_SUMMARY KPI</b> gives a forecast summary for your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.FORECAST_SUMMARY('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          46. <b>WAREHOUSE_NAME KPI</b> returns the list of warehouse names in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.WAREHOUSE_NAME('costnomics_app'); <br />
        </CCardText>

        <CCardText>
          47. <b>MAX_DATE_FROM_AVERAGE_QUERY_VOLUME_BY_HOUR KPI</b> returns the max date for average query volume by hour, in your environment.{" "}
          <br />
          Param 1: Schema name (varchar).
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB_NAME{">"}; <br />
          CALL datageir_dev_share.costnomics_setup.MAX_DATE_FROM_AVERAGE_QUERY_VOLUME_BY_HOUR('costnomics_app'); <br />
        </CCardText>
      </CCard> */}
    </>
  );
}
export default Examples;
