import "./App.css";
import "../src/scss/style.scss";

import "@coreui/coreui/dist/css/coreui.min.css";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/MarketingHome";
import { BookDemo } from "./components/costnomics/BookDemo";
import { CandourSideMenu } from "./pages/v2_candour/CandourSideMenu";
import { HawkeyeSideMenu } from "./pages/v2_hawkeye/HawkeyeSideMenu";
import Documentation from "./pages/v1/documentation";
import { Costnomics } from "./pages/costnomics/Costnomics";
import LoginPage from "./demo/pages/Login";
import AdminLoginPage from "./demo/pages/AdminLogin";
const CostnomicsLayout = React.lazy(() =>
  import("./demo/layout/CostnomicsLayout")
);
const Domain = React.lazy(() => import("./demo/pages/Domains"));
const AppLayout = React.lazy(() => import("./demo/layout/AppLayout"));
// import SignupPage from "./demo/pages/Signup";
// import AdminLoginPage from "./demo/pages/AdminLogin";
// import ForgotPassword from "./demo/components/ForgotPassword";
const EnterpriseSetUp = React.lazy(() =>
  import("./demo/components/EnterpriseAccountSetup")
);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/" name="marketingHome" element={<Home />} />
          <Route path="/book-demo" name="bookDemo" element={<BookDemo />} />
          {/* Old route for costnomics */}
          {/* <Route path="*" name="costnomicsUserGuide" element={<AppLayout />} />
          <Route
            path="/V2/costnomics/user-guide"
            name="costnomicsUserGuide"
            element={<AppLayout />}
          /> */}

          {/* NEW modified route */}
          <Route path="*" name="costnomicsnew" element={<Costnomics />} />

          <Route
            path="/V2/candour/user-guide"
            name="candourUserGuide"
            element={<CandourSideMenu />}
          />
          <Route
            path="/V2/hawkeye/user-guide"
            name="hawkeyeUserGuide"
            element={<HawkeyeSideMenu />}
          />
          <Route
            path="/V1/costnomics/user-guide"
            name="costnomicsUserGuidev1"
            element={<Documentation />}
          />
          <Route
            path="/costnomics/demo"
            name="costnomicsDemo"
            element={<LoginPage />}
          />

          <Route
            path="/costnomics/demo/*"
            name="Costnomics"
            element={<CostnomicsLayout />}
          />
          <Route
            path="/costnomics/demo/datageir/*"
            name="Datageir"
            element={<AppLayout />}
          />

          <Route
            path="/costnomics/demo/admin-login"
            name="AdminLogin"
            element={<AdminLoginPage />}
          />

          {/* <Route path="/costnomics/demo/signup" element={<SignupPage />} /> */}

          <Route
            path="/costnomics/demo/account-setup"
            element={<EnterpriseSetUp />}
          />

          {/* <Route path="/costnomics/demo/forgot-password" element={<ForgotPassword />} /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
