import { React, useEffect, useState } from "react";
import {
  CTabPane,
  CTabContent,
  CButton,
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardBody,
} from "@coreui/react";
import { Link } from "react-router-dom";
import { loginFields } from "../constants/formFields";
import { useNavigate } from "react-router-dom";
import LoginService from "../services/login.service";
import enterpriseProfileImg from "../assets/enterprise_profile.png";
import AdminLogin from "../components/AdminLogin";
import SignupPage from "./Signup";
import UserLogin from "../components/UserLogin";
import UseToken from "../components/App/UseToken";
import UserDetailsService from "../services/user.detail.service";
import alldomainsListService from "../services/alldomains.list.service";
import requestflowService from "../services/requestflow.service";
import { UseUserDetails } from "../components/App/useUserDetails";
// redux import
import { useDispatch } from "react-redux";
import { updateUserUuid } from "../redux/userDetail";
import UseUUID from "../components/App/UseUUID";
import "../../scss/_custom.scss";
// import "../scss/_custom.scss"
import GoogleSignIn from "./GoogleSignIn";
import EnterpriseSetUp from "../components/EnterpriseAccountSetup";
import loginImg from "../assets/login_img.png";
import EnterpriseUUID from "../components/App/EnterpriseUUID";
import { UseDomainDetails } from "../components/App/UseDomainDetails";
import signup_licenseService from "../services/signup_license.service";
import domainListService from "../services/domain.list.service";
import { UseUserPlanDetails } from "../components/App/UseUserPlanDetails";

const fields = loginFields;
let fieldsState = {};

const loginType = {
  USER: "user",
  ADMIN: "admin",
};
const ServiceType = {
  ENTERPRISE: "ENTERPRISE",
  DOMAIN: "DOMAIN",
  PRODUCT: "PRODUCT",
  ENTERPRISE_USER: "ENTERPRISE_USER",
  DATA_SOURCE_USER: "DATA_SOURCE_USER",
};

fields.forEach((field) => (fieldsState[field.id] = ""));

export default function LoginPage() {
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const { accessToken, setAccessToken } = UseToken();
  const { idToken, setIdToken } = UseToken();
  const { UUID, setUUID } = UseUUID();
  const { enterpriseUUID, setEnterpriseUUID } = EnterpriseUUID();

  const [loginState, setLoginState] = useState(fieldsState);
  const [activeKey, setActiveKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [userNotExistError, setUserNotExistError] = useState(false);
  const [userExistVisible, setUserExistVisible] = useState(false);
  const [licenseExpired, setLicenseExpired] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const { domainDetails, setDomainDetails } = UseDomainDetails();
  const [rejectVisible, setRejectVisible] = useState(false);
  const { userDetails, setUserDetails } = UseUserDetails();
  const { userPlanDetails, setUserPlanDetails } = UseUserPlanDetails();

  const handleActiveKey = (activeState, email, name) => {
    setEmail(email);
    setName(name);
    setActiveKey(activeState);
  };
  const handleChange = (e) => {
    setErrorVisible(false);
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setVisible(false);
    authenticateUser();
  };
  const getLicense = async (userdetails) => {
    if (userdetails.request_type == "ENTERPRISE") {
      var repo = await signup_licenseService.GetUserLicense(userdetails.uuid);
      if (repo != undefined) {
        if (repo.length > 0) {
          let config = JSON.parse(repo[0].properties);
          return config;
        }
      }
    } else if (userdetails.request_type == "ENTERPRISE_USER") {
      var repo = await signup_licenseService.GetUserLicense(
        userdetails.parent_uuid
      );
      if (repo != undefined) {
        if (repo.length > 0) {
          let config = JSON.parse(repo[0].properties);
          return config;
        }
      }
    } else if (userdetails.request_type == "DOMAIN") {
      const respons = await domainListService.getParentId(
        userdetails.parent_uuid
      );
      if (respons[0].parent_uuid == "null" || respons[0].parent_uuid == null) {
        var repo = await signup_licenseService.GetUserLicense(
          userdetails.parent_uuid
        );
        if (repo != undefined) {
          if (repo.length > 0) {
            let config = JSON.parse(repo[0].properties);
            return config;
          }
        }
      } else {
        var repo = await signup_licenseService.GetUserLicense(
          respons[0].parent_uuid
        );
        if (repo != undefined) {
          if (repo.length > 0) {
            let config = JSON.parse(repo[0].properties);
            return config;
          }
        }
      }
    } else if (userdetails.request_type == "DATA_SOURCE_USER") {
      const respos = await domainListService.getParentId(
        userdetails.parent_uuid
      );

      const respons = await domainListService.getParentId(
        respos[0].parent_uuid
      );

      if (respons[0].parent_uuid == "null" || respons[0].parent_uuid == null) {
        var repo = await signup_licenseService.GetUserLicense(
          respos[0].parent_uuid
        );
        if (repo != undefined) {
          if (repo.length > 0) {
            let config = JSON.parse(repo[0].properties);
            return config;
          }
        }
      } else {
        var repo = await signup_licenseService.GetUserLicense(
          respons[0].parent_uuid
        );
        if (repo != undefined) {
          if (repo.length > 0) {
            let config = JSON.parse(repo[0].properties);
            return config;
          }
        }
      }
    }
  };

  const authenticateUser = async () => {
    setIsLoading(true);
    setUserNotExistError(false);
    var email = loginState["email-address"].toLowerCase();
    var passsword = loginState["password"];
    var response;
    if (activeKey == 0) {
      {
        const response = await UserDetailsService.getUserDetails(email);
        if (response != undefined && response != "") {
          // const licenseDetails = await getLicense(response);

          // let expiryDate = new Date(licenseDetails.end_date).getTime();
          // let currentDate = new Date().getTime();
          // if (currentDate <= expiryDate && response["is_active"] !== "N") {

          if (response != undefined && response != "") {
            if (
              (response["request_type"] == ServiceType.ENTERPRISE &&
                response["is_active"] === "Y") ||
              (response["request_type"] == ServiceType.ENTERPRISE_USER &&
                response["is_active"] === "Y")
            ) {
              setEnterpriseUUID(response["uuid"]);
              setUUID(null);
              const cognitoResp = await LoginService.login(
                email,
                passsword,
                loginType.USER
              );
              if (cognitoResp != undefined) {
                if (cognitoResp.status == 200) {
                  // setUserPlanDetails(
                  //   licenseDetails.start_date,
                  //   licenseDetails.end_date,
                  //   licenseDetails.plan
                  // );
                  // auth_type: "google"
                  // email: "vaibhav.kale@atgeirsolutions.com"
                  // owner: "vaibhav"
                  // parent_uuid: "null"
                  // request_type: "ENTERPRISE_USER"

                  // domain_description: "demo site"
                  // domain_name: "demo"
                  // email: "vaibhav.kale@atgeirsolutions.com"
                  // owner: "vaibhav(Self)"
                  setUserDetails(
                    response.parent_uuid,
                    response.email,
                    response.request_type,
                    response.auth_type,
                    response.owner
                  );
                  if (response.request_type == "DOMAIN") {
                    setDomainDetails(
                      response.service_name,
                      response.email,
                      response.description,
                      response.owner
                    );
                  }
                  setAccessToken(cognitoResp.data.accessToken);
                  setIdToken(cognitoResp.data.idToken);
                  let path = "/costnomics/demo/datageir/enterprise-dashboard";
                  navigate(path, { state: { data: cognitoResp } });
                  var action_id = 8;
                  var actionFlow = await requestflowService.registerRequest(
                    action_id,
                    cognitoResp["uuid"]
                  );
                } else {
                  setErrorVisible(true);
                }
              } else {
                setErrorVisible(true);
              }
            } else if (
              response["request_type"] == ServiceType.ENTERPRISE &&
              response["is_active"] === "N"
            ) {
              setErrorVisible(false);
              setRejectVisible(true);
              setVisible(false);
              setUserExistVisible(false);
            } else if (
              response["request_type"] == ServiceType.ENTERPRISE &&
              response["is_active"] === null
            ) {
              setErrorVisible(false);
              setRejectVisible(false);
              setVisible(true);
              setUserExistVisible(false);
            } else if (
              response["request_type"] == ServiceType.DOMAIN ||
              response["request_type"] == ServiceType.DATA_SOURCE_USER
            ) {
              setUUID(response["uuid"]);
              dispatch(updateUserUuid(response["uuid"]));
              setUserDetails(
                response.parent_uuid,
                response.email,
                response.request_type,
                response.auth_type,
                response.owner
              );
              setDomainDetails(
                response.service_name,
                response.email,
                response.description,
                response.owner
              );

              if (response.password == passsword) {
                const newtoken = await LoginService.getaccesstoken(
                  email,
                  passsword
                );
                setAccessToken(newtoken);

                let path = "/costnomics/demo/datageir/data-sources";
                navigate(path, { state: { data: response } });

                var action_id = 14;
                var actionFlow = await requestflowService.registerRequest(
                  action_id,
                  response["uuid"]
                );
              } else {
                setErrorVisible(true);
              }

              // setAccessToken(cognitoResp.data.accessToken);
              // setIdToken(cognitoResp.data.idToken);
              // let path = "/costnomics/demo/datageir/data-sources";
              // navigate(path, { state: { data: cognitoResp } });
              // var action_id = 14;
              // var actionFlow = await requestflowService.registerRequest(
              //   action_id,
              //   cognitoResp["uuid"]
              // );
              // } else {
              //   setErrorVisible(true);
              // }
              // } else {
              //   setErrorVisible(true);
              // }
            } else if (response["request_type"] == ServiceType.PRODUCT) {
              let path = "/costnomics/demo/datageir/productowner";
              navigate(path, { state: { data: response } });
              var action_id = 22;
              var actionFlow = await requestflowService.registerRequest(
                action_id,
                response["uuid"]
              );
            }
          } else {
            setUserNotExistError(true);
          }
          // }
          //  else if (expiryDate != Number && response["is_active"] !== "Y") {
          //   setVisible(true);
          // }
          // else {
          //   setLicenseExpired(true);
          // }
        } else {
          setUserNotExistError(true);
        }
      }
    } else if (activeKey == 2) {
      //admin login
      setLicenseExpired(false);
      response = await LoginService.login(email, passsword, loginType.ADMIN);

      setUserDetails("", "", "ADMIN", "", "");

      if (response != undefined && response != "") {
        if (response.data.accessToken) {
          setAccessToken(response.data.accessToken);
          setIdToken(response.data.idToken);
          let path = "/costnomics/demo/datageir/approved-enterprises";
          navigate(path);
        } else {
          setErrorVisible(true);
        }
      } else if (response == "") {
        userNotExistError(true);
      } else {
        setErrorVisible(true);
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="bg-setup-img min-vh-100 d-flex flex-row align-items-center ">
      <CContainer style={{ width: "55%" }}>
        <CRow className="justify-content-center tab-Shadow">
          <CCol md={7} className="d-flex p-0 rounded ">
            <CTabContent className="bg-white rounded-left w-100">
              <CTabPane
                role="tabpanel"
                aria-labelledby="home-tab"
                visible={activeKey === 0}
              >
                <UserLogin
                  fields={fields}
                  loginState={loginState}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  isLoading={isLoading}
                  errorVisible={errorVisible}
                  setErrorVisible={setErrorVisible}
                  loginType={"user"}
                  visible={visible}
                  setVisible={setVisible}
                  handleActiveKey={handleActiveKey}
                  rejectVisible={rejectVisible}
                  setRejectVisible={setRejectVisible}
                  setUserNotExistError={setUserNotExistError}
                  userNotExistError={userNotExistError}
                  userExistVisible={userExistVisible}
                  setUserExistVisible={setUserExistVisible}
                  licenseExpired={licenseExpired}
                  setLicenseExpired={setLicenseExpired}
                />
              </CTabPane>

              <CTabPane
                role="tabpanel"
                aria-labelledby="profile-tab"
                visible={activeKey === 1}
              >
                <SignupPage handleActiveKey={handleActiveKey} />
              </CTabPane>
            </CTabContent>
          </CCol>
          <CCol md={5} className="m-0 p-0">
            {activeKey != 3 ? (
              <CCard
                className=" m-0 py-2 rounded-0 resp-hide "
                style={{ height: "100%", backgroundColor: "#faf8fd" }}
              >
                <CCardBody className="text-center">
                  <div>
                    {activeKey == 1 ? (
                      <>
                        <h5 className="mx-0" style={{ fontSize: "20px" }}>
                          DataGeir™
                        </h5>{" "}
                        <p>
                          DataGeir™ is a solution package built for the cloud
                          that helps businesses get the most value from their
                          scattered, siloed data. Our products' cloud-based data
                          mesh framework guarantees hyper-scalable data asset
                          support and drives competitive advantage.
                        </p>
                      </>
                    ) : null}
                    <img src={loginImg} className="w-100" />

                    <a
                      href="https://mail.google.com/mail/u/0/?fs=1&to=info@atgeirsolutions.com&tf=cm"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      className="d-flex justify-content-center mt-0"
                    >
                      <CButton
                        color="primary"
                        className="m-0"
                        variant="outline"
                        tabIndex={-1}
                      >
                        Contact info@atgeirsolutions.com
                      </CButton>
                    </a>
                  </div>
                </CCardBody>
              </CCard>
            ) : null}
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}
