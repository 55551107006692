import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
} from "@coreui/react";
import laptopImage from "../../assets/marketingAssets/laptop_image.png";
import atgeirLogoLight from "../../assets/marketingAssets/Atgeir_Logo_Light.svg";
import costnomicsGif from "../../assets/marketingAssets/Costnomics_gif_image.gif";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const BookDemo = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      console.log(
        `Name: ${firstName}, Email: ${email}, CompanyName:${companyName}, Message: ${message}`
      );
    }
    setValidated(true);
  };

  return (
    <>
      <div className="book-demo-bg-img d-flex align-items-top">
        <CCol sm={6} className="d-flex flex-column align-items-center">
          <CContainer fluid className="p-0" style={{ width: "60%" }}>
            <img
              src={atgeirLogoLight}
              alt="atgeir logo"
              width="160"
              height="60"
              className="d-inline-block align-top p-1 cursor-pointer"
              onClick={() => navigate("/")}
            />
          </CContainer>

          <CContainer
            fluid
            className="bg-white p-2 rounded mt-4  m-0 position-relative book-demo-form-bg"
          >
            <CRow className="pt-2">
              <CCol sm={12} className="align-items-start m-auto">
                <div className={"text-align-center pb-2 form-title"}>
                  Book a Demo
                </div>

                {/* <div style={{fontSize:"12px", color:"grey", lineHeight:"19px"}} className="ps-2 pb-2">
          Share us a Few Details About Yourself and Let Our Product Expert Connect with You
          </div> */}

                <CForm
                  onSubmit={handleSubmit}
                  style={{ textAlign: "left" }}
                  noValidate
                  className="px-2 needs-validation"
                  validated={validated}
                >
                  <CRow>
                    <CCol>
                      <CFormLabel
                        htmlFor="firstName"
                        className="mt-2 mb-1 label-required text-white"
                      >
                        First Name
                      </CFormLabel>
                      <CFormInput
                        type="text"
                        id="firstName"
                        placeholder="Enter your first name"
                        feedbackInvalid="Please Enter Valid First Name"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        required
                      />
                    </CCol>
                    <CCol>
                      <CFormLabel
                        htmlFor="lastName"
                        className="mt-2 mb-1 label-required text-white"
                      >
                        Last Name
                      </CFormLabel>
                      <CFormInput
                        type="text"
                        id="lastName"
                        feedbackInvalid="Please Enter Valid Last Name"
                        placeholder="Enter your last name"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        required
                      />
                    </CCol>
                  </CRow>
                  {/* <CFormFeedback invalid>Please enter your name</CFormFeedback> */}

                  <CFormLabel
                    htmlFor="email"
                    className="mt-2 mb-1 label-required text-white"
                  >
                    Email
                  </CFormLabel>
                  <CFormInput
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                  />
                  <CFormFeedback invalid>
                    Please enter a valid email address
                  </CFormFeedback>

                  <CFormLabel
                    htmlFor="companyName"
                    className="mt-2 mb-1 label-required text-white"
                  >
                    Company Name
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    id="companyName"
                    feedbackInvalid="Please Enter Valid Company Name"
                    placeholder="Enter your company name"
                    value={companyName}
                    onChange={(event) => setCompanyName(event.target.value)}
                    required
                  />

                  <CFormLabel
                    htmlFor="message"
                    className="mt-2 mb-1 text-white"
                  >
                    Message
                  </CFormLabel>
                  <CFormTextarea
                    id="message"
                    placeholder="Enter your message"
                    value={message}
                    style={{ height: "50px" }}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                  <div style={{ textAlign: "right" }}>
                    <CButton
                      variant="outline"
                      color="light"
                      type="submit"
                      className="mt-3 px-5 font-weight-600"
                    >
                      Submit
                    </CButton>
                  </div>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CCol>
        <CCol sm={6} className="position-relative">
          <CContainer
            className="position-absolute p-0"
            style={{
              width: "120%",
              height: "100vh",
              right: "-2%",
            }}
          >
            <img
              src={laptopImage}
              alt="laptop"
              className="m-0 p-0 "
              style={{ width: "100%", height: "100%" }}
            />
            <CContainer
              className="position-absolute p-0"
              style={{ top: "16.2%", right: "-12.5%" }}
            >
              <img
                style={{ width: "77%", height: "58vh" }}
                alt="atgeir gif"
                src={costnomicsGif}
                // className="laptop-img"
              />
            </CContainer>
          </CContainer>
        </CCol>
      </div>
    </>
  );
};
