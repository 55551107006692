import React, { useState } from "react";
import { cilChevronBottom, cilChevronTop} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCardText,
  CCol,
  CCollapse,
  CContainer,
  CHeaderDivider,
} from "@coreui/react";
import { Title } from "../Title";

export const FAQ = (props) => {
  const [expandedId, setExpandedId] = useState(null);

  const toggleFAQ = (id) => {
    if (id === expandedId) {
      setExpandedId(null); // Collapse if clicked FAQ is already expanded
    } else {
      setExpandedId(id); // Expand the clicked FAQ
    }
  };

  return (
    <CContainer className="m-0 pb-5 pt-3 " fluid>
      {/* <CCol sm={12} className="m-auto p-2 pb-4" style={{fontSize:"30px", fontWeight:"800",textAlign:"center"}}>Frequently asked questions</CCol> */}
      {props.data !== undefined
        ? props.data.map((faq, index) => (
            <>
              <CCard className="bg-transparent border-0">
                <CCardBody className="m-auto" style={{ width: "70%",backdropFilter:"blur(20px)" }}>
                  <CCol
                    onClick={() => toggleFAQ(index)}
                    className="d-flex align-items-center justify-content-between cursor-pointer"
                  >
                    <CCardText
                      className="m-0 p-0"
                      style={{ fontSize: "18px", fontWeight: "700",color:"#D2D2D2" }}
                    >
                      {faq[0]}
                    </CCardText>
                    <CIcon
                      icon={
                        expandedId === index ? cilChevronTop : cilChevronBottom
                      }
                      style={{
                        transition: "transform 0.3 ease-in-out",
                        height:"16px",
                        width:"30px",
                        color:"#D2D2D2"
                      
                      }}
                    />
                  </CCol>
                  <CCollapse visible={expandedId === index}>
                    <CCardText
                      className="pt-2"
                      style={{
                        width: "80%",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#C2C2C2",
                      }}
                    >
                      {faq[1]}
                    </CCardText>
                  </CCollapse>
                  {props.data.length!==index+1?<CHeaderDivider
                    className="mt-3"
                    style={{ border: "1px solid #7a869a" }}
                  />:null}
                </CCardBody>
              </CCard>
            </>
          ))
        : null}
    </CContainer>
  );
};
