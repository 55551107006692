import React from "react";
import { CCard, CCardText, CImage } from "@coreui/react";
import { DocumentTitle } from "../component/DocumentTitle";
import { CodeView } from "../component/CodeView";
import { DocumentationNote } from "../component/DocumentationNote";
import DocumentationNoteInfo from "../component/DocumentationNoteInfo";
import get_application from "../../../assets/CandourAssets/get_application.jpg";

function GetTheApplicationCandour() {
  return (
    <>
      <DocumentTitle title={"Get The Application"} />
      <CCard style={{ padding: 10 }}>
        <CCardText>
          Please go to the <a href="https://app.snowflake.com/marketplace" target="_blank">Snowflake marketplace</a> and search for the “CANDOUR”
          app.
        </CCardText>
        <CCardText>
          Click on the “Get” button and specify the application name as per
          your organization's policies.
        </CCardText>
        <div className="clearfix">
          <CImage src={get_application} width={250} />
        </div>
        <br />
        <DocumentationNoteInfo
          text={`AFTER SUCCESSFUL INSTALLATION OF THE APP, RUN POST-INSTALLATION SETUP AS PER SECTION BELOW.`}
        />
      </CCard>
    </>
  );
}
export default GetTheApplicationCandour;
