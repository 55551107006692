import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
} from "@coreui/react";
// import CostnomicsImg from 'src/assets/images/CostNomics_Logo_doc.png'
// import companyLogo from 'src/assets/images/Atgeir_Logo.png'

function UninstallationSteps() {
  return (
    <>
      <CCardText className="document-text"> Uninstallation Steps.</CCardText>
      <CCard style={{ padding: 10 }}>
        <CCardText>
          This section contains steps to remove CostNomics™ native apps.
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE ROLE ACCOUNTADMIN;
          <br />
          DROP WAREHOUSE COSTNOMICSAPP_WH; // or warehouse used by native app.
          <br />
          DROP DATABASE {"<DB_NAME>"};<br />
          DROP ROLE DATAGEIR_COSTNOMICS_PROD_ROLE;
          <br />
        </CCardText>
      </CCard>
    </>
  );
}
export default UninstallationSteps;
