import {
  CCol,
  CHeaderDivider,
  CNavGroup,
  CNavItem,
  CNavTitle,
  CSidebar,
  CSidebarNav,
  CSidebarToggler,
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CNav,
  CCardImage,
  CFooter,
  CNavLink,
  CHeader,
  CTabContent,
  CTabPane,
  CSidebarBrand,
} from "@coreui/react";
import { useEffect, useState } from "react";
import "../../scss/style.scss";
import Navbar from "./component/Navbar";
import companyLogo from "../../assets/documentationAssets/images/Atgeir-New-Logo_Light.svg";

import { Link } from "react-router-dom";
import "../../scss/docs.css";
import IntroductionCandour from "./pages/IntroductionCandour";
import PreInstallationSetupCandour from "./pages/PreInstallationSetupCandour copy";
import GetTheApplicationCandour from "./pages/GetTheApplicationCandour";
import PostInstallationSetupCandour from "./pages/PostInstallationSetupCandour";
import DataPopulationStepsCandour from "./pages/DataPopulationStepsCandour";
import SampleDatasetExampleCandour from "./pages/SampleDatasetExampleCandour";
import TaskSchedulingCandour from "./pages/TaskSchedulingCandour";
import StreamlitAppCandour from "./pages/StreamlitAppCandour";
import UninstallationStepsCandour from "./pages/UninstallationSteps_v2";
export const CandourSideMenu = () => {
  const [activeKey, setActiveKey] = useState(1);
  const URL = window.location.href;

  useEffect(() => {
    let k = URL.split("#")[1];
    if (k == "introduction") {
      setActiveKey(1);
    } else if (k == "pre-installation") {
      setActiveKey(2);
    } else if (k == "get-the-application") {
      setActiveKey(3);
    } else if (k == "post-installation") {
      setActiveKey(4);
    } else if (k == "data-population") {
      setActiveKey(5);
    } else if (k == "task-scheduling") {
      setActiveKey(7);
    } else if (k == "streamlit-app") {
      setActiveKey(8);
    } else if (k == "uninstallation") {
      setActiveKey(9);
    } else if (k == "sample-dataset-example") {
      setActiveKey(6);
    }
  }, []);

  return (
    <div className="p-0 m-0 w-100">
      <Navbar />
      <div className="d-flex p-0 m-0">
        <CSidebar className="guide-sidebar">
          <CSidebarBrand style={{ backgroundColor: "#01245e" }}>
            <CCol
              style={{ height: "55px" }}
              sm={9}
              className=" d-flex align-items-center"
            >
              <CCardImage src={companyLogo} />
            </CCol>
          </CSidebarBrand>
          <CSidebarNav style={{ borderRight: "1px solid lightgray" }}>
            <CNavTitle className="font-14">Guides</CNavTitle>
            <CNavItem
              href="#introduction"
              active={activeKey === 1}
              onClick={() => setActiveKey(1)}
              className="sidemenu-navitem-bold "
            >
              Introduction
            </CNavItem>
            <CHeaderDivider className="border my-2" />
            <CNavGroup toggler="Installation" className="sidemenu-navitem-bold">
              <CNavItem
                active={activeKey === 2}
                onClick={() => setActiveKey(2)}
                href="#pre-installation"
                className="sidemenu-navitem mt-1 colors"
              >
                Pre-Installation Setup
              </CNavItem>
              <CNavItem
                active={activeKey === 3}
                onClick={() => setActiveKey(3)}
                href="#get-the-application"
                className="sidemenu-navitem"
              >
                Get the Application
              </CNavItem>
              <CNavItem
                active={activeKey === 4}
                onClick={() => setActiveKey(4)}
                href="#post-installation"
                className="sidemenu-navitem"
              >
                Post-Installation Setup
              </CNavItem>
            </CNavGroup>
            <CHeaderDivider className="border my-2" />
            <CNavItem
              active={activeKey === 5}
              onClick={() => setActiveKey(5)}
              href="#data-population"
              className="sidemenu-navitem-bold"
            >
              Data Population Steps
            </CNavItem>

            <CHeaderDivider className="border my-2" />
            <CNavItem
              active={activeKey === 7}
              onClick={() => setActiveKey(7)}
              href="#task-scheduling"
              className="sidemenu-navitem-bold"
            >
              Task Scheduling
            </CNavItem>
            <CHeaderDivider className="border my-2" />
            <CNavItem
              active={activeKey === 8}
              onClick={() => setActiveKey(8)}
              href="#streamlit-app"
              className="sidemenu-navitem-bold"
            >
              Streamlit App
            </CNavItem>
            <CHeaderDivider className="border my-2" />
            <CNavItem
              active={activeKey === 9}
              onClick={() => setActiveKey(9)}
              href="#uninstallation"
              className="sidemenu-navitem-bold"
            >
              Uninstallation
            </CNavItem>
            <CHeaderDivider className="border my-2" />

            <CNavItem
              active={activeKey === 6}
              onClick={() => setActiveKey(6)}
              href="#sample-dataset-example"
              className="sidemenu-navitem-bold"
            >
              Sample Dataset Example
            </CNavItem>
            <CHeaderDivider className="border my-2" />
          </CSidebarNav>
          <CCardText className="font-sm pb-2">
            <a href="https://www.atgeirsolutions.com/" target="_blank">
              Atgeir Solutions™
            </a>
            © 2023 All Rights Reserved.
          </CCardText>
        </CSidebar>
        <CTabContent style={{ paddingLeft: "260px", paddingTop: "80px" }}>
          <CTabPane
            role="tabpanel"
            aria-labelledby="home-tab"
            visible={activeKey === 1}
          >
            <IntroductionCandour />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="profile-tab"
            visible={activeKey === 2}
          >
            <PreInstallationSetupCandour />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="profile-tab"
            visible={activeKey === 3}
          >
            <GetTheApplicationCandour />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="contact-tab"
            visible={activeKey === 4}
          >
            <PostInstallationSetupCandour />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="contact-tab"
            visible={activeKey === 5}
          >
            <DataPopulationStepsCandour setActiveKey={setActiveKey} />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="contact-tab"
            visible={activeKey === 6}
          >
            <SampleDatasetExampleCandour />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="contact-tab"
            visible={activeKey === 7}
          >
            <TaskSchedulingCandour />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="contact-tab"
            visible={activeKey === 8}
          >
            <StreamlitAppCandour />
          </CTabPane>
          <CTabPane
            role="tabpanel"
            aria-labelledby="contact-tab"
            visible={activeKey === 9}
          >
            <UninstallationStepsCandour />
          </CTabPane>
        </CTabContent>
      </div>
    </div>
  );
};
