import { CCol, CContainer, CRow } from "@coreui/react";
import BillingAnalysis from "../../../assets/marketingAssets/Features/dashboard/billing.png";
import Configuration from "../../../assets/marketingAssets/Features/dashboard/configuration.png";
import Performance from "../../../assets/marketingAssets/Features/dashboard/performance.png";
import ResourceConsumption from "../../../assets/marketingAssets/Features/dashboard/resource.png";
import Usage from "../../../assets/marketingAssets/Features/dashboard/usage.png";
import { useEffect, useState } from "react";

const ImageArray = [
  BillingAnalysis,
  Configuration,
  Performance,
  ResourceConsumption,
  Usage,
];
const ImageSlider = () => {
  const [selectedImage, setSelectedImage] = useState(ImageArray[0]);
  const [isHovered, setIsHovered] = useState(false);
  const [slideInterval, setSlideInterval] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    let i = imageIndex;
    if (!isHovered) {
      // Start the slide interval when mouse is not hovering
      setSlideInterval(
        setInterval(() => {
          if (i <= 4) {
            setSelectedImage(ImageArray[i++]);
          } else {
            i = 0;
            setImageIndex(0);
            setSelectedImage(ImageArray[i]);
          }
        }, 2000)
      );
    } else {
      // Clear the slide interval when mouse is hovering
      clearInterval(slideInterval);
    }

    return () => {
      // Clear the slide interval on component unmount
      clearInterval(slideInterval);
    };
  }, [isHovered]);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = (index) => {
    setIsHovered(false);
    setImageIndex(index);
  };
  return (
    <CContainer className="pt-0" fluid>
      <CRow className="justify-content-center align-items-center">
        <CCol xs={12} sm={12} md={6} className="p-2 slider-img-container" style={{backgroundColor:"#ebedef"}}>
          <img 
            src={selectedImage}
            alt="enterpriseDashboard"
            className="slider-img"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </CCol>
      </CRow>
      <CRow className="justify-content-center align-items-center mt-4">
        <CCol
          sm={6}
          className="d-flex justify-content-center align-items-center slider-toggler-icon gap-2"
          style={{
            perspective: " 10px",
            perspectiveOrigin: "center center",
            transformStyle: "preserve-3d",
            position: "relative",
          }}
        >
          {ImageArray.map((image, index) => (
            <CCol
              key={index}
              className="d-flex justify-content-center align-items-center"
              sm={2}
            >
              <img
                src={image}
                alt="enterpriseDashboard"
                className="cursor-pointer slider-toggler-image"
                onClick={() => setSelectedImage(image)}
                style={{
                  width: "100%",
                  height: "65px",
                  padding: "2px",
                  backgroundColor: "#e7e8ea",
                  borderRadius: "5px",
                  border:selectedImage==image?"2px solid #f77f2d":"2px solid transparent"
                }}
                onMouseEnter={() => {
                  handleMouseEnter(image);
                }}
                onMouseLeave={() => handleMouseLeave(index)}
              />
            </CCol>
          ))}
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default ImageSlider;
