import React from "react";
import { CCard, CCardText, CHeaderDivider, CImage } from "@coreui/react";
import { DocumentTitle } from "../component/DocumentTitle";
import { CodeView } from "../component/CodeView";
import { DocumentationNote } from "../component/DocumentationNote";
import DocumentationNoteInfo from "../component/DocumentationNoteInfo";
import streamlit2 from "../../../assets/CandourAssets/candour_streamlit2.png";
import streamlit3 from "../../../assets/CandourAssets/candour_streamlit3.png";
import candour1 from "../../../assets/CandourAssets/candour1.jpg";
import candour2 from "../../../assets/CandourAssets/candour2.png";
import candour3 from "../../../assets/CandourAssets/candour3.png";
import candour4 from "../../../assets/CandourAssets/candour4.png";
import candour5 from "../../../assets/CandourAssets/candour5.png";
import candour6 from "../../../assets/CandourAssets/candour6.png";
import candour7 from "../../../assets/CandourAssets/candour7.png";
import candour8 from "../../../assets/CandourAssets/candour8.png";
import candour9 from "../../../assets/CandourAssets/candour9.png";
import candour10 from "../../../assets/CandourAssets/candour10.png";
function StreamlitAppCandour() {
  return (
    <>
      <DocumentTitle title={"Streamlit App"} />
      <CCard style={{ padding: 10 }}>
        <CCardText>
          1) Log into Snowsight, the Snowflake web interface.
        </CCardText>
        <br />

        <CCardText>
          2) Click the dropdown menu next to your login name, then click Switch
          Role » ACCOUNTADMIN.
        </CCardText>
        <div className="clearfix">
          <CImage src={streamlit2} width={850} />
        </div>
        <br />
        <CCardText>
          3) Click on “Apps”, select the created application and then select the
          warehouse created for the application.
        </CCardText>
        <div className="clearfix">
          <CImage src={streamlit3} width={850} />
        </div>
        <br />
        <CCardText>4) Candour Dashboards</CCardText>
        <div className="clearfix">
          <CImage src={candour1} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={candour5} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={candour2} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={candour3} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={candour4} width={850} />
        </div>
        <br />

        <div className="clearfix">
          <CImage src={candour6} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={candour7} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={candour8} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={candour9} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={candour10} width={850} />
        </div>
        <br />
        
      </CCard>
    </>
  );
}
export default StreamlitAppCandour;
