import {
  CContainer,
  CHeaderDivider,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavbarToggler,
  CNavItem,
  CNavLink,
  COffcanvas,
  COffcanvasBody,
  COffcanvasHeader,
} from "@coreui/react";
import { useState } from "react";
import { NavbrandLogo } from "./NavBrandLogo";
import { NavItem } from "./NavItem";
import { Link } from "react-scroll";
import CIcon from "@coreui/icons-react";
import { cilHamburgerMenu, cilX } from "@coreui/icons";
import { BookADemo } from "../Reusable/BookADemoButton";

export const Navbar = (props) => {
  const [visible, setVisible] = useState(false);
  return (
    <CNavbar
      expand="lg"
      colorScheme="light"
      placement="sticky-top"
      className="small custom-active-color m-0 p-0"
      style={{ backgroundColor: "#01245E" }}
    >
      <CContainer
        fluid
        className="m-0 p-0 d-flex align-items-center position-relative"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <CNavbarBrand href="#" className="m-0 ms-2 p-0">
          <NavbrandLogo />
        </CNavbarBrand>

        <COffcanvas
          id="offcanvasNavbar"
          placement="end"
          portal={false}
          visible={visible}
          onHide={() => setVisible(false)}
          className="off-canvas-width"
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#01245e",
          }}
        >
          <COffcanvasHeader>
            <CNavbarBrand href="#" className="m-0 ms-2 p-0">
              <NavbrandLogo classes="logo-in-header" />
            </CNavbarBrand>
            <CIcon
              size="lg"
              style={{ color: "white" }}
              className="me-2"
              icon={cilX}
              onClick={() => setVisible(false)}
            />
            {/* <CCloseButton
            style={{color:'white'}}
              className="text-reset"
              onClick={() => setVisible(false)}
            /> */}
          </COffcanvasHeader>
          <COffcanvasBody>
            <CNavbarNav className="d-flex justify-content-center custom-navbarnav gap-2">
              <Link
                activeClass="custom-navlink-active "
                to="Home"
                spy={true}
                smooth={true}
                hashSpy={true}
                duration={400}
                ignoreCancelEvents={false}
              />
              <Link
                activeClass="custom-navlink-active "
                to="problems"
                spy={true}
                smooth={true}
                hashSpy={true}
                duration={400}
                ignoreCancelEvents={false}
              />
              <Link
                activeClass="custom-navlink-active "
                to="solutions"
                spy={true}
                smooth={true}
                hashSpy={true}
                duration={400}
                ignoreCancelEvents={false}
              />
              <NavItem title="Features" id={"features"} />
              <CHeaderDivider className="custom-devider" />

              <CNavItem className="d-flex justify-content-center align-items-center  custom-navlink-other">
                <CNavLink
                  href="costnomics/user-guide"
                  className="custom-navlink "
                >
                  Documentation
                </CNavLink>
              </CNavItem>
              {/* <NavItem title="Documentation" id="" /> */}
              <CHeaderDivider className="custom-devider" />
              <NavItem title="Pricing" id="pricing" />
              <CHeaderDivider className="custom-devider" />
              <NavItem title="Contact Us" id="contact-us" />
              <CNavItem className="d-flex justify-content-center align-items-center  custom-navlink-other">
                <CNavLink href="costnomics/demo" className="custom-navlink ">
                  Demo
                </CNavLink>
              </CNavItem>

              <CHeaderDivider className="custom-devider" />
            </CNavbarNav>
            <CNavbarNav className="custom-navbarnav2 gap-2">
              <BookADemo
                lightShadow={false}
                setToastVisible={props.setToastVisible}
                setErrorToastVisible={props.setErrorToastVisible}
              />
              <CHeaderDivider className="custom-devider" />
              <CNavItem className="d-flex justify-content-center align-items-center  custom-navlink-other">
                <CNavLink
                  href="https://costnomics.datageir.ai/"
                  target="_blank"
                  className="custom-navlink"
                >
                  Sign In
                </CNavLink>
              </CNavItem>
              <CHeaderDivider className="custom-devider" />
            </CNavbarNav>
          </COffcanvasBody>
        </COffcanvas>

        <CNavbarToggler
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
          onClick={() => setVisible(!visible)}
          className="navbar-toggler-button"
        >
          <CIcon size="xl" icon={cilHamburgerMenu} addToggler />
        </CNavbarToggler>
      </CContainer>
    </CNavbar>
  );
};
