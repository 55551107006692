import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
} from "@coreui/react";
import { DocumentTitle } from "../component/DocumentTitle";

function IntroductionCandour() {
  return (
    <>
      <DocumentTitle title="Introduction" />
      <CCard style={{ padding: 10 }}>
        <CCardText className="p-0 text-justify-justify pb-0 mb-2">
          Introducing Candour, the ultimate ML observability tool designed to
          elevate the performance of your machine learning models in production.
          With its advanced capabilities, Candour ensures that you stay one step
          ahead by comparing reference and production datasets, continuously
          monitoring incoming data for any abnormalities. Experience the power
          of data-driven decision-making as Candour provides insightful
          visualizations, enabling you to address data quality, data drift, and
          data integrity issues with precision. Seamlessly integrated with the
          Snowflake Streamlit App, Candour offers an intuitive and interactive
          interface, empowering you to explore captured KPIs, unlock valuable
          recommendations, and gain deep visibility into your ML pipelines.
          Revolutionize your monitoring process and unleash the true potential
          of your ML models with Candour. Elevate performance, enhance data
          quality - choose Candour.
        </CCardText>
        <b>Key Features:</b>
        <CCardText className="ps-2 mt-2 text-justify-justify pb-0 mb-1">
          <b>Data Drift Monitoring: </b>
          The Data Drift feature in Candour allows you to detect changes in data
          distribution over time. By comparing data samples from different time
          datasets, Candour calculates various statistical measures and distance
          metrics to quantify the degree of drift. Key features of the Data
          Drift module include:
          <CCardText className="ps-2 mt-1 text-justify-justify pb-0 mb-2">
            <ul>
              <li>
                <b>Visualization of drift metrics:</b> Candour generates
                visualizations using streamlit, such as distribution comparison
                plots , to illustrate the changes in data distribution.
              </li>
              <li>
                <b>Drift detection:</b> Candour automatically detects
                significant drift based on predefined thresholds or statistical
                tests.
              </li>
            </ul>
          </CCardText>
        </CCardText>
        <CCardText className="ps-2 text-justify-justify pb-0 mb-2">
          <b>Data Quality Assessment: </b>
          Candour's Data Quality & Integrity feature enables comprehensive
          evaluation and monitoring of data quality. It provides a wide range of
          metrics to assess completeness, correctness, consistency, and
          uniqueness of datasets. Key features include:
          <CCardText className="ps-2 mt-1 text-justify-justify pb-0 mb-2">
            <ul>
              <li>
                <b>Calculation of Data Quality Metrics:</b> Candour computes
                metrics such as missing values percentage, unique values count,
                and data type consistency.
              </li>
              <li>
                <b>Visualization of Data Quality Metrics:</b> Candour generates
                visualizations, such as pie charts, to present data quality
                metrics in an intuitive and easy-to-understand manner.
              </li>
            </ul>
          </CCardText>
        </CCardText>
        <CCardText className="ps-2 text-justify-justify pb-0 mb-2">
          <b>Regression Analysis: </b>
          Candour's Regression analysis feature enables users to train a new
          regression model using their dataset. It provides a wide range of
          metrics to assess the overall performance of the newly trained model.
          Key features include:
          <CCardText className="ps-2 mt-1 text-justify-justify pb-0 mb-2">
            <ul>
              <li>
                <b>Regression models to choose from:</b> Candour provides 5
                regression models: Linear regression, Decision Tree, Gradient
                Boosting, Lasso and Ridge.
              </li>
              <li>
                <b> Visualization of the result in streamlit</b>
              </li>
            </ul>
          </CCardText>
        </CCardText>
        <CCardText className="ps-2 text-justify-justify pb-0 mb-2">
          <b>Classification Analysis: </b>
          Candour's Classification analysis feature enables users to train a new
          classification model using their dataset. It provides a wide range of
          metrics to assess the overall performance of the newly trained model.
          Key features include:
          <CCardText className="ps-2 mt-1 text-justify-justify pb-0 mb-2">
            <ul>
              <li>
                <b>Classification models to choose from:</b> Candour provides 4
                classification models: Decision Tree, Gradient Boosting, Random
                Forest and Extra Trees.
              </li>
              <li>
                <b> Visualization of the result in streamlit</b>
              </li>
            </ul>
          </CCardText>
        </CCardText>
        <b>Benefits:</b>
        <CCardText className="ps-2 mt-2 text-justify-justify pb-0 mb-1">
          <b>Proactive Issue Identification: </b> Candour empowers organizations
          to identify abnormalities or discrepancies in their machine learning
          pipelines in advance, allowing proactive steps to be taken for
          maintaining prediction accuracy.
        </CCardText>
        <CCardText className="ps-2 text-justify-justify pb-0 mb-2">
          <b>Enhanced Performance Monitoring:</b> By leveraging Candour's
          insights on data drift, data quality & data integrity, organizations
          can evaluate the overall impact on machine learning models and make
          informed decisions for improvement.
        </CCardText>
        <CCardText className="ps-2 text-justify-justify pb-0 mb-2">
          <b>Streamlined User Experience:</b> Candour seamlessly integrates with
          the Snowflake Streamlit App, offering a user-friendly interface for
          interactive exploration, visualization of key performance indicators
          (KPIs), and recommendations.
        </CCardText>

        <CCardText className="p-0 text-justify-justify pb-0 mb-2">
          Candour empowers organizations to compare datasets, identify
          discrepancies, and monitor machine learning models in production. By
          ensuring optimal performance and facilitating continuous improvement
          in data quality and model prediction, Candour enables organizations to
          make data-driven decisions with confidence.
        </CCardText>
      </CCard>
    </>
  );
}

export default IntroductionCandour;
