import React from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
} from '@coreui/icons';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react';

const navigation = [
  {
    component: CNavItem,
    name: 'New Release',
    to: '/costnomics/user-guide/new_release',
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },

  {
    component: CNavItem,
    name: 'Introduction',
    to: '/costnomics/user-guide/introduction',
  },
  {
    component: CNavGroup,
    name: 'Installation',
    to: '/installation',
    items: [
      {
        component: CNavItem,
        name: 'Prerequisites',
        to: '/costnomics/user-guide/installation/prerequisites',
      },
      {
        component: CNavItem,
        name: 'Pre-Installation Setup',
        to: '/costnomics/user-guide/installation/pre-installation',
      },
      {
        component: CNavItem,
        name: 'Get the Application',
        to: '/costnomics/user-guide/installation/get-application',
      },
      {
        component: CNavItem,
        name: 'Post-Installation Setup',
        to: '/costnomics/user-guide/installation/post-installation',
      },
    ],
  },

  {
    component: CNavItem,
    name: 'First-Time Data Population Steps',
    to: '/costnomics/user-guide/data-population',
  },
  {
    component: CNavItem,
    name: 'Task Scheduling',
    to: '/costnomics/user-guide/task-scheduling',
  },
  {
    component: CNavItem,
    name: 'Web Application Registration',
    to: '/costnomics/user-guide/web-application-registration',
  },
  {
    component: CNavGroup,
    name: 'Features',
    to: '/features',
    items: [
      {
        component: CNavItem,
        name: 'Enterprise Dashboards',
        to: '/costnomics/user-guide/features/enterprise-dashboard',
      },
      {
        component: CNavItem,
        name: 'Cost Centre (Tags)',
        to: '/costnomics/user-guide/features/cost-centre-tags',
      },
      {
        component: CNavItem,
        name: 'Root Cause Analysis',
        to: '/costnomics/user-guide/features/root-cause-analysis',
      },
      {
        component: CNavItem,
        name: 'Looker',
        to: '/costnomics/user-guide/features/looker',
      },
      {
        component: CNavItem,
        name: 'Airflow',
        to: '/costnomics/user-guide/features/airflow',
      },
      {
        component: CNavItem,
        name: 'Budget Management',
        to: '/costnomics/user-guide/features/BudgetManagement',
      },
      {
        component: CNavItem,
        name: 'Resource Optimization',
        to: '/costnomics/user-guide/features/resource-optimization',
      },
      {
        component: CNavItem,
        name: 'Workloads',
        to: '/costnomics/user-guide/features/workload',
      },
      {
        component: CNavItem,
        name: 'Alert and Monitoring',
        to: '/costnomics/user-guide/features/alert-monitoring',
      },
      {
        component: CNavItem,
        name: 'Forecasts',
        to: '/costnomics/user-guide/features/forecasts',
      },
      {
        component: CNavItem,
        name: 'Dashboards',
        to: '/costnomics/user-guide/features/dashboards',
      },
      {
        component: CNavItem,
        name: 'Query Acceleration',
        to: '/costnomics/user-guide/features/query-acceleration-service',
      },
      {
        component: CNavItem,
        name: 'Auto Suspend',
        to: '/costnomics/user-guide/features/auto-suspend-experimentation',
      },
      {
        component: CNavItem,
        name: 'Warehouse Experimentation',
        to: '/costnomics/user-guide/features/warehouse-experimentation',
      },
      {
        component: CNavItem,
        name: 'Custom Reports',
        to: '/costnomics/user-guide/features/custom-reports',
      },
      {
        component: CNavItem,
        name: 'Query Debugger',
        to: '/costnomics/user-guide/features/query-debugger',
      },
      {
        component: CNavItem,
        name: 'KPI Call Examples',
        to: '/costnomics/user-guide/features/Kpi-call-examples',
      },
    ],
  },

  {
    component: CNavItem,
    name: 'Streamlit App',
    to: '/costnomics/user-guide/streamlit-app',
  },
  {
    component: CNavItem,
    name: 'Uninstallation Steps',
    to: '/costnomics/user-guide/uninstallation',
  },
  // {
  //   component: CNavItem,
  //   name: "Release History",
  //   to: "/costnomics/user-guide/release_history",
  // },
];
export default navigation;
