import { CContainer, CRow } from "@coreui/react";
import { PointsCard } from "./PointsCard";
import { Title } from "./Title";
import BlackBoxImg from "../../assets/marketingAssets/blackbox_new.svg";
import NewWarehouseImg from "../../assets/marketingAssets/new_warehouse.svg";
import NewQueryImg from "../../assets/marketingAssets/new_query.svg";

export const PainPoints = () => {
  return (
    <CContainer className="problems-container" fluid>
      {/* <Title title={"Do these Pain-Points Feel Familiar?"} />
      <CRow className="mt-4">
        <PointsCard
          img={BlackBoxImg}
          title={"Black Box Snowflake costing?"}
          description={"Struggling to make sense of your Snowflake credits?"}
        />
        <PointsCard
          img={NewWarehouseImg}
          title={"Poor Warehouse Utilization?"}
          description={"Unsure if your Warehouse Architecture is the most optimal?"}
        />
        <PointsCard
          img={NewQueryImg}
          title={"Inefficient Queries?"}
          description={"Don’t know how to spot & improve inefficient queries?"}
        />
      </CRow> */}
      <div className="problems-box">
        <div className="imgBx">
          {/* <img src="https://images.unsplash.com/photo-1579748138140-ce9ef2c32db1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80" /> */}
        </div>
        <div className="problems-title">
          <h2>Cost Inefficiencies in Snowflake's Model</h2>
        </div>
        <div className="content">
          <div>
            <p>
              Snowflake's pay-as-you-go model can result in cost inefficiencies
              if not managed effectively.
            </p>
          </div>
        </div>
      </div>
      <div className="problems-box">
        <div className="imgBx">
          {/* <img src="https://images.unsplash.com/photo-1579639782539-15cc6c0be63f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80" /> */}
        </div>
        <div className="problems-title">
          <h2>Poor Resource Utilization and Increased Costs</h2>
        </div>
        <div className="content">
          <div>
            <p>
              Poor resource utilization can lead to increased costs in a
              Snowflake account.
            </p>
          </div>
        </div>
      </div>
      <div className="problems-box">
        <div className="imgBx">
          {/* <img src="https://images.unsplash.com/photo-1603984362497-0a878f607b92?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80" /> */}
        </div>
        <div className="problems-title">
          <h2>Limited Consumption Visibility</h2>
        </div>
        <div className="content">
          <div>
            <p>
              Lack of visibility into consumption trends hinders cost
              optimization in Snowflake.
            </p>
          </div>
        </div>
      </div>
    </CContainer>
  );
};
