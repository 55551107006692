import React from "react";
import { CCard, CCardText, CImage } from "@coreui/react";
import { DocumentTitle } from "../component/DocumentTitle";
import { CodeView } from "../component/CodeView";
import { DocumentationNote } from "../component/DocumentationNote";
import DocumentationNoteInfo from "../component/DocumentationNoteInfo";

function PreInstallationSetupCandour() {
  return (
    <>
      <DocumentTitle title={"Pre-Installation Setup"} />
      <CCard style={{ padding: 10 }}>
        <CCardText>
          This section covers the steps that native app users must execute
          before subscribing to the native application in the Snowflake
          marketplace.{" "}
        </CCardText>
        <CCardText>1) Create a user for Candour™ Native App.</CCardText>
        <DocumentationNote
          text={`Replace <USER_NAME> with the name of the user to be created.`}
        />
        <CodeView
          rows={1}
          codeText={`CREATE USER <USER_NAME> COMMENT='USER FOR CANDOUR NATIVE APP';`}
        />
        Example:
        <CodeView
          eg={true}
          rows={1}
          codeText="CREATE USER CANDOUR_USER COMMENT='USER FOR CANDOUR NATIVE APP';"
        />
        {/* <br /> */}
        {/* <div className="clearfix">
          <CImage src={CostnomicsWH} width={850} />
        </div> */}
        {/* <br /> */}
        <br />
        <CCardText>
          2) Create a dedicated warehouse for Candour Native App.
        </CCardText>
        <DocumentationNote
          text={`Replace <WAREHOUSE_NAME> with the name of the warehouse to be created.`}
        />
        <DocumentationNote
          text={`Users can choose any naming conventions that align with their organization's policies. In the following setup statements, we will be using the name "CANDOUR_WH" as an example. Please replace it with your preferred name in all setup statements to match your naming conventions.`}
        />
        <CodeView
          rows={3}
          codeText={`CREATE WAREHOUSE <WAREHOUSE_NAME> WITH WAREHOUSE_SIZE = 'XSMALL' WAREHOUSE_TYPE = 'STANDARD' AUTO_SUSPEND = 60 AUTO_RESUME = TRUE MIN_CLUSTER_COUNT = 1 MAX_CLUSTER_COUNT = 2 SCALING_POLICY = 'STANDARD' COMMENT = 'Warehouse for Candour Native App';`}
        />
        Example:
        <CodeView
          eg={true}
          rows={3}
          codeText={`CREATE WAREHOUSE CANDOUR_WH WITH WAREHOUSE_SIZE = 'XSMALL' WAREHOUSE_TYPE = 'STANDARD' AUTO_SUSPEND = 60 AUTO_RESUME = TRUE MIN_CLUSTER_COUNT = 1 MAX_CLUSTER_COUNT = 2 SCALING_POLICY = 'STANDARD' COMMENT = 'Warehouse for Candour Native App';`}
        />
        {/* <div className="clearfix">
          <CImage src={UserName} width={850} />
        </div>
        <br /> */}
        <br />
        <CCardText>3) Create a role for Candour Native App.</CCardText>
        <DocumentationNote
          text={`Replace <ROLE_NAME> with the name of the role to be created.`}
        />
        <CodeView
          rows={1}
          codeText={`CREATE ROLE <ROLE_NAME>  COMMENT='ROLE FOR CANDOUR NATIVE APP';`}
        />
        Example:
        <CodeView
          eg={true}
          rows={1}
          codeText={`CREATE ROLE CANDOURAPP_ROLE COMMENT='ROLE FOR CANDOUR NATIVE APP';`}
        />
        {/* <div className="clearfix">
          <CImage src={UserRole} width={850} />
        </div>
        <br /> */}
        <br />
        <CCardText>
          4) Execute the following command to grant usage on the warehouse to
          the role you created in the previous steps.
        </CCardText>
        <DocumentationNote
          text={`Replace <WAREHOUSE_NAME> and <ROLE_NAME> with the name of the warehouse and role created in the above steps.`}
        />
        <CodeView
          rows={1}
          codeText={`GRANT USAGE ON WAREHOUSE <WAREHOUSE _NAME> TO ROLE <ROLE_NAME>;`}
        />
        Example:
        <CodeView
          eg={true}
          rows={1}
          codeText={`GRANT USAGE ON WAREHOUSE CANDOUR_WH TO ROLE CANDOURAPP_ROLE ;`}
        />
        {/* <div className="clearfix">
          <CImage src={GrantUsage} width={850} />
        </div>
        <br /> */}
        <br />
        <CCardText>
          5) Execute the following command to grant a role to the user.
        </CCardText>
        <DocumentationNote
          text={`Replace <ROLE_NAME> and <USER_NAME> with the name of the role and user created in the above steps.`}
        />
        <CodeView
          rows={1}
          codeText={`GRANT ROLE <ROLE_NAME> TO USER <USER_NAME>;`}
        />
        Example:
        <CodeView
          eg={true}
          rows={1}
          codeText={`GRANT ROLE CANDOURAPP_ROLE TO USER CANDOUR_USER;`}
        />
        {/* <div className="clearfix">
          <CImage src={GrantRole} width={850} />
        </div>
        <br /> */}
        <br />
        <DocumentationNoteInfo
          text={`YOU HAVE COMPLETED ALL PRE-INSTALLATION STEPS.`}
        />
      </CCard>
    </>
  );
}
export default PreInstallationSetupCandour;
