import React from "react";
import { CCard, CCardText } from "@coreui/react";

import { DocumentationNote } from "../component/DocumentationNote";
import { CodeView } from "../component/CodeView";
import DocumentationNoteInfo from "../component/DocumentationNoteInfo";
import { DocumentTitle } from "../component/DocumentTitle";

function PostInstallationSetupCandour() {
  return (
    <>
      <DocumentTitle title={"Post-Installation Setup"} />
      <CCard style={{ padding: 10 }}>
        <CCardText className="mb-0">
          This section contains steps that native app users should execute after
          subscribing the native application from Snowflake marketplace.
        </CCardText>
        <br />
        <CCardText>
          1) Execute the following statements for additional grants on the
          created application.
        </CCardText>
        <DocumentationNote
          text={`Replace <APPLICATION_NAME> with the application installed name.
Replace <DATABASE_NAME> with the database that contains your dataset/table.
Replace <SCHEMA_NAME> with the schema in your database.
Replace <REFERENCE_TABLE> with your reference/training table.
Replace <PRODUCTION_TABLE> with your production table.
Replace  <ROLE_NAME> with the name of the role created.`}
        />
        <CodeView
          rows={10}
          codeText={`GRANT APPLICATION ROLE <APPLICATION_NAME>.CANDOUR_ROLE TO ROLE  <ROLE_NAME>;
GRANT USAGE ON DATABASE  <DATABASE_NAME>  TO APPLICATION <APPLICATION_NAME>;
GRANT USAGE ON DATABASE  <DATABASE_NAME>  TO ROLE  <ROLE_NAME>;
GRANT USAGE ON SCHEMA <DATABASE_NAME>.<SCHEMA> TO APPLICATION <APPLICATION_NAME> ;
GRANT USAGE ON SCHEMA <DATABASE_NAME>.<SCHEMA> TO ROLE <ROLE_NAME> ;
GRANT SELECT ON TABLE <DATABASE_NAME>.<SCHEMA>.<REFERENCE_TABLE> TO APPLICATION <APPLICATION_NAME>;
GRANT SELECT ON TABLE <DATABASE_NAME>.<SCHEMA>.<REFERENCE_TABLE> TO ROLE <ROLE_NAME>;
GRANT SELECT ON TABLE <DATABASE_NAME>.<SCHEMA>.<PRODUCTION_TABLE> TO APPLICATION <APPLICATION_NAME>;
GRANT SELECT ON TABLE <DATABASE_NAME>.<SCHEMA>.<PRODUCTION_TABLE> TO ROLE <ROLE_NAME>;
`}
        />

        <br />
        <DocumentationNoteInfo
          text={`YOU HAVE COMPLETED ALL POST-INSTALLATION STEPS. NOW, RUN FIRST-TIME DATA POPULATION STEPS AS PER THE NEXT SECTION`}
        />
      </CCard>
    </>
  );
}

export default PostInstallationSetupCandour;
