import { CNavItem} from "@coreui/react";
import { Link } from "react-scroll";

export const NavItem = (props) => {
  return (
    <Link
      activeClass={"custom-navlink-active"}
      to={props.id}
      spy={true}
      smooth={true}
      hashSpy={true}
      duration={400}
      offset={-30}
      ignoreCancelEvents={false}
      className="d-flex justify-content-center align-items-center react-scroll-link cursor-pointer px-2 "
    >
      <CNavItem className=" custom-navlink p-0 m-0">{props.title}</CNavItem>
    </Link>
  );
};
