import axios from "axios";
const config = require("../config/config");
const API_URL = config.api_url;


class SignUpService {
  signUpUser(
    name,
    description,
    owner,
    mobile_number,
    email,
    address,
    password,
    requestType,
    uuid,
    type
  ) {
    // console.log("API url:",API_URL)

    try {
      var data = JSON.stringify({
        request_type: requestType,
        service_name: name,
        description: description,
        owner: owner,
        mobile_number:mobile_number,
        email: email,
        address: address,
        password: password,
        parent_uuid: uuid,
        auth_type: type,
      });

      var config = {
        method: "post",
        url: API_URL + "/v1/postgres/signup_details",
        headers: {
          
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("API config:",config)

      return axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

export default new SignUpService();
