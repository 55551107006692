import { useState } from "react";


export const UseUserDetails = () => {

    const getUserDetails = () => {
        const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
        return userDetails;
    }

    const [userDetails, setUserDetails] = useState(getUserDetails());

        const saveUserDetails = (parent_uuid,email, request_type, auth_type,owner,domain_name) => {
        // const userDetails={email,request_type}
        sessionStorage.setItem("userDetails", JSON.stringify({parent_uuid, email, request_type, auth_type,owner,domain_name }));
        setUserDetails({parent_uuid, email, request_type, auth_type,owner,domain_name});
    };
    return { setUserDetails: saveUserDetails, userDetails };

}