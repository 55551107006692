import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";
function BudgetManagement() {
  return (
    <>
      <CCardText className="document-text" id="anomaly-detection">
        {" "}
        Budget Management
      </CCardText>
      <CCardText style={{ paddingLeft: 10 }}>
        The budget management tool enables you to set and manage your budget for
        specific durations. You can set the reset period for your budget and the
        scope that you would like to apply your budget to. It allows you to set
        and receive alerts, that helps you stay within your budget and plan your
        future usage on that basis. The budget management tool also suggests a
        monthly budget for you based on the forecasted usage. It gives you a
        preview of your monthly cost data as actual versus forecasted usage.
      </CCardText>
      {/* TO ADD DROPDOWN HERE */}
      <CCard style={{ padding: 10 }}>
        <CCardText>
          <b>1) BUDGETS</b> - The ‘Budgets’ table, stores the information of all
          created budgets, which helps you to manage your cost. <br />
          <b>2) BUDGET_HISTORY </b>- The budget history table reads data from
          the ‘Budgets’ table, from the time the budget was created and computes
          your current usage. <br /> <br />
          {/* <p>
            &emsp;i) BUDGET_ID - The budget id is a unique identifier, which is
            auto incremented for the table.
            <br />
            &emsp;ii) BUDGET_NAME - A budget name is an identifier for the
            budget and it must be unique.
            <br />
            &emsp;iii) BUDGET_AMT - The budget amount is the total allocated
            amount for a specified budget.
            <br />
            &emsp;iv) RESET_PERIOD - For the reset period define a set duration
            for the budget. It can be either in months, quarters, or years.
            <br />
            &emsp;v) RESET_PERIOD_DURATION - This is the number of
            months/quarters/years that you have set the budget for.
            <br />
            &emsp;vi) BUDGET_SCOPE - The budget you create will be assigned to
            the selected scope. The scope can be set to the Account, Warehouse,
            User Name, User Role, Partner Tools, KPI or Storage.
            <br />
            &emsp;vii) BUDGET_SUB_SCOPE - The budget sub-scope defines the
            object name of the scope type. Example : If the budget scope is a
            warehouse, then the budget sub scope must be a valid warehouse name.
            <br />
            &emsp;viii) EMAIL_ID - The Email addresses that are provided are
            used to notify the user about daily budget usage.
            <br />
            &emsp;The alerts set by the user to keep a track on the set budget
            are in percentage(%)
            <br />
            &emsp;ix) AL_YELLOW- The first alert must be greater than zero.
            <br />
            &emsp;x) AL_ORANGE- The second alert must be greater than the first
            alert.
            <br />
            &emsp;xi) AL_RED- The third alert must be greater than the above two
            alerts.
          </p> */}
          <b>Query to get the budget list :</b> <br />
          <CCardText className="costnomics-doc-text">
            SELECT BG.BUDGET_ID,BG.BUDGET_NAME,BG.BUDGET_SCOPE,
            <br />
            <CCardText className="mb-0" style={{ paddingLeft: "20px" }}>
              BG.BUDGET_SUB_SCOPE,BG.RESET_PERIOD,
              <br />
              BG.RESET_PERIOD_DURATION,BG.BUDGET_AMT,
              <br />
              BH.ACTUAL_AMOUNT,ROUND(BH.VALUE_IN_PERCENT,2) PERCENT_AMOUNT_USED,
              <br />
              BH.ALERT_STATUS,BG.IS_ACTTIVE,
              <br />
              BG.CREATED_ON FROM APP.BUDGET_HISTORY BH JOIN APP.BUDGETS BG ON
              BG.BUDGET_ID=BH.BUDGET_ID WHERE BH.CREATED_ON = (SELECT
              MAX(CREATED_ON) FROM APP.BUDGET_HISTORY WHERE BUDGET_ID =
              BG.BUDGET_ID)
            </CCardText>
            UNION
            <br />
            SELECT BG.BUDGET_ID,BG.BUDGET_NAME,BG.BUDGET_SCOPE,
            <CCardText className="mb-0" style={{ paddingLeft: "20px" }}>
              BG.BUDGET_SUB_SCOPE,BG.RESET_PERIOD,
              <br />
              BG.RESET_PERIOD_DURATION,BG.BUDGET_AMT,
              <br />
              0 ACTUAL_AMOUNT,0 VALUE_IN_PERCENT,'GREEN' ALERT_STATUS,
              <br />
              BG.IS_ACTTIVE,
              <br />
              BG.CREATED_ON FROM APP.BUDGETS BG WHERE BG.CREATED_ON IN (SELECT
              CREATED_ON FROM APP.BUDGETS WHERE BUDGET_ID IN (SELECT BUDGET_ID
              FROM APP.BUDGETS MINUS SELECT DISTINCT BUDGET_ID FROM
              APP.BUDGET_HISTORY) ORDER BY BG.CREATED_ON);
            </CCardText>
            <br />
          </CCardText>
        </CCardText>

        <CCardText>
          <b>3) BUDGET_FORECAST </b> - The budget_forecast table displays the
          forecasted dollars used for the budget.
          <br />
          <br />
          <b> Query to get the budget forecast: </b>
          <br />
          <br />
          <CCardText className="costnomics-doc-text">
            //Replace 'account budget' with your budget_name. <br /> SELECT *
            FROM APP.BUDGET_FORECAST WHERE BUDGET_NAME='account budget';
          </CCardText>
        </CCardText>

        <CCardText>
          <b>4) BUDGET_ANOMALY_REPORT </b> - The budget_anomaly_report displays
          the anomalous values on the actual budget amount. The description is
          as follows:
          <br />
          <br />
          <b> Anomaly report description : </b>
          <br />
          <br />
          <CTable bordered dark small responsive style={{ maxWidth: "35%" }}>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>Parameter</CTableHeaderCell>
                <CTableHeaderCell>Description</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow>
                <CTableDataCell>Value</CTableDataCell>
                <CTableDataCell>
                  The actual budget amount that are detected as anomaly
                </CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>Date</CTableDataCell>
                <CTableDataCell>
                  The list of dates on which the anomalies are detected
                </CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableDataCell>Score</CTableDataCell>

                <CTableDataCell>
                  The score for the detected anomalies
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
          <b> Query to get the anomalous report:</b>
          <br />
          <br />
          <CCardText className="costnomics-doc-text">
          //Replace 'account budget' with your budget_name. <br /> SELECT
            DISTINCT S.VALUE AS ANOMALY_VALUE ,
            TO_CHAR(BUDGET_HISTORY.CREATED_ON,'YYYY-MM-DD HH24:MI:SS') AS
            DATECOL FROM APP.BUDGET_ANOMALY_REPORT JOIN APP.BUDGET_HISTORY ON
            APP.BUDGET_ANOMALY_REPORT.BUDGET_ID = BUDGET_HISTORY.BUDGET_ID JOIN
            TABLE(BUDGET_ANOMALY_REPORT.FLATTEN(BUDGET_ANOMALY_REPORT.ANOMALY_RESULT:"anomaly_report":"Value"))
            S ON S.VALUE = BUDGET_HISTORY.ACTUAL_AMOUNT WHERE
            BUDGET_ANOMALY_REPORT.BUDGET_NAME ='account budget' AND
            BUDGET_HISTORY.CREATED_ON{" >"} DATEADD(DAY, -30, CURRENT_DATE());
          </CCardText>
        </CCardText>

        <CCardText className="document-text" id="anomaly-detection">
          {" "}
          Budget Planner
        </CCardText>
        <CCardText style={{ paddingLeft: 10 }}>
          The budget planner is a useful tool for anyone who wishes to plan and
          manage their snowflake usage. Enabling you to foresee your weekly,
          monthly and yearly cost on snowflake.On the budget planner page you
          can calculate the base cost by simply selecting the snowflake edition,
          the cloud providers and the region that you are currently using. You
          can calculate the Compute cost, by selecting the warehouse size and
          inputting your usage details. You can also calculate serverless
          features services cost by selecting the feature, usage per day, usage
          minutes per day and usage days per week, this will give your credit
          used per week and your cost per week.You can also check your data
          transfer cost for; The same cloud provider and same region, Same cloud
          provider and different region, and Different cloud providers and the
          internet. You can also calculate your storage cost per week by
          inputting your daily storage in GB and usage days per week.
          Furthermore you can calculate summary cost for weekly, monthly and
          quarterly usage. By calculating cost for different service types, you
          can also see the total incurred cost.
          <br />
          <br />
          <b>
            <u>
              The Budget Management Tool and the Budget Planner is a suite of
              tools provided by CostNomics that will help you to analyze,
              manage, and optimize the costs of your workloads drastically.
              Using this suite helps ensure that your organization is taking
              full advantage of the benefits provided by CostNomics.
            </u>
          </b>
        </CCardText>
      </CCard>
    </>
  );
}

export default BudgetManagement;
