import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userUuid : null,
}

export const userDetailsSlice = createSlice({
  name: 'userDetailsSlice',
  initialState,
  reducers: {
    updateUserUuid: (state,action) => {
        state.userUuid= action.payload
      },
  },
})

// Action creators are generated for each case reducer function
export const { 
  updateUserUuid, 
  
 } = userDetailsSlice.actions

export default userDetailsSlice.reducer