import React from "react";
import { CCard, CCardText, CHeaderDivider, CImage } from "@coreui/react";
import { DocumentTitle } from "../component/DocumentTitle";
import { CodeView } from "../component/CodeView";
import { DocumentationNote } from "../component/DocumentationNote";
import streamlit2 from "../../../assets/CandourAssets/candour_streamlit2.png";
import streamlit3 from "../../../assets/HawkeyeAssets/hw_streamlit.png";
import ds_hw_2 from "../../../assets/HawkeyeAssets/ds_hw_2.png";
import ds_hw_3 from "../../../assets/HawkeyeAssets/ds_hw_3.png";
import ds_hw_4 from "../../../assets/HawkeyeAssets/ds_hw_4.png";
import ds_hw_5 from "../../../assets/HawkeyeAssets/ds_hw_5.png";
import ds_hw_6 from "../../../assets/HawkeyeAssets/ds_hw_6.png";
import ds_hw_7 from "../../../assets/HawkeyeAssets/ds_hw_7.png";
import ds_hw_8_1 from "../../../assets/HawkeyeAssets/ds_hw_8_1.jpg";
import ds_hw_8 from "../../../assets/HawkeyeAssets/ds_hw_8.png";
import ds_hw_9 from "../../../assets/HawkeyeAssets/ds_hw_9.png";
import ds_hw_10 from "../../../assets/HawkeyeAssets/ds_hw_10.png";
import ds_hw_12 from "../../../assets/HawkeyeAssets/ds_hw_12.png";
import ds_hw_1_1 from "../../../assets/HawkeyeAssets/hw_data_quality.png";
import ds_hw_5_1 from "../../../assets/HawkeyeAssets/hw_table_profiling.png";
import ds_hw_11_1 from "../../../assets/HawkeyeAssets/hw_cross_column.png";


function StreamlitAppHawkeye() {
  return (
    <>
      <DocumentTitle title={"Streamlit App"} />
      <CCard style={{ padding: 10 }}>
        <CCardText>
          1) Log into Snowsight, the Snowflake web interface.
        </CCardText>
        <br />

        <CCardText>
          2) Click the dropdown menu next to your login name, then click Switch
          Role » ACCOUNTADMIN.
        </CCardText>
        <div className="clearfix">
          <CImage src={streamlit2} width={850} />
        </div>
        <br />
        <CCardText>
          3) Click on “Apps”, select the created application and then select the
          warehouse created for the application.
        </CCardText>
        <div className="clearfix">
          <CImage src={streamlit3} width={850} />
        </div>
        <br />
        <CCardText>4) Hawkeye Dashboards</CCardText>
        <div className="clearfix">
          <CImage src={ds_hw_1_1} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={ds_hw_2} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={ds_hw_3} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={ds_hw_4} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={ds_hw_5_1} width={850} />
        </div>
        <br />

        <div className="clearfix">
          <CImage src={ds_hw_6} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={ds_hw_7} width={850} />
        </div>
        <br />
        <DocumentationNote
          text={"Please ensure to specify COLUMN NAME in UPPER CASE"}
        />
        <div className="clearfix">
          <CImage src={ds_hw_8_1} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={ds_hw_8} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={ds_hw_9} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={ds_hw_10} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={ds_hw_11_1} width={850} />
        </div>
        <br />
        <div className="clearfix">
          <CImage src={ds_hw_12} width={850} />
        </div>
        <br />
      </CCard>
    </>
  );
}
export default StreamlitAppHawkeye;
