import React from "react";
import { CCard, CCardText, CImage } from "@coreui/react";
import { DocumentTitle } from "../component/DocumentTitle";
import { CodeView } from "../component/CodeView";
import { DocumentationNote } from "../component/DocumentationNote";
import DocumentationNoteInfo from "../component/DocumentationNoteInfo";

function PreInstallationSetupHawkeye() {
  return (
    <>
      <DocumentTitle title={"Pre-Installation Setup"} />
      <CCard style={{ padding: 10 }}>
        <CCardText>
          This section contains steps that native app users should execute
          before subscribing to the native application from Snowflake
          marketplace.{" "}
        </CCardText>
        <CCardText>
          1) Create a dedicated warehouse for the HawkEye App.
        </CCardText>
        <DocumentationNote
          text={`Users can choose any naming conventions that match their organization's policies. But make sure the same is used in other statements as well. For representation, we will use the name "HAWKEYE_WH". Users can always replace it with their preferred name in all setup statements.
          `}
        />
        <CodeView
          rows={2}
          codeText={`CREATE WAREHOUSE <WAREHOUSE_NAME> WITH WAREHOUSE_SIZE = 'XSMALL' WAREHOUSE_TYPE = 'STANDARD' AUTO_SUSPEND = 60 AUTO_RESUME = TRUE MIN_CLUSTER_COUNT = 1 MAX_CLUSTER_COUNT = 2 SCALING_POLICY = 'STANDARD' COMMENT = 'Warehouse for HawkEye Native App';`}
        />
        Example:
        <CodeView
          eg={true}
          rows={2}
          codeText="CREATE WAREHOUSE HAWKEYE_WH WITH WAREHOUSE_SIZE = 'XSMALL' WAREHOUSE_TYPE = 'STANDARD' AUTO_SUSPEND = 60 AUTO_RESUME = TRUE MIN_CLUSTER_COUNT = 1 MAX_CLUSTER_COUNT = 2 SCALING_POLICY = 'STANDARD' COMMENT = 'Warehouse for Hawkeye Native App';"
        />
        {/* <br /> */}
        {/* <div className="clearfix">
          <CImage src={CostnomicsWH} width={850} />
        </div> */}
        {/* <br /> */}
        <br />
        <CCardText>2) Create a user for the HawkEye Native App.</CCardText>
        <DocumentationNote
          text={`Note: Replace <USER_NAME>  with the name of the user to be created.`}
        />
        <CodeView
          rows={1}
          codeText={`CREATE USER <USER_NAME> COMMENT='USER FOR HAWKEYE NATIVE APP';`}
        />
        Example:
        <CodeView
          eg={true}
          rows={1}
          codeText={`CREATE USER HAWKEYE_USER COMMENT='USER FOR HAWKEYE NATIVE APP';`}
        />
        {/* <div className="clearfix">
          <CImage src={UserName} width={850} />
        </div>
        <br /> */}
        <br />
        <CCardText>3) Create a role for the HawkEye Native App..</CCardText>
        <DocumentationNote
          text={`Replace <ROLE_NAME> with the name of the role to be created.`}
        />
        <CodeView
          rows={1}
          codeText={`CREATE ROLE <ROLE_NAME> COMMENT='ROLE FOR HAWKEYE NATIVE APP'; `}
        />
        Example:
        <CodeView
          eg={true}
          rows={1}
          codeText={`CREATE ROLE HAWKEYEAPP_ROLE COMMENT='ROLE FOR HAWKEYE NATIVE APP';`}
        />
        {/* <div className="clearfix">
          <CImage src={UserRole} width={850} />
        </div>
        <br /> */}
        <br />
        <CCardText>
          4) Execute the following command to grant usage on the warehouse to
          the role you created in the previous steps.
        </CCardText>
        <DocumentationNote
          text={`Note: Replace <ROLE_NAME> With Name of The Role To Be Created.`}
        />
        <CodeView
          rows={1}
          codeText={`GRANT USAGE ON WAREHOUSE <WAREHOUSE _NAME> TO ROLE <ROLE_NAME>;`}
        />
        Example:
        <CodeView
          eg={true}
          rows={1}
          codeText={`GRANT USAGE ON WAREHOUSE HAWKEYE_WH TO ROLE HAWKEYEAPP_ROLE;`}
        />
        {/* <div className="clearfix">
          <CImage src={GrantUsage} width={850} />
        </div>
        <br /> */}
        <br />
        <CCardText>
          5) Execute the following command to grant a role to the user.
        </CCardText>
        <DocumentationNote
          text={`Note: Replace <ROLE_NAME> and <USER_NAME> with the name of the role and user created in the above steps.`}
        />
        <CodeView
          rows={1}
          codeText={`GRANT ROLE <ROLE_NAME> TO USER <USER_NAME>;`}
        />
        Example:
        <CodeView
          eg={true}
          rows={1}
          codeText={`GRANT ROLE HAWKEYEAPP_ROLE TO USER HAWKEYE_USER;`}
        />
        {/* <div className="clearfix">
          <CImage src={GrantRole} width={850} />
        </div>
        <br /> */}
        <br />
        {/* <br />
        <CCardText>
          6) Create database and schema using following commands for sample
          dataset setup.
        </CCardText>
        <CodeView
          rows={1}
          codeText={`CREATE DATABASE IF NOT EXISTS HAWKEYE_DB;`}
        />
        <CodeView
          rows={1}
          codeText={`CREATE SCHEMA IF NOT EXISTS HAWKEYE_DB.SAMPLE_SCHEMA;`}
        />
        <CodeView
          rows={1}
          codeText={`CREATE OR REPLACE TABLE HAWKEYE_DB.SAMPLE_SCHEMA.ITEM AS SELECT * FROM SNOWFLAKE_SAMPLE_DATA.TPCDS_SF100TCL.ITEM;`}
        />
        <br /> */}
        <DocumentationNoteInfo
          text={`YOU HAVE COMPLETED ALL PRE-INSTALLATION STEPS.`}
        />
      </CCard>
    </>
  );
}
export default PreInstallationSetupHawkeye;
