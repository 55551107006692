import { cilHome } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CContainer } from "@coreui/react";
import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="guide-navbar ">
      DataGeir HawkEye{" "}
      <Link className="icon-twitter" to={"/"}>
        <CIcon style={{ marginRight: "30px" }} size="lg" icon={cilHome}></CIcon>
      </Link>
    </div>
  );
};

export default Navbar;
