import axios from "axios";
const config = require("../config/config");
const API_URL = config.api_url;




class RegisterService {
  registerUser(email, password, idToken, groupname) {
    // console.log("API url in cognito:", API_URL)
    // console.log("group name is", groupname)
    try {
      // console.log("inside try")
      var data = JSON.stringify({
        email: email,
        password: password,
        groupname: groupname,
      });

      var config = {
        method: "post",
        url: API_URL + "/v1/auth/register",
        headers: {
          
          "Content-Type": "application/json",
          authorization: idToken,
        },
        data: data,
      };
      // console.log("API config in cognito:", config)

      return axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  registerEntUser(email, password, idToken, groupname) {
    // console.log("API url in cognito:", API_URL)
    // console.log("group name is", groupname)
    try {
      // console.log("inside try")
      var data = JSON.stringify({
        email: email,
        password: password,
        groupname: groupname,
      });

      var config = {
        method: "post",
        url: API_URL + "/v1/auth/entregister",
        headers: {
          "Content-Type": "application/json",
          authorization: idToken,
        },
        data: data,
      };
      // console.log("API config in cognito:", config)

      return axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

export default new RegisterService();
