import React from "react";
import { CAlert, CCard, CCardText, CCol, CImage } from "@coreui/react";
import { DocumentTitle } from "../component/DocumentTitle";
import { CodeView } from "../component/CodeView";
import { DocumentationNote } from "../component/DocumentationNote";
import DocumentationNoteInfo from "../component/DocumentationNoteInfo";

function SampleDatasetExampleHawkeye() {
  return (
    <>
      <DocumentTitle title={"Sample Dataset Example"} />
      <CCard style={{ padding: 10 }}>
        <CCardText>
          Follow the next steps to run the job on the sample dataset.
        </CCardText>
        <CodeView rows={1} codeText={`USE DATABASE <APPLICATION_NAME>;`} />
        <br />
        <CCol style={{ maxWidth: "900px" }}>
          <CAlert color="warning" className="d-flex gap-1">
            <span style={{ fontWeight: 700 }}>Note: </span>{" "}
            <div style={{ whiteSpace: "pre-line" }}>
              Sample data sets are provided in a database named
              SNOWFLAKE_SAMPLE_DATA that has been shared with your account from
              the Snowflake SFC_SAMPLES account. If you do not see the database,
              you can create it yourself. Refer to Using the Sample Database.{" "}
              <a
                href="https://docs.snowflake.com/en/user-guide/sample-data-using"
                targe="_blank"
              >
                {" "}
                Using the Sample Database.
              </a>
            </div>
          </CAlert>
        </CCol>
        <CCardText>
          <b>Step 1:</b> Create database and schema using following commands for
          sample dataset setup.
        </CCardText>
        <CodeView
          rows={1}
          codeText={`CREATE DATABASE IF NOT EXISTS HAWKEYE_DB;`}
        />
        <CodeView
          rows={1}
          codeText={`CREATE SCHEMA IF NOT EXISTS HAWKEYE_DB.SAMPLE_SCHEMA;`}
        />
        <CodeView
          rows={2}
          codeText={`CREATE OR REPLACE TABLE HAWKEYE_DB.SAMPLE_SCHEMA.ITEM AS SELECT * FROM SNOWFLAKE_SAMPLE_DATA.TPCDS_SF100TCL.ITEM;`}
        />
        <br />
        <CCardText>
          <b>Step 2: </b>Execute following grant statements on sample dataset.
        </CCardText>
        <CodeView
          rows={6}
          codeText={`GRANT USAGE ON DATABASE HAWKEYE_DB TO APPLICATION <APPLICATION_NAME>;
GRANT USAGE ON SCHEMA HAWKEYE_DB.SAMPLE_SCHEMA TO APPLICATION  <APPLICATION_NAME>;
GRANT SELECT ON TABLE HAWKEYE_DB.SAMPLE_SCHEMA.ITEM TO APPLICATION  <APPLICATION_NAME>;
GRANT USAGE ON DATABASE HAWKEYE_DB TO ROLE <ROLE_NAME>;
GRANT USAGE ON SCHEMA HAWKEYE_DB.SAMPLE_SCHEMA TO ROLE <ROLE_NAME>;
GRANT SELECT ON TABLE HAWKEYE_DB.SAMPLE_SCHEMA.ITEM TO ROLE <ROLE_NAME>;`}
        />
        <br />
        <CCardText>
          <b>Step 3: </b>
        </CCardText>
        <CodeView
          rows={10}
          codeText={`INSERT INTO APP.CONFIG_PROPERTY (DB_NAME,SCHEMA_NAME,TABLE_NAME,CONFIGURATION,IS_DELETED) SELECT 'HAWKEYE_DB','SAMPLE_SCHEMA','ITEM', PARSE_JSON('{"DATE_COLUMN_NAME":"NULL","FROM_DATE":"NULL","COLUMN_LIST":"*","AVERAGE_RECORD_SIZE":"True","PEARSON_MATRIX":"TRUE","TOTAL_MEMORY_SIZE":"TRUE",       "CHI_SQUARE_MATRIX":"TRUE","COEFFICIENT_OF_VARIATION":"TRUE","TOP_FIVE":"TRUE","LOWEST_FIVE":"TRUE","MEAN_ABSOLUTE_DEVIATION":"TRUE",           "MEDIAN_ABSOLUTE_DEVIATION":"TRUE","VALUE_COUNT_CATEGORICAL":"TRUE","UNIQUE_VALUES_PROPORTION_TO_BE_GREATER_THAN":"50",           "TEXT_VALUES_PROPORTION_TO_BE_GREATER_THAN":"50","DATA_LABELING_THRESHOLD":"400","PROFILE_ENTRIES_COUNT":"30","ANOMALY_COLUMN_LIST":"I_ITEM_SK,I_ITEM_ID,I_REC_START_DATE,I_REC_END_DATE,I_ITEM_DESC"}'),false;`}
        />
        <CCardText>
          <br />
          <b>Step 4: </b>
        </CCardText>
        <CodeView
          rows={1}
          codeText={`CALL CODE.HAWKEYE_PERFORMANCE('HAWKEYE_DB','SAMPLE_SCHEMA','ITEM');`}
        />
        <CCardText>
          <br />
          <b>Step 5: </b>
        </CCardText>
        <CodeView
          rows={4}
          codeText={`insert into app.config_validation(db_name, schema_name,TABLE_NAME,column_name,completness_config,validity_config,consistency_config,accuracy_config,uniqueness_config,timeliness_config) select 'HAWKEYE_DB','SAMPLE_SCHEMA','ITEM','I_ITEM_DESC',PARSE_JSON('{
            "expect_column_value_lengths_to_be_between": {
              "status": "TRUE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_kurtosis_to_be_between": {
              "status": "TRUE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_kurtosis_to_equal": {
              "status": "TRUE",
              "value": "1"
            },
            "expect_column_values_to_be_null": {
              "status": "TRUE"
            },
            "expect_column_values_not_to_be_null": {
              "status": "TRUE"
            },
            "expect_column_values_to_be_of_type": {
              "status": "TRUE",
          "data_type": "STRING"
            }
          } '),PARSE_JSON('{
            "expect_column_values_to_match_date_format": {
              "status": "TRUE",
              "date_pattern": "YYYY-MM-DD"
            },  
            "expect_column_to_be_categorical": {
              "status": "TRUE"
            },
            "expect_string_column_to_be_text": {
              "status": "TRUE"
            },  
            "expect_column_distinct_values_to_be_in_set": {
              "status": "TRUE",
              "distinct_value_set": ["accent","accessories","archery"]
            },
            "expect_column_distinct_values_to_equal_set": {
              "status": "TRUE",
              "distinct_value_set": ["accent","accessories","archery"]
            },
            "expect_table_columns_to_match_set": {
              "status": "TRUE",
              "column_set": ["I_CONTAINER","I_ITEM_DESC","I_ITEM_SK","I_CATEGORY","I_COLOR","I_FORMULATION","I_SIZE","I_BRAND_ID","I_MANAGER_ID","I_MANUFACT","I_MANUFACT_ID","I_CATEGORY_ID","I_REC_START_DATE","I_CLASS_ID","I_ITEM_ID","I_UNITS","I_WHOLESALE_COST","I_BRAND","I_CLASS","I_PRODUCT_NAME","I_CURRENT_PRICE","I_REC_END_DATE"]
            },
            "expect_table_columns_to_match_ordered_list": {
              "status": "TRUE",
              "column_set": ["I_CONTAINER","I_ITEM_DESC","I_ITEM_SK","I_CATEGORY","I_COLOR","I_FORMULATION","I_SIZE","I_BRAND_ID","I_MANAGER_ID","I_MANUFACT","I_MANUFACT_ID","I_CATEGORY_ID","I_REC_START_DATE","I_CLASS_ID","I_ITEM_ID","I_UNITS","I_WHOLESALE_COST","I_BRAND","I_CLASS","I_PRODUCT_NAME","I_CURRENT_PRICE","I_REC_END_DATE"]
            }
          }  '),PARSE_JSON('{
            "expect_table_column_count_to_be_between": {
              "status": "TRUE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_table_column_count_to_equal": {
              "status": "TRUE",
              "value": "1"
            },
            "expect_table_row_count_to_be_between": {
              "status": "TRUE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_table_row_count_to_equal": {
              "status": "TRUE",
              "value": "1"
            },
            "expect_table_duplicate_row_to_equal": {
              "status": "FALSE",
              "value": "1"
            },
            "expect_table_duplicate_row_to_be_between": {
              "status": "TRUE",
              "min_value": "1",
              "max_value": "10"
            }
          } '),PARSE_JSON('{
            "expect_column_max_to_be_between": {
              "status": "TRUE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_min_to_be_between": {
              "status": "FALSE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_mean_to_be_between": {
              "status": "TRUE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_median_absolute_deviation_to_be_between": {
              "status": "TRUE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_sum_to_be_between": {
              "status": "FALSE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_quantile_values_to_be_between": {
              "status": "TRUE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_stdev_to_be_between": {
              "status": "TRUE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_not_negative_to_be_between": {
              "status": "FALSE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_not_negative_to_equal": {
              "status": "TRUE",
              "value": "1"
            },
            "expect_column_skewness_to_be_between": {
              "status": "TRUE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_skewness_equal": {
              "status": "TRUE",
              "value": "1"
            }
          }'),PARSE_JSON('{
            "expect_column_proportion_of_unique_values_to_be_between": {
              "status": "FALSE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_unique_value_count_to_be_between": {
              "status": "TRUE",
              "min_value": "1",
              "max_value": "10"
            },
            "expect_column_values_to_be_unique": {
              "status": "TRUE"
            },
            "expect_column_values_not_to_be_unique": {
              "status": "TRUE"
            },
            "expect_column_unique_count_to_be_greater_than": {
              "status": "TRUE",
              "value": "1"
            },
            "expect_column_unique_count_to_be_less_than": {
              "status": "TRUE",
              "value": "1"
            },
            "expect_column_unique_count_to_equal": {
              "status": "TRUE",
              "value": "1"
            }
          }'),PARSE_JSON('{
            "expect_timeliness": {
              "days": "7"    
            }
          }');
          `}
        />
        <br />
        <CCardText>
          <b>Step 6: </b>
        </CCardText>
        <CodeView
          rows={1}
          codeText={`CALL CODE.HAWKEYE_VALIDATION_REPORT('HAWKEYE_DB','SAMPLE_SCHEMA','ITEM','I_ITEM_DESC');`}
        />
        <br />
        <CCardText>
          <b>Step 7: </b>
        </CCardText>
        <DocumentationNote
          text={`Please ensure to have 30 entries in the profile report table to execute the following command. \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0`}
        />
        <CodeView
          rows={1}
          codeText={`CALL CODE.HAWKEYE_ANOMALY('HAWKEYE_DB','SAMPLE_SCHEMA','ITEM');`}
        />
        <br />
        <DocumentationNoteInfo text="FOLLOW THE STREAMLIT APP SECTION TO VIEW THE DASHBOARDS" />
      </CCard>
    </>
  );
}
export default SampleDatasetExampleHawkeye;
