import {
  CButton,
  CCardText,
  CCol,
  CContainer,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CImage,
  CRow,
  CSpinner,
} from "@coreui/react";
import { useState } from "react";
import CIcon from "@coreui/icons-react";
import { cibLinkedin, cibTwitter } from "@coreui/icons";
import atgeirLogo from "../../assets/marketingAssets/Atgeir_Logo_Light.svg";
import { useNavigate } from "react-router-dom";
import { animateScroll } from "react-scroll";
import axios from "axios";

export const ContactUs = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = (event) => {
    setLoading(true);
    props.setToastVisible(false);
    props.setErrorToastVisible(false);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      const body = `{\n  "data": [\n    [\n      0,\n      "contact_us",\n      "costnomics_support@atgeirsolutions.com",\n      \"{\\n\\\"USER_NAME\\\": \\\"${name} \\\",\\\"USER_EMAIL\\\": \\\"${email}\\\",\\\"USER_MESSAGE\\\": \\\"${message}\\\"\\n}\"\n    ]\n  ]}`;

      let postData = JSON.stringify({
        body,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://8c5hijp35g.execute-api.us-east-2.amazonaws.com/prod",
        headers: {
          "x-api-key": "AMyRNTBy1l1oVeSBkxct52dalIp3W83J1olwiio5",
          "content-type": "application/json",
        },
        data: postData,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data.statusCode == 200) {
            setLoading(false);
            props.setToastVisible(true);
          } else {
            props.setErrorToastVisible(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
    setValidated(true);
  };

  return (
    <div className="w-100 pb-5" style={{ backgroundColor: "#01245e" }}>
      <CContainer className="pt-5" fluid>
        <CRow className="pt-4">
          <CCol sm={12} md={6} className="ps-5">
            <CImage
              src={atgeirLogo}
              width={"200px"}
              onClick={() => animateScroll.scrollToTop()}
            />
            <div
              className="mt-2 font-weight-600 font-md text-white text-justify "
              style={{ fontSize: "14px" }}
            >
              Agility & Innovation, Forming The
              <br /> Cornerstones Of What We Do
            </div>
            <CRow className="d-flex justify-content-start gap-1 align-items-start pt-5 m-0">
              <CCol sm={12} md={5} className="m-0 p-0">
                <div
                  className=" mt-3 text-white"
                  style={{ fontSize: "20px", fontWeight: "600" }}
                >
                  Connect with us:{" "}
                </div>
                <div className="font-lg mt-3" style={{ color: "#D3D3D3" }}>
                  For support or any questions <br />
                  Email us at{" "}
                  <a
                    href="mailto:info@atgeirsolutions.com"
                    style={{ color: "#ebedef" }}
                  >
                    info@atgeirsolutions.com
                  </a>
                </div>
                <div
                  className="font-lg mt-3 d-flex gap-3 "
                  style={{ color: "#D3D3D3" }}
                >
                  <a
                    href="https://www.linkedin.com/company/atgeir-solutions/"
                    target="_blank"
                  >
                    <CIcon
                      icon={cibLinkedin}
                      className="hover icon-linkedin icon-label"
                      size="lg"
                    />
                  </a>
                  <a href="https://twitter.com/AtgeirL" target="_blank">
                    <CIcon
                      icon={cibTwitter}
                      className="hover icon-twitter"
                      size="lg"
                    />
                  </a>
                </div>
              </CCol>
              <CCol sm={12} md={6} className="m-0 p-0">
                <div
                  style={{ fontSize: "20px", fontWeight: "600" }}
                  className=" mt-3 text-white"
                >
                  Address:{" "}
                </div>
                <div className="font-lg mt-2 " style={{ color: "#D3D3D3" }}>
                  <div className="font-weight-600">Global Delivery Center</div>
                  <div className="w-75" >
                    Office 501, Orville business port, KONARK CAMPUS, West Ave,
                    Konark Nagar, Mhada Colony, Viman Nagar, Pune, Maharashtra
                    411014
                  </div>
                  <div className="mt-2 ">Phone: +91 020-41292883</div>
                </div>
              </CCol>
            </CRow>
          </CCol>

          <CCol
            style={{ paddingBottom: "60px" }}
            sm={12}
            md={5}
            className="align-items-start mt-5 mt-md-0"
          >
            <div
              className={"text-align-center  text-white"}
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              Contact Us
            </div>
            <div
              className="font-lg text-align-center mt-2 px-5"
              style={{ color: "#D3D3D3" }}
            >
              Lets Get this conversation started. Tell us a bit about yourself,
              and we'll get in touch as soon as we can
            </div>
            <CForm
              onSubmit={handleSubmit}
              style={{ textAlign: "left", width: "75%" }}
              noValidate
              className="px-2 m-auto needs-validation"
              validated={validated}
            >
              <CRow>
                <CFormLabel
                  htmlFor="name"
                  className="mt-3 mb-1 label-required text-white"
                >
                  Name
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="name"
                  placeholder="Enter your name"
                  feedbackInvalid="Please Enter Valid Name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  required
                />
                <CFormLabel
                  htmlFor="email"
                  className="mt-3 mb-1 label-required text-white"
                >
                  Email
                </CFormLabel>
                <CFormInput
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  required
                />
                <CFormFeedback invalid>
                  Please enter a valid email address
                </CFormFeedback>

                <CFormLabel
                  htmlFor="message"
                  className="mt-3 mb-1 text-white label-required"
                >
                  Message
                </CFormLabel>
                <CFormTextarea
                  id="message"
                  placeholder="Enter your message"
                  feedbackInvalid="Please Enter Valid Message"
                  value={message}
                  style={{ height: "50px" }}
                  onChange={(event) => setMessage(event.target.value)}
                  required
                />
              </CRow>
              <div
                style={{ textAlign: "right" }}
                className="d-flex justify-content-end"
              >
                <CButton
                  variant="outline"
                  color="light"
                  type="submit"
                  className="mt-4 px-5 font-weight-600 d-flex  align-items-center gap-2"
                  disabled={loading}
                >
                  {loading ? <CSpinner size="sm" color="light" /> : null}
                  Submit
                </CButton>
              </div>
            </CForm>
          </CCol>
        </CRow>
        <CCardText className="font-lg text-white">
          <a
            className="text-white"
            style={{ textDecoration: "none" }}
            href="https://www.atgeirsolutions.com/"
            target="_blank"
          >
            Atgeir Solutions™
          </a>
          © 2023 All Rights Reserved.
        </CCardText>
      </CContainer>
    </div>
  );
};
