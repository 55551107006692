import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
} from "@coreui/react";
import { DocumentTitle } from "../component/DocumentTitle";

function IntroductionHawkeye() {
  return (
    <>
      <DocumentTitle title="Introduction" />
      <CCard style={{ padding: 10 }}>
        <CCardText className="p-0 text-justify-justify pb-0 mb-2">
          Data observability is the process of observing, examining, analyzing, visualizing, and making concrete conclusions from the given data.
          In short, it helps to understand the source data better.
        </CCardText>
        <CCardText className="p-0 text-justify-justify pb-0 mb-2">
          In Snowflake, a table can be of size ranging from a few megabytes data to terabytes,
          ranging from a few columns to multiple columns table. Just by looking at the table in Snowflake,
          it's hard for a user to interpret the table in a meaningful way.
          The amount of data is just the one side of a coin - data quality is important too.
          A table containing the data that isn’t formatted right and standardized with the rest of the database can cause delays and problems that can lead to missed opportunities, wrong interpretation and bad decisions.

        </CCardText>
        <CCardText className="p-0 text-justify-justify pb-0 mb-2">
          Data observability helps you get ahead of these hurdles. By ensuring that you observe and examine the source data that you have, you can proactively create a plan to fix the known problems and clean up your data
          warehouse before those problems affect the other data in your organization.
        </CCardText>
        <CCardText className="p-0 text-justify-justify pb-0 mb-2">
          Data profiling includes descriptive statistics such as measuring central tendency and dispersion  of data ; collecting length,
          labels and tags the data and much more.
        </CCardText>
        <b>HawkEye</b>

        <CCardText className="p-0 text-justify-justify pb-0 mb-2">
        HawkEye is a native application for data profiling that can assist you in analyzing your Snowflake data statistically and keeping an eye on the quality of your data as a result.
          Users can also discover more about data quality by looking at how your data profile has changed over time.

        </CCardText>
        <b>Features of HawkEye:</b>
        <CCardText className="ps-2 mt-2 text-justify-justify pb-0 mb-1">
          <b>Dataset Profiling: </b>
          HawkEye will do the data profiling on the source table.It is the overall description of the table which includes number of observations, variable types, average record size in memory, 
          number of duplicate rows etc.
        </CCardText>

        <CCardText className="ps-2 mt-2 text-justify-justify pb-0 mb-1">
          <b>Column Profiling:  </b>
          While analyzing the data it is important to have the option of observing individual columns separately to have the clear picture of that particular column. HawkEye gives the individual column description based on column data type like numerical, string and date.

        </CCardText>

        <CCardText className="ps-2 mt-2 text-justify-justify pb-0 mb-1">
          <b>Cross-column Profiling: </b>
          While analyzing the data it is important to have the option of observing individual columns separately to have the clear picture of that particular column. HawkEye gives the individual column description based on column data type like numerical, string and date.
        </CCardText>

        <CCardText className="ps-2 mt-2 text-justify-justify pb-0 mb-1">
          <b>Data Labeling: </b>
          Use of Machine Learning to identify different labels / tags of a textual column. It recognizes different entities in the textual column like person, organizations, geographical locations etc.
           and different tags like ADJ, CS, CC, etc.        
           </CCardText>

           <CCardText className="ps-2 mt-2 text-justify-justify pb-0 mb-1">
          <b>Anomaly Detection:  </b>
          HawkEye  also provides anomaly detection on the data profiler itself. Anomaly detection refers to the identification of outliers which deviate significantly from the majority of the data. 
          Such observations appear inconsistent with the rest of the dataset.
          <br></br>       
          <br></br>       

          The user will have to give the source table; the list of columns, 
          the number of records and other configuration information to perform data profiling. 
          HawkEye  analyzes configuration parameters, column data type and generates the profile report on individual columns and the entire dataset.
          <br></br>
          <br></br>

          After doing data profiling for numerical, string, date and labeler columns, users can also do the anomaly detection of the columns based on their preference. HawkEye uses statistical approaches to identify outliers from profile observations.
           </CCardText>


           <CCardText className="ps-2 mt-2 text-justify-justify pb-0 mb-1">
          <b>Data Quality Tests:  </b>
          HawkEye  also provides Data quality rules to ensure the completeness,validity, consistency, accuracy, uniqueness, and timeliness of data. These rules help maintain data integrity and improve the overall quality of data.         </CCardText>

      </CCard>
    </>
  );
}

export default IntroductionHawkeye;
