import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
} from "@coreui/react";
// import CostnomicsImg from 'src/assets/images/CostNomics_Logo_doc.png'
// import companyLogo from 'src/assets/images/Atgeir_Logo.png'

function Introduction() {
  return (
    <>
      <CCardText className="document-text "> Introduction.</CCardText>
      <CCard style={{ padding: 10 }}>
        <CCardText className="p-0 text-justify-justify pb-0 mb-1">
          CostNomics™ native application is a comprehensive cost management
          solution that helps you in Snowflake’s budget planning for different
          accounts, tags, storage and KPIs along with provisions to set red,
          amber, and green alerts. It captures more than 40 KPIs that provide
          deeper insights into resources consumption patterns, performance
          bottlenecks, on-going costs patterns and configuration gaps in current
          Snowflake account setup. It also helps you in forecasting the cost of
          your current account using state of the art techniques. It gives you
          provisions to generate cost optimization recommendation reports based
          on observed KPI values. All functionalities are provided using diverse
          Stored Procedures (SQL, JavaScript, and Python) that need to be
          scheduled for a minimum of 6 hours that capture relevant data and
          store them in Snowflake tables. The meaningful KPIs are also provided
          that can be called to observe and analyze data based on business
          logic.
        </CCardText>
        <CCardText className="p-0">
          These KPIs and recommendation reports can be used with any
          visualization tools like Power BI, Tabelu, React Applications to build
          dashboards supporting comprehensive investigation across various cost
          segments.
        </CCardText>
      </CCard>
    </>
  );
}

export default Introduction;
