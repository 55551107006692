import { useState } from "react";

export default function EnterpriseUUID() {
  const getEnterpriseUUID = () => {
    const tokenString = localStorage.getItem("EnterpriseUUID");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [enterpriseUUID, setEnterpriseUUID] = useState(getEnterpriseUUID());

  const saveEnterpriseUUID = (userEnterpriseUUID) => {
    localStorage.setItem("EnterpriseUUID", JSON.stringify(userEnterpriseUUID));
    setEnterpriseUUID(userEnterpriseUUID);
  };

  return {
    enterpriseUUID,
    setEnterpriseUUID: saveEnterpriseUUID,
  };
}
