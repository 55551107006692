import { cilClone, cilPencil, cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CCol, CForm, CFormTextarea, CInputGroup, CToast } from "@coreui/react";
import { useRef, useState } from "react";

export const CodeView = ({ codeText, rows, eg }) => {
  const [originalCode, setOriginalCode] = useState(codeText);
  const [code, setCode] = useState(originalCode);
  const [editable, setEditable] = useState(false);
  const textareaRef = useRef(null);

  const handleEdit = () => {
    setEditable(true);
    textareaRef.current.focus();
  };

  const handleReload = () => {
    setCode(originalCode);
    setEditable(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  return (
    <CCol style={{ maxWidth: "900px" }} className=" p-1">
      <div>
        <CForm className="position-relative">
          <CInputGroup>
            <CFormTextarea
              value={code}
              ref={textareaRef}
              onChange={handleCodeChange}
              disabled={!editable}
              rows={rows}
              className={eg ? "code-textarea-eg p-4" : "code-textarea p-4"}
              style={{
                resize: "vertical",
                borderRadius: "4px",
                color: "#394f60",
                fontWeight: 480,
                lineHeight: "20px",
                letterSpacing: "0.5px",
                overflow: "hidden",
                height: "auto",
              }}
            />
          </CInputGroup>
          <CCol className="d-flex align-items-center gap-2 position-absolute top-0 end-0 p-2 ">
            <CIcon
              width={12}
              height={12}
              icon={cilClone}
              onClick={handleCopy}
              className="icon-color node"
            />
            <CIcon
              width={12}
              height={12}
              icon={cilPencil}
              onClick={handleEdit}
              className="icon-color node"
            />
            <CIcon
              width={12}
              height={12}
              icon={cilReload}
              onClick={handleReload}
              className="icon-color node"
            />
          </CCol>
        </CForm>
      </div>
    </CCol>
  );
};
