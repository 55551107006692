import React from "react";
import { CCard, CCardText, CCol, CAlert } from "@coreui/react";
import { CodeView } from "../component/CodeView";
import DocumentationNoteInfo from "../component/DocumentationNoteInfo";
import { DocumentTitle } from "../component/DocumentTitle";
import { DocumentationNote } from "../component/DocumentationNote";

function TaskSchedulingCandour() {
  return (
    <>
      <DocumentTitle title={"Task Scheduling"} />
      {/* <CCardText className="document-text"> Task Scheduling.</CCardText> */}
      <CCard style={{ padding: 10 }}>
        <CCardText>
          This section contains the steps native app users should implement to
          schedule Snowflake tasks to populate the output tables on a regular
          basis.
        </CCardText>
        <CCardText>
          Create Snowflake Task to schedule the execution of Candour stored
          procedure.
        </CCardText>
        <DocumentationNote
          text={`1. Replace <DB_NAME> and <SCHEMA_NAME> with the name of the database and schema where the user wants to create the task.
          2. Replace <WH_NAME> with the warehouse name created in the first step of pre-installation.
          3. Task scheduling is according to the UTC time zone. It uses a cron expression with a daily time and frequency.
          4. Replace <CRON_SCHEDULE> with appropriate cron schedule that you want to use like ‘0 6 * * *’
          5. The minimum frequency for executing the task should be 6 hours.
          6. Replace <APPLICATION_NAME> with the name of the created application.
          `}
        />
        <CodeView rows={1} codeText={`USE ROLE ACCOUNTADMIN;`} />
        <CodeView
          rows={5}
          codeText={`CREATE OR REPLACE TASK <DB_NAME>.<SCHEMA_NAME>.CANDOUR_SCHEDULING_TASK
          WAREHOUSE = <WH_NAME>
          SCHEDULE = 'USING CRON <CRON_SCHEDULE> UTC'
          AS
          BEGIN
          CALL CODE.CANDOUR(‘{"DB_REFERENCE":"<VALUE1>","SCHEMA_REFERENCE": "<VALUE2>","TABLE_REFERENCE":"<VALUE3>","DB_PRODUCTION":"<VALUE4>","SCHEMA_PRODUCTION":"<VALUE5>","TABLE_PRODUCTION":"<VALUE6>"}’);
          END;
          `}
        />
        <br />
        <DocumentationNoteInfo
          text={`YOU HAVE COMPLETED ALL TASK SCHEDULING STEPS.`}
        />
      </CCard>
    </>
  );
}
export default TaskSchedulingCandour;
