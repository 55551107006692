import { useState } from "react";

export const UseUserPlanDetails = () => {
  const getUserPlanDetails = () => {
    const userPlanDetails = JSON.parse(
      sessionStorage.getItem("userPlanDetails")
    );
    return userPlanDetails;
  };

  const [userPlanDetails, setUserPlanDetails] = useState(getUserPlanDetails());

  const saveUserPlanDetails = (start_date, end_date, plan) => {
    // const userDetails={email,request_type}
    sessionStorage.setItem(
      "userPlanDetails",
      JSON.stringify({ start_date, end_date, plan })
    );
    setUserPlanDetails({ start_date, end_date, plan });
  };
  return { setUserPlanDetails: saveUserPlanDetails, userPlanDetails };
};
