import { CCard, CCardText } from "@coreui/react";

export const Title = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CCard className="pt-5 border-0 bg-transparent w-75">
        <CCardText
          className={`section-title ${
            props.color === undefined ? "title-text-gradiant" : props.color
          }`}
        >
          {props.title}
        </CCardText>
      </CCard>
    </div>
  );
};
