import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    SF_ApiUrl: "",
    SF_Warehouse: "",
    SF_Role: "",
    SF_Database: "",
    SF_Schema: "",
    SF_Configurations: {},
    DataSourceId: "",
    currentDataSourceName: ""
}

export const configDetailsSlice = createSlice({
    name: 'configDetailsSlice',
    initialState,
    reducers: {
        updateSFApiUrl: (state, action) => {
            state.SF_ApiUrl = action.payload
        },
        updateSFWarehouse: (state, action) => {
            state.SF_Warehouse = action.payload
        },
        updateSFRole: (state, action) => {
            state.SF_Role = action.payload
        },
        updateSFDatabase: (state, action) => {
            state.SF_Database = action.payload
        },
        updateSFSchema: (state, action) => {
            state.SF_Schema = action.payload
        },
        updateSFConfigurations: (state, action) => {
            state.SF_Configurations = action.payload
        },
        updateDataSourceId: (state, action) => {
            state.DataSourceId = action.payload
        },
        updateCurrentDataSourceName: (state, action) => {
            state.currentDataSourceName = action.payload
        },
    },
})

export const {
    updateSFApiUrl,
    updateSFDatabase,
    updateSFRole,
    updateSFSchema,
    updateSFWarehouse,
    updateSFConfigurations,
    updateDataSourceId,
    updateCurrentDataSourceName

} = configDetailsSlice.actions

export default configDetailsSlice.reducer