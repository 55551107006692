export const env = process.env.REACT_APP_NODE_ENV;
export const port = process.env.REACT_APP_PORT;
export const api_url = process.env.REACT_APP_API_URL;
export const api_key = process.env.REACT_APP_API_KEY;

export const sf_query_body = {
  statement: "<to_be_added_by_service_class>",
  token: "<to_be_added_runtime>",
  username: "<to_be_added_runtime>",
};

export const sf_query_handle_body = {
  statementHandle: "<to_be_added_by_service_class>",
  token: "<to_be_added_runtime>",
};
