import { CContainer, CRow, CCol } from "@coreui/react";
import { PricingPoints } from "../components/costnomics/Reusable/PricingPoints";
import { animateScroll } from "react-scroll";
import { useState } from "react";

const basicData = [
  "One Snowflake Account",
  "1 months Trial for 1 Snowflake Account",
  "Upto 5 Accounts Supported in Web Client",
  "Budgets & Alerts",
  "Cost Monitoring",
  "Storage Recommendations",
  "Query Recommendations",
  "Warehouse Recommendations",
  "ETl/ELT Workload Monitoring",
  "Custom Reports",
  "Enterprise Monitoring Dashboard",
  "Configuration Management",
  "Looker Costs",
  "Root Cause Analysis",
  "Chargeback Model",
  "Forecasts & Anomaly Detection",
  "What if (Experiments & Automation) (Coming Soon)",
  "Third Party Integration (Embeddings/API/SDK) (Coming Soon)",
  "Automated Workload Identification and Cost Monitoring (Coming Soon)",
  "Customised Monthly Consulting for Cost & Performance Optimization",
  "Product Customization",
  "Dedicated Support",
];

const enterpriseData = [
  "Multiple Snowflake Accounts",
  "2 months Trial for 1 Snowflake Account",
  "More than 5 Accounts Supported in Web Client",
  "Budgets & Alerts",
  "Cost Monitoring",
  "Storage Recommendations",
  "Query Recommendations",
  "Warehouse Recommendations",
  "ETl/ELT Workload Monitoring",
  "Custom Reports",
  "Enterprise Monitoring Dashboard",
  "Configuration Management",
  "Looker Costs",
  "Root Cause Analysis",
  "Chargeback Model",
  "Forecasts & Anomaly Detection",
  "What If (Experiments & Automation) (Coming Soon)",
  "Third Party Integration (Embeddings/API/SDK) (Coming Soon)",
  "Automated Workload Identification and Cost Monitoring (Coming Soon)",
  "10 hours Monthly Consulting for Cost & Performance Optimization",
  "Dedicated Support",
];
const enterpriseGoldData = [
  "Multiple Snowflake Accounts",
  "2 months Trial for 1 Snowflake Account",
  "More than 5 Accounts Supported in Web Client",
  "Budgets & Alerts",
  "Cost Monitoring",
  "Storage Recommendations",
  "Query Recommendations",
  "Warehouse Recommendations",
  "ETl/ELT Workload Monitoring",
  "Custom Reports",
  "Enterprise Monitoring Dashboard",
  "Configuration Management",
  "Looker Costs",
  "Root Cause Analysis",
  "Chargeback Model",
  "Forecasts & Anomaly Detection",
  "What if (Experiments & Automation) (Coming Soon)",
  "Third Party Integration (Embeddings/API/SDK) (Coming Soon)",
  "Automated Workload Identification and Cost Monitoring (Coming Soon)",
  "Customised Monthly Consulting for Cost & Performance Optimization",
  "Product Customization",
  "Dedicated Support",
];

export const Pricing = () => {
  const [showMore, setShowMore] = useState({
    basic: false,
    enterprise: false,
    enterpriseGold: false,
  });
  return (
    <CCol
      xs="12"
      sm="12"
      className="m-0 mt-5"
      style={{ paddingBottom: "80px" }}
    >
      <CRow className="m-0 px-5 d-flex align-items-center justify-content-center">
        <CCol xs="12" sm="4">
          <div class="pricing-card card-3">
            <h3>CostNomics™</h3>
            <h2>350</h2>
            <h4>$350/month</h4>
            <hr className="mb-2" />
            <CCol
              style={{
                height: "630px",
              }}
            >
              {basicData.map((features) => (
                <PricingPoints text={features} />
              ))}
            </CCol>

            <div style={{ marginTop: "10px" }}>
              <a
                href="https://app.snowflake.com/marketplace/listing/GZSTZ66YI8SF4/atgeir-solutions-inc-costnomics%E2%84%A2?search=costnomics"
                target="_blank"
                className="d-flex justify-content-center py-2"
              >
                Buy
              </a>
            </div>
          </div>
        </CCol>
        {/* <CCol xs="12" sm="4">
          <div class="pricing-card card2">
            <h3>Enterprise</h3>
            <h2>350</h2>
            <h4>$350/month</h4>
            <hr className="mb-2" />

            <CCol
              style={{
                height: "650px",
                overflow: "auto",
              }}
            >
              {enterpriseData.map((features) => (
                <PricingPoints text={features} />
              ))}
            </CCol>

            <CCol>
              <a
                // href="https://app.snowflake.com/marketplace/listings/Atgeir%20Solutions%20Inc"
                // target="_blank"
                onClick={() => animateScroll.scrollToBottom()}
                className=" d-flex justify-content-center py-2 cursor-pointer"
              >
                Contact Sales
              </a>
            </CCol>
          </div>
        </CCol>
        <CCol xs="12" sm="4">
          <div class="pricing-card card4">
            <h3>Enterprise</h3>
            <h2>Gold</h2>
            <h4>Contact for price</h4>
            <hr className="mb-2" />
            <CCol
              style={{
                height: "650px",
                overflow: "auto",
              }}
            >
              {enterpriseGoldData.map((features) => (
                <PricingPoints text={features} />
              ))}
            </CCol>

            <CCol>
              <a
                // href="https://app.snowflake.com/marketplace/listings/Atgeir%20Solutions%20Inc"
                // target="_blank"
                onClick={() => animateScroll.scrollToBottom()}
                className=" d-flex justify-content-center py-2 cursor-pointer"
              >
                Contact Sales
              </a>
            </CCol>
          </div>
        </CCol> */}
      </CRow>
    </CCol>
  );
};
