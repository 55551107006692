import { React, useState } from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CNav,
  CCardImage,
  CFooter,
  CNavItem,
  CNavLink,
  CHeader,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CTabContent,
  CTabPane,
} from "@coreui/react";
import Introduction from "./Introduction";
import PreInstallationSetup from "./PreInstallationSetup";
import PostInstallationSetup from "./PostInstallationSetup";
import DataPopulationSteps from "./DataPopulationSteps";
import TaskScheduling from "./TaskScheduling";
import Examples from "./dropdown/KpiCalls";
import UninstallationSteps from "./UninstallationSteps";
import CostnomicsImg from "../../assets/documentationAssets/images/CostNomics_Logo_doc.png";
import companyLogo from "../../assets/documentationAssets/images/Atgeir_Logo.png";
import AnomalyDocumentation from "./dropdown/AnomalyDocumentation";
import "../../scss/documentation.css";
import Budget from "./dropdown/BudgetManagement";
import Recommendations from "./dropdown/Recommendations_Doc";

export default function Documentation() {
  const [activeKey, setActiveKey] = useState(1);

  var href_url = window.location.href;
  var subStr = "/costnomics/user-guide";

  return (
    <>
      <div id="doc-body">
        <CContainer id="about-atgeir" style={{ margin: 0, maxWidth: "none" }}>
          <CRow className="align-items-center">
            <CCol>
              <CCardImage src={CostnomicsImg} />
            </CCol>
            <CCol sm={10}>
              <CCardTitle>DataGeir™ CostNomics™</CCardTitle>
            </CCol>

            <CCol>
              <CCardImage src={companyLogo} />
            </CCol>
          </CRow>
        </CContainer>
        {/* Navigation Below */}
        <div class="d-flex flex-column docs-highlight mb-3" id="navbar">
          <div class="p-2 docs-highlight" id="navbar-tabs">
            <CNav variant="tabs" fixed="top" className="">
              <CNavItem>
                <CNavLink
                  href="user-guide#introduction"
                  active={activeKey === 1}
                  onClick={() => setActiveKey(1)}
                >
                  Introduction
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink
                  href="user-guide#pre_installation"
                  active={activeKey === 2}
                  onClick={() => setActiveKey(2)}
                >
                  Pre-Installation setup
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink
                  href="user-guide#post_installation"
                  active={activeKey === 3}
                  onClick={() => setActiveKey(3)}
                >
                  Post-Installation setup
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink
                  href="user-guide#data_population"
                  active={activeKey === 4}
                  onClick={() => setActiveKey(4)}
                >
                  First-Time Data Population Steps
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink
                  href="user-guide#task_scheduling"
                  active={activeKey === 5}
                  onClick={() => setActiveKey(5)}
                >
                  Task Scheduling
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="user-guide#uninstallation"
                  active={activeKey === 6}
                  onClick={() => setActiveKey(6)}
                >
                  Uninstallation steps
                </CNavLink>
              </CNavItem>
              
              <CDropdown variant="nav-item">
                <CDropdownToggle color="secondary">Examples</CDropdownToggle>
                <CDropdownMenu className="font-md">
                  <CDropdownItem
                    className="font-md"
                    href="user-guide#kpi_calls"
                    active={activeKey === 7}
                    onClick={() => setActiveKey(7)}
                  >
                    Cost Center Management
                  </CDropdownItem>
                  <CDropdownItem
                    className="font-md"
                    href="user-guide#budget_management"
                    active={activeKey === 9}
                    onClick={() => setActiveKey(9)}
                  >
                    Budget Management
                  </CDropdownItem>
                  <CDropdownItem
                    className="font-md"
                    href="user-guide#resource_optimization_recommendation"
                    active={activeKey === 10}
                    onClick={() => setActiveKey(10)}
                  >
                    Recommendations
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </CNav>
          </div>
        </div>
        <div class="p-2 docs-highlight " id="content">
          <CTabContent>
            <CTabPane
              role="tabpanel"
              aria-labelledby="home-tab"
              visible={activeKey === 1}
            >
              <Introduction />
            </CTabPane>

            <CTabPane
              role="tabpanel"
              aria-labelledby="profile-tab"
              visible={activeKey === 2}
            >
              <PreInstallationSetup />
            </CTabPane>

            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={activeKey === 3}
            >
              <PostInstallationSetup />
            </CTabPane>

            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={activeKey === 4}
            >
              <DataPopulationSteps />
            </CTabPane>

            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={activeKey === 5}
            >
              <TaskScheduling />
            </CTabPane>

            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={activeKey === 6}
            >
              <UninstallationSteps />
            </CTabPane>

            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={activeKey === 7}
            >
              <Examples />
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={activeKey === 8}
            >
              <AnomalyDocumentation />
            </CTabPane>

            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={activeKey === 9}
            >
              <Budget />
            </CTabPane>

            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={activeKey === 10}
            >
              <Recommendations />
            </CTabPane>
          </CTabContent>
        </div>

        <br />

        {/* FOOTER */}
        <CFooter id="footer">
          <div>
            <a
              href="https://atgeirsolutions.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Atgeir Solutions™
            </a>
            <span>&copy; 2023 All Rights Reserved.</span>
          </div>
        </CFooter>
      </div>
    </>
  );
}

Documentation.propTypes = {};
