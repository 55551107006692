import React from "react";
import { CAlert, CCol } from "@coreui/react";

const DocumentationNoteInfo = ({text}) => {
  return (
    <CCol style={{ maxWidth: "900px" }}>
      <CAlert style={{ fontWeight: 500 }} color="success">{text}</CAlert>
    </CCol>
  );
};

export default DocumentationNoteInfo;
