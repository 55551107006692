import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
} from "@coreui/react";
// import CostnomicsImg from 'src/assets/images/CostNomics_Logo_doc.png'
// import companyLogo from 'src/assets/images/Atgeir_Logo.png'

function TaskScheduling() {
  return (
    <>
      <CCardText className="document-text"> Task Scheduling.</CCardText>
      <CCard style={{ padding: 10 }}>
        <CCardText>
          This section contains the steps that native app users should execute
          to schedule snowflake tasks to populate to KPI tables on a regular
          basis.
        </CCardText>
        <CCardText>
          1) This procedure will schedule all the CostNomics™ stored procedures
          using Snowflake Task.
        </CCardText>
        <CCardText style={{ padding: 15 }}>
          <b>
            <u>NOTES:</u>
            <br />
            <br />
            <CCardText style={{ paddingLeft: "30px" }}>
              <span>1. </span>
              <u>
                Scheduling Is In Utc. It Uses A Cron Expression For Tasks Daily
                Timings And Frequencies.
              </u>
              <br />
              <br />
              <span>2. </span>
              <u>
                Replace {"<Cron_exp>"} With Appropriate Cron Schedule That You
                Want To Use. Like ‘0 6 * * *’
              </u>
              <br />
              <br />
              <span>3. </span>
              <u>Minimum Frequency To Execute This Task Should Be 6 Hours.</u>
              <br />
              <br />
              <span>4. </span>
              <u>
                Replace Costnomics_wh With The Warehouse Name Created In First
                Step Of Pre_installation.
              </u>
            </CCardText>
          </b>
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB_NAME>"};
          <br />
          CALL CODE.COSTNOMICS_SCHEDULING_SP('COSTNOMICSAPP_WH', '{"<CRON_EXP>"}
          ');
        </CCardText>
        <CCardText>
          <b>
            <u>
              YOU HAVE COMPLETED ALL TASK SCHEDULING STEPS. THEN, RUN EXAMPLES
              AS PER BELOW SECTION
            </u>
          </b>
        </CCardText>
      </CCard>
    </>
  );
}
export default TaskScheduling;
