import "core-js";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Routes } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from "react-redux";
import appStore from './demo/redux/appStore'


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <GoogleOAuthProvider clientId="971708092056-ne2m0vkq9qjh195s50che03rh2q5fm4m.apps.googleusercontent.com">
        <Provider store={appStore}>
            <App />
        </Provider>
    </GoogleOAuthProvider>

);

reportWebVitals();
