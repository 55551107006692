import { CAlert, CCol } from "@coreui/react";

export const DocumentationNote = ({ text }) => {
  return (
    <CCol style={{ maxWidth: "900px" }}>
      <CAlert color="warning" className="d-flex gap-1">
        <span style={{ fontWeight: 700 }}>Note: </span>{" "}
        <div style={{ whiteSpace: "pre-line" }}>{text}</div>
      </CAlert>
    </CCol>
  );
};
