import React from 'react';

const NewRelease = React.lazy(() => import('./pages/NewRelease'));
const Introduction = React.lazy(() => import('./pages/Introduction_v2'));
const Prerequisites = React.lazy(() => import('./pages/installation/Prerequisites'));
const PreInstallation = React.lazy(() => import('./pages/installation/PreInstallationSetup'));
const GetApplication = React.lazy(() => import('./pages/installation/GetTheApplication'));
const PostInstallation = React.lazy(() => import('./pages/installation/PostInstallationSetup'));
const DataPopulation = React.lazy(() => import('./pages/DataPopulationSteps_v2'));
const TaskScheduling = React.lazy(() => import('./pages/TaskScheduling_v2'));
const Registration = React.lazy(() => import('./pages/Registration'));
const EnterpriseDashboard = React.lazy(() => import('./pages/features/EnterpriseDashboard'));
const CostCenter = React.lazy(() => import('./pages/features/CostCenter'));
const RootCauseAnalysis = React.lazy(() => import('./pages/features/RootCauseAnalysis'));
const Looker = React.lazy(() => import('./pages/features/Looker.jsx'));
const Airflow = React.lazy(() => import('./pages/features/Airflow.jsx'));
const BudgetManagement = React.lazy(() => import('./pages/features/BudgetManagement'));
const ResourceOptimization = React.lazy(() => import('./pages/features/ResourceOptimization'));
const Workload = React.lazy(() => import('./pages/features/Workload'));
const AlertMonitoring = React.lazy(() => import('./pages/features/AlertMonitoring'));
const forecasts = React.lazy(() => import('./pages/features/Forecasts'));
const Dashboards = React.lazy(() => import('./pages/features/Dashboards'));
const AutoSuspend = React.lazy(() => import('./pages/features/AutoSuspend'));
const WarehouseExperimentation = React.lazy(() => import('./pages/features/WarehouseExperimentation'));
const QueryAcceleration = React.lazy(() => import('./pages/features/QueryAcceleration.jsx'));
const CustomReports = React.lazy(() => import('./pages/features/CustomReport.jsx'));
const QueryDebugger = React.lazy(() => import('./pages/features/QueryDebugger.jsx'));
const KpiCallsExamples = React.lazy(() => import('./pages/features/KpiCallsExamples'));
const StreamlitApp = React.lazy(() => import('./pages/StreamlitCostnomics'));
const Uninstallation = React.lazy(() => import('./pages/UninstallationSteps'));

const routes = [
  {
    path: '/costnomics/user-guide/introduction',
    name: 'Introduction',
    element: Introduction,
  },
  {
    path: '/costnomics/user-guide/new_release',
    name: 'New Release',
    element: NewRelease,
  },
  {
    path: '/costnomics/user-guide/installation',
    name: 'installation',
    element: Prerequisites,
  },
  {
    path: '/costnomics/user-guide/installation/prerequisites',
    name: 'Prerequisites',
    element: Prerequisites,
  },
  {
    path: '/costnomics/user-guide/installation/pre-installation',
    name: 'Pre Installation',
    element: PreInstallation,
  },
  {
    path: '/costnomics/user-guide/installation/get-application',
    name: 'Get Application',
    element: GetApplication,
  },
  {
    path: '/costnomics/user-guide/installation/post-installation',
    name: 'Post Installation',
    element: PostInstallation,
  },
  {
    path: '/costnomics/user-guide/data-population',
    name: 'Data Population',
    element: DataPopulation,
  },
  {
    path: '/costnomics/user-guide/task-scheduling',
    name: 'Task Scheduling',
    element: TaskScheduling,
  },
  {
    path: '/costnomics/user-guide/web-application',
    name: 'Web Application',
    element: Registration,
  },
  {
    path: '/costnomics/user-guide/web-application-registration',
    name: 'Web Application Registration',
    element: Registration,
  },
  {
    path: '/costnomics/user-guide/features',
    name: 'Features',
    element: EnterpriseDashboard,
  },

  {
    path: '/costnomics/user-guide/features/enterprise-dashboard',
    name: 'Enterprise Dashboard',
    element: EnterpriseDashboard,
  },
  {
    path: '/costnomics/user-guide/features/cost-centre-tags',
    name: 'Cost Center Tags',
    element: CostCenter,
  },
  {
    path: '/costnomics/user-guide/features/looker',
    name: 'Looker',
    element: Looker,
  },
  {
    path: '/costnomics/user-guide/features/airflow',
    name: 'Airflow',
    element: Airflow,
  },
  {
    path: '/costnomics/user-guide/features/root-cause-analysis',
    name: 'Root Cause Analysis',
    element: RootCauseAnalysis,
  },
  {
    path: '/costnomics/user-guide/features/BudgetManagement',
    name: 'Budget Management',
    element: BudgetManagement,
  },

  {
    path: '/costnomics/user-guide/features/resource-optimization',
    name: 'Resource Optimization',
    element: ResourceOptimization,
  },
  {
    path: '/costnomics/user-guide/features/workload',
    name: 'Workload',
    element: Workload,
  },
  {
    path: '/costnomics/user-guide/features/alert-monitoring',
    name: 'Alert Monitoring',
    element: AlertMonitoring,
  },
  {
    path: '/costnomics/user-guide/features/forecasts',
    name: 'Forecasts',
    element: forecasts,
  },
  {
    path: '/costnomics/user-guide/features/dashboards',
    name: 'Dashboards',
    element: Dashboards,
  },

  {
    path: '/costnomics/user-guide/features/query-acceleration-service',
    name: 'Query Acceleration',
    element: QueryAcceleration,
  },
  {
    path: '/costnomics/user-guide/features/auto-suspend-experimentation',
    name: 'Auto Suspend',
    element: AutoSuspend,
  },
  {
    path: '/costnomics/user-guide/features/warehouse-experimentation',
    name: 'Warehouse Experimentation',
    element: WarehouseExperimentation,
  },

  {
    path: '/costnomics/user-guide/features/custom-reports',
    name: 'Custom Reports',
    element: CustomReports,
  },

  {
    path: '/costnomics/user-guide/features/query-debugger',
    name: 'Query Debugger',
    element: QueryDebugger,
  },

  {
    path: '/costnomics/user-guide/features/Kpi-call-examples',
    name: 'Kpi Calls Examples',
    element: KpiCallsExamples,
  },
  {
    path: '/costnomics/user-guide/streamlit-app',
    name: 'Streamlit App',
    element: StreamlitApp,
  },
  {
    path: '/costnomics/user-guide/uninstallation',
    name: 'Uninstallation',
    element: Uninstallation,
  },
];

export default routes;
