import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    sidebarShow: true,
    sidebarUnfoldable:false
}

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store


export const sideBarSlice = createSlice({
    name: 'sideBarSlice',
    initialState,
    reducers: {
        // updateSideBar: (state = initialState, { type, ...rest }) => {
        //     switch (type) {
        //         case 'set':
        //             return { ...state, ...rest }
        //         default:
        //             return state
        //     }
        // }
        updateSideBarShow: (state,action) => {
            state.sidebarShow= action.payload
        },
        updateSideBarUnfoldable: (state,action) => {
            state.sidebarUnfoldable= action.payload
        }
    },
})

export const {
    updateSideBarShow,
    updateSideBarUnfoldable
    // updateSideBar

} = sideBarSlice.actions

export default sideBarSlice.reducer