import { useState } from "react";


export const UseDomainDetails = () => {

    const getDomainDetails = () => {
        const domainDetails = JSON.parse(sessionStorage.getItem("domainDetails"));
        return domainDetails;
    }

    const [domainDetails, setDomainDetails] = useState(getDomainDetails());

        const saveDomainDetails = (domain_name,email,domain_description,owner) => {
        sessionStorage.setItem("domainDetails", JSON.stringify({domain_name,email,domain_description,owner }));
        setDomainDetails({domain_name,email,domain_description,owner});
    };
    return { setDomainDetails: saveDomainDetails, domainDetails };

}