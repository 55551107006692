import { useState } from "react";
import Navbar from "./navigation_bar/Navbar";
import SideBar from "./navigation_bar/SideBar";
import CostnomicsContent from "./CostnomicsContent";
import { CodeView } from "./components/CodeView";
import { CCol } from "@coreui/react";

export const Costnomics = () => {
  const [visible, setVisible] = useState(true);
  return (
    <div>
      <SideBar visible={visible} setVisible={setVisible} />
      <div className="wrapper d-flex flex-column bg-white w-100">
        <Navbar visible={visible} setVisible={setVisible} />
        {/* <div className="body d-flex px-3"> */}
        <CCol className="px-3 pe-4 w-100">
          <CostnomicsContent />
        </CCol>
        {/* <div className="flex-grow-1" style={{width:"65%"}}>
            <CodeView />
          </div> */}
        {/* <div style={{ width: "300px" }}>h</div> */}
        {/* </div> */}
        {/* <AppFooter /> */}
      </div>
    </div>
  );
};
