import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";

// routes config
import routes from "./routes";

const CostnomicsContent = () => {
  return (
    <CContainer fluid>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {routes.map((route, idx) => {
            // console.log(route);
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}

          <Route
            path="/costnomics/user-guide"
            element={<Navigate to={routes[0].path} replace />}
          />
          {/* <Route
            path="/costnomics/user-guide/installation"
            element={<Navigate to={routes[2].path} replace />}
          /> */}

          <Route
            path="/breadcrumbs"
            element={<Navigate to="breadcrumbs" replace />}
          />
        </Routes>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(CostnomicsContent);
