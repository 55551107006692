import { React, useState, useEffect } from "react";
// import { cilCheckCircle } from '@coreui/icons-react'
// import { CIcon } from '@coreui/icons-react';
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import { cilCheckCircle } from "@coreui/icons";
import { signupFields } from "../constants/formFields";
import SignupService from "../services/signup.service";
import requestflowService from "../services/requestflow.service";
import getEnterpriseService from "../services/getEnterprise.service";
import EnterpriseService from "../services/enterprise.list.service";
import FormAction from "./FormAction";
import ValidateEmail from "../services/emailvalidation.service";
import Input from "./Input";
import "../../scss/style.scss";
import RegisterService from "../services/cognito.service";
import UseToken from "./App/UseToken";
import {
  CAlert,
  CSpinner,
  CNav,
  CTabPane,
  CTabContent,
  CNavItem,
  CNavTitle,
  CNavLink,
  CCardTitle,
  CButton,
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CForm,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CCardGroup,
  CFormCheck,
  CFormLabel,
} from "@coreui/react";
import validator from "validator";
import signup_licenseService from "../services/signup_license.service";
import GoogleSignIn from "../pages/GoogleSignIn";
import { useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
const fields = signupFields;
let fieldsState = {};

fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Signup(props) {
  const { idToken, setIdToken } = UseToken();
  const [signupState, setSignupState] = useState(fieldsState);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isFromSubmit, setIsFromSubmit] = useState(false);
  const [isEmailExists, setIsEmailExists] = useState(false);
  const [isEnterpriseExists, setIsEnterpriseExists] = useState(false);
  const [signupRes, setSignupRes] = useState(false);
  const [isAllClicked, setIsAllClicked] = useState();
  const [productArray, setProductArray] = useState(["1"]);
  const [costnomicsCheckd, setCostnomicsCheckd] = useState(true);
  const [hawkeyeChecked, setHawkeyeChecked] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [enterprises, setEnterprises] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {}, [costnomicsCheckd, hawkeyeChecked]);
  useEffect(() => {
    setCostnomicsCheckd(["1"]);
    setProductArray(["1"]);
    getEnterpriseRequests();
  }, []);
  const getEnterpriseRequests = async () => {
    const response = await EnterpriseService.getAllEnterprises();
    if (response != undefined) {
      // console.log(response)
      setEnterprises(response);
    } else {
      console.log("No data found");
    }
  };
  const handleChange = (e) => {
    setDisableButton(false);
    setSignupRes(false);
    setSignupState({ ...signupState, [e.target.id]: e.target.value });
    setVisible(false);
    setIsEmailExists(true);
    let uniq = enterprises.find((el) => {
      if (e.target.id === "name")
        return (
          el.service_name.toLowerCase() ==
          e.target.value.toLowerCase().replace(/\s+/g, " ").trim()
        );
    });
    if (uniq === undefined) {
      setIsEnterpriseExists(true);
    } else {
      setIsEnterpriseExists(false);
    }
  };
  const handleMobileChange = (e) => {
    setDisableButton(false);
    setSignupRes(false);
    setSignupState({ ...signupState, ["mobile"]: e });
    setVisible(false);
    setIsEmailExists(true);
  };
  const handleSubmit = (e) => {
    setDisableButton(true);
    e.preventDefault();
    // console.log(signupState);
    setIsFromSubmit(true);
    setIsEmailExists(true);
    let uniq = enterprises.find((el) => {
      return (
        el.service_name.toLowerCase() ==
        signupState["name"].toLowerCase().replace(/\s+/g, " ").trim()
      );
    });
    if (uniq === undefined) {
      setIsEnterpriseExists(true);
      // console.log("create account goo*******")
      createAccount();
    } else {
      setIsEnterpriseExists(false);
      setIsLoading(false);
    }
  };
  // console.log(isEnterpriseExists);
  const emailvalidation = async () => {
    var email = signupState["email"].toLowerCase();
    var emailresp = await ValidateEmail.checkEmail(email);
    // console.log("emailresp", emailresp);
    return emailresp;
  };

  function handleClick(event) {
    setDisableButton(false);
    setIsLoading(false);
    if (event.target.value == "1") {
      // console.log(event.target.value)
      setCostnomicsCheckd(true);
    } else {
      setHawkeyeChecked(event.target.checked);
    }

    if (event.target.checked) {
      setCostnomicsCheckd(["1"]);
      // setProductArray([...productArray, event.target.value]);
    }
    // else {
    //   const newstorageCost = [...productArray];
    //   const index = productArray.findIndex(
    //     (product) => product === event.target.value
    //   );
    //   newstorageCost.splice(index, 1);
    //   setProductArray(newstorageCost);
    // }

    // else if (event.target.value == "Hawkeye") {
    //   if (event.target.checked) {
    //     setProductArray([...productArray, "Hawkeye"])
    //   }
    //   else {
    //     const newstorageCost = [...productArray];
    //     const index = productArray.findIndex((product) => product === event.target.value);
    //     newstorageCost.splice(index, 1);
    //     console.log("storage data", newstorageCost)
    //     setProductArray(newstorageCost);
    //   }
    // }
  }

  //handle Signup API Integration here
  const validateMobile = (mobile) => {
    if (mobile != undefined) {
      return isValidPhoneNumber(mobile);
    } else {
      return false;
    }
  };
  const createAccount = async () => {
    setIsLoading(true);

    var name = signupState["name"].replace(/\s+/g, " ").trim();
    var owner = signupState["owner"].replace(/\s+/g, " ").trim();
    var email = signupState["email"].toLowerCase();
    var address = signupState["address"].replace(/\s+/g, " ").trim();
    var password = signupState["password"];
    var cpassword = signupState["confirm password"];
    var mobile = signupState["mobile"].trim();

    if (
      name.length == 0 ||
      !isNaN(name) ||
      owner.length == 0 ||
      !isNaN(owner) ||
      email.length == 0 ||
      !validator.isEmail(email) ||
      address.length == 0 ||
      !isNaN(address) ||
      mobile.length == 0 ||
      !validateMobile(mobile) ||
      password.length == 0 ||
      productArray.length == 0 ||
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      }) ||
      cpassword.length == 0 ||
      !isNaN(cpassword) ||
      password !== cpassword
    ) {
      setIsLoading(false);
      setDisableButton(false);
      return;
    }
    var cpassword = signupState["confirm password"];

    var emailres = await emailvalidation();
    // console.log("var res", emailres);

    if (emailres.count == 0) {
      setIsEmailExists(true);

      try {
        var reponse = await SignupService.signUpUser(
          name,
          null,
          owner,
          mobile,
          email,
          address,
          password,
          "DATA_SOURCE_USER",
          "67e5dbbe-93c2-4e0d-b66e-29b72f65a452",
          null
        );
        setIsLoading(true);
        setVisible(true);
        setIsFromSubmit(false);
        // signupState["name"] = "";
        // signupState["owner"] = "";
        // signupState["address"] = "";
        // signupState["email"] = "";
        // signupState["password"] = "";
        // signupState["confirm password"] = "";
        // signupState["mobile"] = "";

        setCostnomicsCheckd(false);
        setHawkeyeChecked(false);
        setProductArray(["1"]);
        setSignupState({ ...signupState });
        if (reponse != undefined) {
          var repo = await signup_licenseService.signUpUserLicense(
            email,
            productArray
          );
          setDisableButton(false);
          setSignupRes(true);
          var enterprise = await getEnterpriseService.getEnterprise(email);
          var enterprise_uuid = enterprise[0].uuid;
          // console.log("Enterprise UUID**", enterprise[0].uuid);
          var action_id = 1;
          var actionFlow = await requestflowService.registerRequest(
            action_id,
            enterprise_uuid
          );
        } else {
          setSignupRes(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsEmailExists(false);
    }
    setIsLoading(false);
    setTimeout(function () {
      setSignupRes(false);
      navigate("/costnomics/demo");
    }, 3000);
  };
  return (
    <CRow className="justify-content-center p-0">
      <CCol>
        <CCardGroup>
          <CCard className="px-4 pt-2 pb-0 ">
            <CCardBody>
              <CForm
                style={{ display: "block" }}
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <CCardTitle style={{ fontSize: 20 }}>
                  DataGeir™ Sign Up
                </CCardTitle>
                {/* <CCardTitle style={{ fontSize: 22 }}>Sign up to create an account</CCardTitle> */}
                <p className="text-medium-emphasis">
                  Sign up to create an account
                </p>
                {fields.map((field) => (
                  <>
                    <Input
                      key={field.id}
                      handleChange={handleChange}
                      value={signupState[field.id]}
                      labelText={field.labelText}
                      labelFor={field.labelFor}
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      handleMobileChange={handleMobileChange}
                      //isRequired={field.isRequired}
                      placeholder={field.placeholder}
                      icon={field.icon}
                      autoComplete={field.autoComplete}
                      autofocus={field.autofocus}
                    />

                    {isFromSubmit &&
                    signupState[field.id] &&
                    signupState[field.id].length == 0 ? (
                      <span className="text-danger">{`Please enter ${field.id}`}</span>
                    ) : isFromSubmit &&
                      field.id == "name" &&
                      !isNaN(signupState[field.id]) ? (
                      <span className="text-danger">{`Please enter a valid ${field.id}`}</span>
                    ) : isFromSubmit &&
                      field.id == "name" &&
                      isEnterpriseExists == false ? (
                      <span className="text-danger">{`This Enterprise already Exists`}</span>
                    ) : isFromSubmit &&
                      field.id == "owner" &&
                      !isNaN(signupState[field.id]) ? (
                      <span className="text-danger">{`Please enter a valid ${field.id}`}</span>
                    ) : isFromSubmit &&
                      field.id == "address" &&
                      !isNaN(signupState[field.id]) ? (
                      <span className="text-danger">{`Please enter a valid ${field.id}`}</span>
                    ) : isFromSubmit &&
                      field.id == "mobile" &&
                      !validateMobile(signupState[field.id]) ? (
                      <span className="text-danger">{`Please enter a valid Mobile Number`}</span>
                    ) : isFromSubmit &&
                      field.id == "email" &&
                      !validator.isEmail(signupState[field.id]) ? (
                      <span className="text-danger">{`Please enter a valid email address`}</span>
                    ) : isFromSubmit &&
                      field.id == "email" &&
                      !isEmailExists ? (
                      <span className="text-danger">{`This email address is already registered`}</span>
                    ) : isFromSubmit &&
                      field.id == "password" &&
                      !validator.isStrongPassword(signupState[field.id], {
                        minLength: 8,
                        minLowercase: 1,
                        minUppercase: 1,
                        minNumbers: 1,
                        minSymbols: 1,
                      }) ? (
                      <span className="text-danger">{`Please enter a strong password`}</span>
                    ) : isFromSubmit &&
                      field.id == "confirm password" &&
                      signupState["password"] !==
                        signupState["confirm password"] ? (
                      <span className="text-danger">{`Password and confirm password sholud be the same`}</span>
                    ) : (
                      ""
                    )}
                  </>
                ))}
                <br />

                {/* <CFormCheck inline id="inlineCheckbox1" value="All" label="All" onChange={handleClick} /> */}
                <CFormCheck
                  className="mt-1 mb-0"
                  inline
                  id="inlineCheckbox2"
                  value="1"
                  label="CostNomics"
                  onChange={handleClick}
                  defaultChecked
                  checked={costnomicsCheckd}
                />
                <CFormCheck
                  className="mt-1 mb-0"
                  inline
                  id="inlineCheckbox3"
                  value="2"
                  label="HawkEye"
                  onChange={handleClick}
                  disabled
                  checked={hawkeyeChecked}
                />
                <br />
                {isFromSubmit && productArray.length == 0 ? (
                  <span className="text-danger">{`Please select product`}</span>
                ) : (
                  ""
                )}
                <CRow className="mt-4 d-flex flex-column align-items-center gap-2 mb-0 pb-0">
                  <CCol
                    sm={12}
                    md={12}
                    className="m-0 p-0 d-flex align-items-center justify-content-center text-muted"
                  >
                    <CButton
                      disabled={disableButton}
                      color="primary"
                      type="submit"
                      size="sm"
                      className="d-flex gap-1 align-items-center sign-in-button justify-content-center mx-4 mb-0"
                    >
                      {isLoading ? (
                        <CSpinner
                          component="span"
                          size="sm"
                          aria-hidden="true"
                        />
                      ) : null}
                      Sign Up
                    </CButton>
                  </CCol>
                  <div className="d-flex justify-content-center gap-2 align-items-center mt-2">
                    <p className="text-medium-emphasis m-0 p-0">
                      Already have an account?
                    </p>
                    <div
                      className="border border-light rounded px-2 py-1 node"
                      onClick={() => {
                        props.handleActiveKey(0);
                      }}
                    >
                      Sign in
                    </div>
                  </div>
                </CRow>
                <br></br>
                {signupRes ? (
                  <div>
                    <CAlert
                      color="success"
                      className="d-flex align-items-center"
                      dismissible
                    >
                      <CIcon
                        icon={cilCheckCircle}
                        className="flex-shrink-0 me-2"
                        width={24}
                        height={24}
                      />
                      {/* <div>Signup successful. Awaiting admin approval.</div> */}
                      <div>Signup successful.</div>
                    </CAlert>
                  </div>
                ) : null}
                <br></br>
              </CForm>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  );
}
