import axios from "axios";
const config = require("../config/config");
const API_URL = config.api_url;


class UserDetailsService {
  getUserDetails(email) {
    // console.log("API url:", API_URL)
    try {
      var data = JSON.stringify({
        email: email,
      });

      var config = {
        method: "post",
        url: API_URL + "/v1/postgres/signup_details/email",
        headers: {
          
          "Content-Type": "application/json",
        },
        data: data,
      };
      return axios
        .request(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

export default new UserDetailsService();
