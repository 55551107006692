import { useState } from 'react';

export default function UseUUID() {

  const getUUID = () => {
    const tokenString = localStorage.getItem('UUID');
    const userToken = JSON.parse(tokenString);
    return userToken
  };
 
  const [UUID, setUUID] = useState(getUUID());

  const saveUuid = (useruuid) => {
    localStorage.setItem('UUID', JSON.stringify(useruuid));
    setUUID(useruuid);
  };
 
  return {
    setUUID:saveUuid,
    UUID,
  }
}