import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
} from "@coreui/react";
import { CodeView } from "../component/CodeView";
import { DocumentationNote } from "../component/DocumentationNote";
import { DocumentTitle } from "../component/DocumentTitle";
// import CostnomicsImg from 'src/assets/images/CostNomics_Logo_doc.png'
// import companyLogo from 'src/assets/images/Atgeir_Logo.png'

function DataPopulationStepsCandour(props) {
  return (
    <>
      {/* <CCardText className="document-text">
        {" "}
        First-Time Data Population Steps.
      </CCardText> */}
      <DocumentTitle title={"Data Population Steps"} />
      <CCol className="d-flex gap-2">
        <CCard style={{ padding: 10, paddingRight: "230px" }}>
          <CCardText>
            This section contains the KPI table data population steps that
            native app users need to execute after post-installation setup.
          </CCardText>
          <CCardText>
            1) Run the following commands for the data population.
            <br />
            <br />
            <DocumentationNote
              text={`Replace <ROLE_NAME> and <APPLICATION_NAME>  with the name of the created role and 	application.`}
            />
            <CodeView rows={1} codeText={`USE ROLE <ROLE_NAME>;`} />
            <CodeView rows={1} codeText={`USE DATABASE <APPLICATION_NAME>;`} />
            <br />
            <CCardText style={{ paddingLeft: 10 }}>
              Use the following procedure call to populate data
            </CCardText>
            <CodeView
              rows={3}
              codeText={`CALL CODE.CANDOUR('{"DB_REFERENCE":"<VALUE1>","SCHEMA_REFERENCE": "<VALUE2>","TABLE_REFERENCE":"<VALUE3>","DB_PRODUCTION":"<VALUE4>","SCHEMA_PRODUCTION":"<VALUE5>","TABLE_PRODUCTION":"<VALUE6>"}');`}
            />
            <br />
            <CCardText style={{ paddingLeft: 10 }}>
              <b>Parameters:</b>
              <br />
              {"<VALUE1>"}: Name of the reference database that contains
              reference / training dataset
              <br />
              {"<VALUE2>"}: Name of the reference schema that contains reference
              / training dataset
              <br />
              {"<VALUE3>"}: Name of the reference / training dataset table
              <br />
              {"<VALUE4>"}: Name of the production database that contains
              production dataset
              <br />
              {"<VALUE5>"}: Name of the production schema that contains
              production dataset
              <br />
              {"<VALUE6>"}: Name of the production table
              <br />
            </CCardText>
          </CCardText>
          <CCardText>
            2) Run the following command for regression analysis (This is
            optional step, if you want to train a new regression model on your
            dataset and check how it performs)
            <br />
            <br />
            <CodeView
              rows={1}
              codeText={`CALL CODE.REGRESSION_SP('{"DB_REFERENCE":"<VALUE1>","SCHEMA_REFERENCE": "<VALUE2>","TABLE_REFERENCE":"<VALUE3>","MODEL_NAME":"<VALUE4>","TARGET_COLUMN":"<VALUE5>"}');`}
            />
            <br />
            <CCardText style={{ paddingLeft: 10 }}>
              <b>Parameters:</b>
              <br />
              {"<VALUE1>"}: Name of the reference database that contains
              reference / training dataset
              <br />
              {"<VALUE2>"}: Name of the reference schema that contains reference
              / training dataset
              <br />
              {"<VALUE3>"}: Name of the reference / training dataset table
              <br />
              {"<VALUE4>"}: Name of the models to choose from list: ['LINEAR',
              'GRADIENT','DECISIONTREE', 'RIDGE', 'LASSO']
              <br />
              {"<VALUE5>"}: Name of the target_column in the reference /
              training dataset table dataset
              <br />
            </CCardText>
          </CCardText>
          <CCardText>
            3) Run the following command for classification analysis (This is
            optional step, if you want to train a new classification model on
            your dataset and check how it performs)
            <br />
            <br />
            <CodeView
              rows={1}
              codeText={`CALL CODE.CLASSIFICATION_SP('{"DB_REFERENCE":"<VALUE1>","SCHEMA_REFERENCE": "<VALUE2>","TABLE_REFERENCE":"<VALUE3>","MODEL_NAME":"<VALUE4>","TARGET_COLUMN":"<VALUE5>"}');`}
            />
            <br />
            <CCardText style={{ paddingLeft: 10 }}>
              <b>Parameters:</b>
              <br />
              {"<VALUE1>"}: Name of the reference database that contains
              reference / training dataset
              <br />
              {"<VALUE2>"}: Name of the reference schema that contains reference
              / training dataset
              <br />
              {"<VALUE3>"}: Name of the reference / training dataset table
              <br />
              {"<VALUE4>"}: Name of the models to choose from list:
              ['DECISIONTREE','GRADIENT','RANDOMFOREST', 'SVM']
              <br />
              {"<VALUE5>"}: Name of the target_column in the reference /
              training dataset table dataset
              <br />
            </CCardText>
          </CCardText>
        </CCard>
        <CCard
          className="p-2 pt-2 pb-2 example-link-card z-index-2"
          style={{ right: "25px" }}
        >
          <CCardText>
            You can explore the app by running the{" "}
            <a
              href="#sample-dataset-example"
              style={{ color: "#0000ff" }}
              onClick={() => props.setActiveKey(6)}
            >
              <b>Sample Dataset Example</b>
            </a>{" "}
            .
          </CCardText>
        </CCard>
      </CCol>
    </>
  );
}
export default DataPopulationStepsCandour;
