import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
} from "@coreui/react";
import { CodeView } from "../component/CodeView";
import { DocumentationNote } from "../component/DocumentationNote";
import { DocumentTitle } from "../component/DocumentTitle";

function DataPopulationStepsHawkeye(props) {
  return (
    <CContainer className="m-0 p-0 d-flex gap-5" fluid>
      {/* <CCardText className="document-text">
        {" "}
        First-Time Data Population Steps.
      </CCardText> */}
      <CCol>
        <DocumentTitle title={"Data Population Steps"} />
        <CCard style={{ padding: 10,paddingRight:"20px" }}>
          <CCardText>
            This section contains the data population steps that native app
            users need to execute after post-installation setup.
          </CCardText>
          <CCardText>
            1) Run following commands to populate the config_property table
            created in the setup.
            <br />
            <br />
            <DocumentationNote
              text={`Replace <APPLICATION_NAME> with the installed application name.
            Replace <DB_NAME> with the database name that contains the table for data profiling.
            Replace <SCHEMA_NAME> with the schema name.
            Replace <TABLE_NAME> with table name on which data profiling is to be obtained`}
            />
            <CodeView rows={1} codeText={`USE DATABASE <APPLICATION_NAME>;`} />
            <CodeView
              rows={6}
              codeText={`INSERT INTO APP.CONFIG_PROPERTY (DB_NAME,SCHEMA_NAME,TABLE_NAME,CONFIGURATION,IS_DELETED) SELECT '<DB_NAME>','<SCHEMA_NAME>','<TABLE_NAME>',PARSE_JSON('{"DATE_COLUMN_NAME":"NULL","FROM_DATE":"NULL","COLUMN_LIST":"*","AVERAGE_RECORD_SIZE":"TRUE","PEARSON_MATRIX":"TRUE","TOTAL_MEMORY_SIZE":"TRUE","CHI_SQUARE_MATRIX":"TRUE","COEFFICIENT_OF_VARIATION":"TRUE","TOP_FIVE":"TRUE","LOWEST_FIVE":"TRUE","MEAN_ABSOLUTE_DEVIATION":"TRUE","MEDIAN_ABSOLUTE_DEVIATION":"TRUE","VALUE_COUNT_CATEGORICAL":"TRUE","UNIQUE_VALUES_PROPORTION_TO_BE_GREATER_THAN":"50","TEXT_VALUES_PROPORTION_TO_BE_GREATER_THAN":"50","PROFILE_ENTRIES_COUNT":"30","DATA_LABELING_THRESHOLD":"400","ANOMALY_COLUMN_LIST":""}'),false;`}
            />
            <br />
            <CCardText style={{ paddingLeft: 10 }}>
              <CCardText style={{ width: "93%" }}>
                <b>
                  Profile parameters specified in the statement default to
                  "TRUE". If users don't want profiling parameters then the user
                  can set that flag as “FALSE”.
                </b>
              </CCardText>
              <b>DB_NAME:</b>{" "}
              {`The database name that contains the table for data profiling.`}
              <br />
              <b>SCHEMA_NAME:</b> {`The schema name within the database.`}
              <br />
              <b>TABLE_NAME:</b>{" "}
              {`The source table on which data profiling is to be obtained.`}
              <br />
              <b>DATE_COLUMN_NAME:</b> {`An optional parameter.`}
              <br />
              If a user wants to filter records based on a date column, enter
              the column name here. Otherwise, keep it “NULL”.
              <br />
              <b>FROM_DATE:</b>{" "}
              {`If you specified a column name for the DATE COLUMN_NAME parameter, specify the date from which you want to do data profiling.`}
              <br />
              Format:‘YYYY-MM-DD’
              <br />
              If the DATE_COLUMN_NAME parameter is “NULL” then keep FROM_DATE
              parameter as “NULL”.
              <br />
              <br />
              <DocumentationNote
                text={`User can run profiling on entire data by setting value of DATE_COLUMN_NAME and FROM_WHICH_DATE to ‘NULL’`}
              />
              <b>COLUMN_LIST:</b>{" "}
              {`Specify the comma-separated column names on which the user wants to perform data profiling.`}
              <br />
              By default it is "*" which indicates that data profiling will be
              performed on all columns.
              <br />
              <b>UNIQUE_VALUES_PROPORTION_TO_BE_GREATER_THAN:</b>{" "}
              {`This is the threshold to identify whether a given column is categorical or not. Threshold value must be greater than 50, default is 50.`}
              <br />
              <b>TEXT_VALUES_PROPORTION_TO_BE_GREATER_THAN:</b>{" "}
              {`This is the threshold to identify whether a given string column is text or not.  Threshold value must be greater than or equal to 50, default is 50.`}
              <br />
              <b>DATA_LABELING_THRESHOLD:</b>{" "}
              {`Number of records to perform  auto labeling, default is 400.`}
              <br />
              <b>PROFILE_ENTRIES_COUNT:</b>{" "}
              {`Number of data profile entries required to run anomaly detection. Count must be greater than or equal to 30,default is 30.`}
              <br />
              <b>ANOMALY_COLUMN_LIST:</b>{" "}
              {`Specify the comma-separated column names on which the user wants to identify anomalies. Make sure it is a subset of COLUMN_LIST. By default, anomalies will be detected on the first ten columns of the data profile.`}
              <br />
            </CCardText>
          </CCardText>
          <CCardText>
            2) Run following Stored Procedure for Data Profiling.
            <br />
            <br />
            <DocumentationNote
              text={`Replace <APPLICATION_NAME> with the installed application name`}
            />
            <CodeView rows={1} codeText={`USE DATABASE<APPLICATION_NAME>;`} />
            <CodeView
              rows={1}
              codeText={`CALL CODE.HAWKEYE_PERFORMANCE ('<DATABASE_NAME>','<SCHEMA_NAME>','<TABLE_NAME>');`}
            />
            <br />
            <CCardText style={{ paddingLeft: 10 }}>
              Parameters:
              <br />
              <b>DATABASE_NAME:</b>{" "}
              {`The database name that contains the table for data profiling`}
              <br />
              <b>SCHEMA_NAME:</b> {` The schema name within the database`}
              <br />
              <b>TABLE_NAME:</b>{" "}
              {`The source table on which data profiling is to be obtained`}
              <br />
            </CCardText>
          </CCardText>
          <CCardText>
            3) Run the following statement to check profiles in the profile
            report table.
            <br />
            <CodeView rows={1} codeText={`SELECT * FROM APP.PROFILE_REPORT;`} />
          </CCardText>

          <CCardText>
            4) Steps to get data quality rules expectations:
            <br />
            <br />
            <CCardText>
              <b>Step 1:</b> Use a database.
              <br />
              <br />
              <DocumentationNote
                text={` Replace <APPLICATION_NAME> with the installed application name.`}
              />
              <CodeView
                rows={1}
                codeText={`USE DATABASE <APPLICATION_NAME>;`}
              />
            </CCardText>
            <CCardText>
              <b>Step 2:</b> Run following command to Populate config_validation
              table created in the setup.
              <br />
              <CodeView
                rows={4}
                codeText={`insert into app.CONFIG_VALIDATION(db_name, schema_name,TABLE_NAME,column_name,completness_config,validity_config,consistency_config,accuracy_config,uniqueness_config,timeliness_config)
select '<DB_NAME>','<SCHEMA_NAME>','<TABLE_NAME>','<COLUMN_NAME>',PARSE_JSON('{
  "expect_column_value_lengths_to_be_between": {
	"status": "TRUE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_kurtosis_to_be_between": {
	"status": "TRUE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_kurtosis_to_equal": {
	"status": "TRUE",
	"value": "1"
  },
  "expect_column_values_to_be_null": {
	"status": "TRUE"
  },
  "expect_column_values_not_to_be_null": {
	"status": "TRUE"
  },
  "expect_column_values_to_be_of_type": {
	"status": "TRUE",
"data_type": "<DATA_TYPE>"
  }
} '),PARSE_JSON('{
  "expect_column_values_to_match_date_format": {
	"status": "TRUE",
	"date_pattern": "YYYY-MM-DD"
  }, 
  "expect_column_to_be_categorical": {
	"status": "TRUE"
  },
  "expect_string_column_to_be_text": {
	"status": "TRUE"
  }, 
  "expect_column_distinct_values_to_be_in_set": {
	"status": "TRUE",
	"distinct_value_set": ["","",""]
  },
  "expect_column_distinct_values_to_equal_set": {
	"status": "TRUE",
	"distinct_value_set": ["","",""]
  },
  "expect_table_columns_to_match_set": {
	"status": "TRUE",
	"column_set": ["<COLUMN_LIST>"]
  },
  "expect_table_columns_to_match_ordered_list": {
	"status": "TRUE",
	"column_set": ["<COLUMN_LIST>"]
  }
}  '),PARSE_JSON('{
  "expect_table_column_count_to_be_between": {
	"status": "TRUE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_table_column_count_to_equal": {
	"status": "TRUE",
	"value": "1"
  },
  "expect_table_row_count_to_be_between": {
	"status": "TRUE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_table_row_count_to_equal": {
	"status": "TRUE",
	"value": "1"
  },
  "expect_table_duplicate_row_to_equal": {
	"status": "FALSE",
	"value": "1"
  },
  "expect_table_duplicate_row_to_be_between": {
	"status": "TRUE",
	"min_value": "1",
	"max_value": "10"
  }
} '),PARSE_JSON('{
  "expect_column_max_to_be_between": {
	"status": "TRUE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_min_to_be_between": {
	"status": "FALSE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_mean_to_be_between": {
	"status": "TRUE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_median_absolute_deviation_to_be_between": {
	"status": "TRUE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_sum_to_be_between": {
	"status": "FALSE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_quantile_values_to_be_between": {
	"status": "TRUE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_stdev_to_be_between": {
	"status": "TRUE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_not_negative_to_be_between": {
	"status": "FALSE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_not_negative_to_equal": {
	"status": "TRUE",
	"value": "1"
  },
  "expect_column_skewness_to_be_between": {
	"status": "TRUE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_skewness_equal": {
	"status": "TRUE",
	"value": "1"
  }
}'),PARSE_JSON('{
  "expect_column_proportion_of_unique_values_to_be_between": {
	"status": "FALSE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_unique_value_count_to_be_between": {
	"status": "TRUE",
	"min_value": "1",
	"max_value": "10"
  },
  "expect_column_values_to_be_unique": {
	"status": "TRUE"
  },
  "expect_column_values_not_to_be_unique": {
	"status": "TRUE"
  },
  "expect_column_unique_count_to_be_greater_than": {
	"status": "TRUE",
	"value": "1"
  },
  "expect_column_unique_count_to_be_less_than": {
	"status": "TRUE",
	"value": "1"
  },
  "expect_column_unique_count_to_equal": {
	"status": "TRUE",
	"value": "1"
  }
}'),PARSE_JSON('{
  "expect_timeliness": {
	"days": "7"	
  }
}');
`}
              />
              <br />
              <b>DB_NAME:</b>{" "}
              {`The database name that contains the table of data profiling`}
              <br />
              <b>SCHEMA_NAME:</b> {`The schema name within the database.`}
              <br />
              <b>TABLE_NAME:</b>{" "}
              {`The source table on which data profiling is obtained.`}
              <br />
              <b>COLUMN_NAME:</b>{" "}
              {`Replace <COLUMN_NAME> with column name of source table to apply data quality rules.`}
              <br />
              <b>DISTINCT_VALUE_SET:</b>{" "}
              {`Please provide a list of values that can be compared to the values in the columns.`}
              <br />
              <b>COLUMN_LIST:</b>{" "}
              {`Please provide a list of columns to check if a column exists in a table or not.`}
              <br />
              <b>DATA_TYPE:</b>{" "}
              {`Please provide data type of column to be checked.`}
              <br />
              <br />
              <DocumentationNote
                text={`By default, status flag for each test is “TRUE”, if user don’t want any test, then make it as “FALSE”`}
              />
            </CCardText>
            <CCardText>
              <b>Step 3:</b> Run following command to check config validation
              table.
              <br />
              <CodeView
                rows={1}
                codeText={`SELECT * FROM APP.CONFIG_VALIDATION;`}
              />
            </CCardText>
            <CCardText>
              <b>Step 4:</b> Run following Stored Procedure for Data Quality
              Rules.
              <br />
              <CodeView
                rows={1}
                codeText={`CALL CODE.HAWKEYE_VALIDATION_REPORT ('<DATABASE_NAME>','<SCHEMA_NAME>','<TABLE_NAME>','<COLUMN_NAME>');`}
              />
              <br />
              <b>DATABASE_NAME:</b>{" "}
              {`The database name that contains the table for data profiling.`}
              <br />
              <b>SCHEMA_NAME:</b> {` The schema name within the database.`}
              <br />
              <b>TABLE_NAME:</b>{" "}
              {`The source table on which data profiling is to be obtained.`}
              <br />
              <b>COLUMN_NAME:</b>{" "}
              {`Column name of source table of which data quality rules are to be applied.`}
              <br />
              <b>COLUMN_DATA_TYPE:</b>{" "}
              {`Replace <DATA_TYPE> with column data type.`}
              <br /> <br />
              <DocumentationNote
                text={`Please ensure to enter COLUMN_NAME in UPPER CASE.`}
              />
            </CCardText>
            <CCardText>
              <b>Step 5:</b> Run following command to check hawkeye data quality
              result.
              <br />
              <CodeView
                rows={1}
                codeText={`SELECT * FROM APP.HAWKEYE_VALIDATION;`}
              />
            </CCardText>
          </CCardText>
          <CCardText>
            5){" "}
            {`Run the following Stored Procedure for Anomaly Detection (please ensure there are 30 entries in the profile_report table for the mentioned table.)`}
            <br />
            <br />
            <DocumentationNote
              text={`Replace <APPLICATION_NAME> with the installed application name`}
            />
            <CodeView rows={1} codeText={`USE DATABASE<APPLICATION_NAME>;`} />
            <CodeView
              rows={1}
              codeText={`CALL CODE.HAWKEYE_ANOMALY ('<DATABASE_NAME>','<SCHEMA_NAME>',’<TABLE_NAME>’);`}
            />
            <br />
            <b>DATABASE_NAME:</b>{" "}
            {`The database name that contains the table for data profiling.`}
            <br />
            <b>SCHEMA_NAME: </b>
            {`The schema name within the database.`}
            <br />
            <b>TABLE_NAME:</b>{" "}
            {`The source table on which anomalies need to be identified.`}
            <br />
          </CCardText>
          <CCardText>
            6){" "}
            {`Run the following statement to check the anomaly report table.`}
            <br />
            <CodeView rows={1} codeText={`SELECT * FROM APP.ANOMALY_REPORT;`} />
          </CCardText>
        </CCard>
      </CCol>
      <CCol sm={2} className="p-0 m-0">
        <CCard className="p-2 pt-2 pb-2 example-link-card" style={{right:"25px"}}>
          <CCardText>
            You can explore the app by running the{" "}
            <a
              href="#sample-dataset-example"
              style={{ color: "#0000ff" }}
              onClick={() => props.setActiveKey(6)}
            >
              <b>Sample Dataset Example</b>
            </a>{" "}
            .
          </CCardText>
        </CCard>
      </CCol>
    </CContainer>
  );
}
export default DataPopulationStepsHawkeye;
