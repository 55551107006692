import React, { useState } from "react";
import { CInputGroup, CInputGroupText, CFormInput, CCol } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLowVision } from "@coreui/icons";
import "react-phone-number-input/style.css";
import PhoneInputWithCountrySelect from "react-phone-number-input";

export default function Input({
  handleChange,
  handleMobileChange,
  value,
  id,
  name,
  type,
  isRequired = false,
  placeholder,
  icon,
  autoComplete,
  autofocus,
}) {
  const [visible, setVisible] = useState(false);
  return id == "mobile" ? (
    <CCol className="mt-3 position-relative">
      <PhoneInputWithCountrySelect
        // international
        // countryCallingCodeEditable={false}
        defaultCountry="IN"
        placeholder="Enter mobile number"
        value={value}
        onChange={handleMobileChange}
        id={id}
        name={name}
      />
    </CCol>
  ) : (
    <CInputGroup className="mt-3 position-relative">
      <CInputGroupText className="bg-white">
        <CIcon icon={icon} size="sm" className="color" />
      </CInputGroupText>
      <CFormInput
        size="sm"
        id={id}
        type={type == "password" && !visible ? type : "text"}
        value={value}
        name={name}
        placeholder={placeholder}
        required={isRequired}
        onChange={handleChange}
        autoComplete={autoComplete}
        autoFocus={autofocus ? "autofocus" : null}
        // valid
        // feedback
      />

      {type == "password" ? (
        <CIcon
          className={`node ${visible ? "text-primary" : ""}`}
          style={{
            position: "absolute",
            right: "5px",
            top: "7px",
            zIndex: 200,
          }}
          icon={cilLowVision}
          onClick={() => setVisible(!visible)}
        />
      ) : null}
    </CInputGroup>
  );
}
