import { cilCheckAlt } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

export const PricingPoints = (props) => {
  return (
    <div className={`d-flex justify-items-center gap-2`}>
      <div style={{width:"30px"}}>
        <CIcon
          size="sm"
          icon={cilCheckAlt}
          className="check-icon"
          style={{ fontWeight: "900", width: "30px" }}
        />
      </div>

      <div>
        <p className={`d-flex justify-items-center gap-2 m-0`} style={{fontSize:"16px"}}>{props.text}</p>
      </div>
    </div>
  );
};
