// import { configureStore } from '@reduxjs/toolkit'
// import userDetailsReducer from './userDetails'

// const appStore = configureStore({
//   reducer: {
//     loginUserDetails:userDetailsReducer
//   },
// })

// export default appStore;

import { configureStore } from "@reduxjs/toolkit";
import userDetailsReducer from "./userDetail";
import sideBarReducer from "./sideBar";
import currentTableReducer from "./currentTable";
import configDetailsReducer from "./configDetails";
import outputConfigDetailsReducer from "./outputConfigDetails";
import sideBarTabCountReducer from "./sideBarTabCount";
const appStore = configureStore({
  reducer: {
    loginUserDetails: userDetailsReducer,
    sideBar: sideBarReducer,
    currentlySelectedTable: currentTableReducer,
    configDetails: configDetailsReducer,
    outputConfigDetails: outputConfigDetailsReducer,
    sideBarTabCountReducer: sideBarTabCountReducer,
  },
});
export default appStore;
