import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
} from "@coreui/react";
function Recommendations() {
  return (
    <>
      <CCardText className="document-text" id="anomaly-detection">
        {" "}
        Recommendations for the Current Month.
      </CCardText>
      <CCardText style={{ paddingLeft: 10 }}>
        On the recommendations page we are listing out the problems related to
        the snowflake account, the reason why this problem occurred and the
        recommendations in order to overcome these problems. We have also added
        the action items for the same. We have sorted these recommendations and
        data related to these problems based on categories. You can click on the
        "view report" option to get a detailed report on the problem statement.
        The categories are as follows:
      </CCardText>
      {/* TO ADD DROPDOWN HERE */}
      <CCard style={{ padding: 10 }}>
        <b>Billing Analysis: </b> 
        Here you can see the most expensive queries from the last 30 days. You
        can get additional details such as query configurations, query
        statistics, etc.
        <br />
        <br />
        <b>Performance: </b> Here you can see problem statements such as
        top 10 queries that scanned the most data, by partitions scanned and
        bytes scanned, top 10 local storage spillers and top users scanning most
        data and the recommendations to overcome these problems.
        <br />
        <br />
        <b>Resource Consumption: </b>
      For recommendations on resource consumption, we are showing
        problem statements and recommendations to optimize our usage and
        consumption of resources.
        <br />
        <br />
        <b>Configuration:</b>Here we are showing various problem
        statements such as, configurational errors like warehouses without auto
        suspend, no auto resume or no resource monitor, ideal warehouses, failed
        tasks and stale table streams.
        <br />
        <br />
        <b>Usage:</b>  Here we are showing usage related problems such as
        idle users, idle roles and the reason for these to occur in the problem
        statement and the recommendations to overcome these problems.
        <br />
        <br />
        <CCardText>
          <b> Recommendations Report: </b> <br />
          On the recommendations report page, we are showing the details of the
          problem, the reason why this problem occurred, and the recommendations
          and action items in order to overcome these problems in order to avoid
          the problem and improve the snowflake account usage.
        </CCardText>
        1) For Recommendations For The Current Month.
        <br />
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB_NAME>"};<br />
          CALL CODE.RECOMMENDATIONS_QUERY('APP');
          <br />
        </CCardText>
        2) To get a report of the recommendation, copy the category,
        object_type, problem_statement, and object_name from the recommendation
        and put them into the below query.
        <CCardText className="costnomics-doc-text">
          SELECT KEY_VALUES FROM APP.REPORT_RECOMMENDATIONS WHERE CATEGORY
          ='category' AND TYPE='object_type' AND
          PROBLEM_STATEMENT='problem_statement' AND IDENTIFIER='object_name';<br/>
        </CCardText>
        Example-<br/>
        <CCardText className="costnomics-doc-text">
          SELECT KEY_VALUES FROM APP.REPORT_RECOMMENDATIONS WHERE CATEGORY
          ='USAGE' AND TYPE='USER' AND PROBLEM_STATEMENT='idle user' AND
          IDENTIFIER='HEMANTH';
        </CCardText>
        <br />
      </CCard>
    </>
  );
}

export default Recommendations;
