import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentTablePath: "",
  currentTableId : "",
  currentJobId:"",
  currentDatabaseName : "",
  currentSchemaName : "",
  currentSchemaList : [],
  currentTableName : "",
  currentTableList : {},
  currentNumericColumnList: [],
  currentStringColumnList:[],
  currentDateColumnList: [],
  currentColumnName:"",
  currentDataType:"",
  currentJobRunId:"",
  currentJobDate: "",
  currentJiraStatuses: [],
  currentJobRunIdStatus: "",
  currentDateRange : "",
  currentStartDate:"",
  currentEndDate:"",
  currentIsApply:""
}

export const currentTableSlice = createSlice({
  name: 'currentTableSlice',
  initialState,
  reducers: {
    updateCurrentTablePath: (state,action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.currentTablePath= action.payload
    },
    updateCurrentTableId: (state,action) => {
        
        state.currentTableId= action.payload
      },
    updateCurrentDatabaseName : (state,action) => {
        
      state.currentDatabaseName= action.payload
      state.currentSchemaName =""
      state.currentTableId =""
      state.currentJobId=""

    },
    updateCurrentSchemaName : (state,action) => {
        
      state.currentSchemaName= action.payload
      state.currentTableName =""
      // state.currentTableList = {}
      state.currentNumericColumnList= []
      state.currentStringColumnList= []
      state.currentDateColumnList= []
      state.currentColumnName=""
      state.currentTableId =""
      state.currentJobId=""
      // state.currentStartDate=""
      // state.currentEndDate=""
    },
    updateCurrentSchemaList : (state,action) => {
      state.currentSchemaList= action.payload
    },
    updateCurrentTableName : (state,action) => {
        
      state.currentTableName= action.payload
      state.currentNumericColumnList= []
      state.currentStringColumnList= []
      state.currentDateColumnList= []
      state.currentColumnName=""
      state.currentJobDate =""
      state.currentJobRunId=""
      // state.currentStartDate=""
      // state.currentEndDate=""
      // state.currentIsApply=false

    },
    updateCurrentTableList : (state,action) => {
        
      state.currentTableList= action.payload
      state.currentTableName =""
      state.currentNumericColumnList= []
      state.currentStringColumnList= []
      state.currentDateColumnList= []
      state.currentColumnName=""
    },

    updateCurrentColumnName : (state,action) => {
      
      state.currentColumnName= action.payload
    },

    updateCurrentNumericColumnList : (state,action) => {

      state.currentNumericColumnList= action.payload
      state.currentColumnName=""
    },

    updateCurrentStringColumnList : (state,action) => {

      state.currentStringColumnList= action.payload
      state.currentColumnName=""
    },

    updateCurrentDateColumnList : (state,action) => {

      state.currentDateColumnList= action.payload
      state.currentColumnName=""
    },

    updatecurrentDataType : (state,action) => {
      
      state.currentDataType= action.payload
    },
    
    updatecurrentJobRunId : (state,action) => {
        
      state.currentJobRunId= action.payload
    },
    updatecurrentJobDate : (state,action) => {
        
      state.currentJobDate= action.payload
      state.currentJobRunId=""
    },
    updateCurrentJiraStatuses : (state,action) => {
          
        state.currentJiraStatuses= action.payload
      },
    updateCurrentJobRunIdStatus : (state,action) => {
        
      state.currentJobRunIdStatus= action.payload
    },
    updateCurrentJobId : (state,action) => {

      state.currentJobId= action.payload
    },
    updateDateRange : (state,action) => {
      state.currentDateRange= action.payload
    },
    updateStartDate : (state,action) => {
      state.currentStartDate= action.payload
      // state.currentIsApply=false

    },
    updateEndDate : (state,action) => {
      state.currentEndDate= action.payload
      // state.currentIsApply=false
    },
    updateIsApply : (state,action) => {
      state.currentIsApply= action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  updateCurrentTablePath, 
  updateCurrentTableId, 
  updateCurrentJobId,
  updateCurrentDatabaseName, 
  updateCurrentSchemaName,
  updateCurrentSchemaList, 
  updateCurrentTableName, 
  updateCurrentTableList,
  updateCurrentColumnName,
  updateCurrentNumericColumnList,
  updateCurrentStringColumnList,
  updateCurrentDateColumnList,
  updatecurrentDataType,
  updatecurrentJobRunId,
  updatecurrentJobDate,
  updateCurrentJiraStatuses,
  updateCurrentJobRunIdStatus,
  updateDateRange,
  updateStartDate,
  updateEndDate,
  updateIsApply
 } = currentTableSlice.actions

export default currentTableSlice.reducer