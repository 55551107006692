import React from "react";
import { CCard, CCardText, CImage } from "@coreui/react";
import { DocumentTitle } from "../component/DocumentTitle";
import { CodeView } from "../component/CodeView";
import { DocumentationNote } from "../component/DocumentationNote";
import DocumentationNoteInfo from "../component/DocumentationNoteInfo";

function SampleDatasetExampleCandour() {
  return (
    <>
      <DocumentTitle title={"Sample Dataset Example"} />
      <CCard style={{ padding: 10 }}>
        <CCardText>
          Follow the next steps to run the job on the sample dataset.
        </CCardText>
        <CodeView rows={1} codeText={`USE DATABASE <APPLICATION_NAME>;`} />
        <br />
        <CCardText>
          <b>Reference link for sample data: </b>"
          <a
            target="_blank"
            href="https://www.kaggle.com/datasets/meirnizri/covid19-dataset"
          >
            https://www.kaggle.com/datasets/meirnizri/covid19-dataset
          </a>
          "
        </CCardText>
        <CCardText>
          Step 1: Download the dataset using the above reference link. <br />
          Step 2: Upload 50% records as TRAINING_DATA by creating a table in the
          database and schema of your choice.
          <br />
          Step 3: Upload remaining 50% records as PRODUCTION_DATA by creating a
          table in the database and schema used in above step.
        </CCardText>
        <br />
        <DocumentationNote
          text={`1. Replace <DATABASE_NAME>  and <SCHEMA_NAME> with the name of the database and schema where sample data is populated.
2. Replace <TRAINING_DATA> and <PRODUCTION_DATA> with the respective table names created for training and production.
3. Replace <APPLICATION_NAME> with the application installed name.
4. Replace <MODEL_NAME> with one of model name from the list  ['LINEAR', 'GRADIENT','DECISIONTREE', 'RIDGE', 'LASSO']
5. Replace <TARGET> with the target column of your training dataset.`}
        />
        <br />
        <CCardText>
          Step 4: Execute following grant statements on sample dataset.
        </CCardText>
        <CodeView
          rows={10}
          codeText={`GRANT USAGE ON DATABASE <DATABASE_NAME> TO APPLICATION <APPLICATION_NAME>; 
GRANT USAGE ON SCHEMA <DATABASE_NAME> .<SCHEMA_NAME>  TO APPLICATION  <APPLICATION_NAME>; 
GRANT SELECT ON TABLE <DATABASE_NAME> .<SCHEMA_NAME>.<TRAINING_DATA> TO APPLICATION  <APPLICATION_NAME>; 
GRANT SELECT ON TABLE <DATABASE_NAME> .<SCHEMA_NAME>.<PRODUCTION_DATA> TO APPLICATION  <APPLICATION_NAME>; 
GRANT USAGE ON DATABASE <DATABASE_NAME> TO ROLE <ROLE_NAME>; 
GRANT USAGE ON SCHEMA<DATABASE_NAME> .<SCHEMA_NAME> TO ROLE <ROLE_NAME>; 
GRANT SELECT ON TABLE <DATABASE_NAME> .<SCHEMA_NAME>.<TRAINING_DATA> TO ROLE <ROLE_NAME>;
GRANT SELECT ON TABLE <DATABASE_NAME> .<SCHEMA_NAME>.<PRODUCTION_DATA> TO ROLE <ROLE_NAME>;`}
        />
        <br />
        <CCardText>
          Step 5: Follow the next steps to run the job on the sample dataset.
        </CCardText>
        <CodeView rows={1} codeText={`USE DATABASE <APPLICATION_NAME>;`} />
        <CodeView
          rows={3}
          codeText={`CALL CODE.CANDOUR('{"DB_REFERENCE":"<DATABASE_NAME>", "SCHEMA_REFERENCE":"<SCHEMA_NAME>", "TABLE_REFERENCE":"<TRAINING_DATA>", "DB_PRODUCTION":"<DATABASE_NAME>", "SCHEMA_PRODUCTION":"<SCHEMA_NAME>", "TABLE_PRODUCTION":"<PRODUCTION_DATA>"}');`}
        />

        <br />
        <CCardText>
          Step 6 (optional): Follow the next steps to run the job for optional
          regression analysis.
        </CCardText>
        <CodeView
          rows={1}
          codeText={`CALL CODE.REGRESSION_SP('{"DB_REFERENCE":"<DATABASE_NAME>","SCHEMA_REFERENCE": "<SCHEMA_NAME>","TABLE_REFERENCE":"<TRAINING_DATA>","MODEL_NAME":"<MODEL_NAME>","TARGET_COLUMN":"<TARGET>"}');`}
        />
        <br />
        <CCardText>
          Step 7 (optional): Follow the next steps to run the job for optional
          classification analysis.
        </CCardText>
        <CodeView
          rows={1}
          codeText={`CALL CODE.CLASSIFICATION_SP('{"DB_REFERENCE":"<DATABASE_NAME>","SCHEMA_REFERENCE": "<SCHEMA_NAME>","TABLE_REFERENCE":"<TRAINING_DATA>","MODEL_NAME":"<MODEL_NAME>","TARGET_COLUMN":"<TARGET>"}'); `}
        />
        <br />
        <DocumentationNoteInfo
          text={`FOLLOW THE STREAMLIT APP SECTION TO VIEW THE RESULTS`}
        />
      </CCard>
    </>
  );
}
export default SampleDatasetExampleCandour;
