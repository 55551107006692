import {
  CCol,
  CContainer,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import EnterpriseDashboard from "../../assets/marketingAssets/Features/enterprise_dashboard.png";
import Workload from "../../assets/marketingAssets/Features/workload1.png";
import Budget from "../../assets/marketingAssets/Features/budget.png";
import BudgetDetails from "../../assets/marketingAssets/Features/BudgetDetails.png";
import Monitoring from "../../assets/marketingAssets/Features/monitoring.png";
import Forcasts from "../../assets/marketingAssets/Features/forecast.png";
import ImageSlider from "./Reusable/ImageSlider";

export const Features = () => {
  const [activeKey, setActiveKey] = useState(1);
  const [isHovered, setIsHovered] = useState(false);
  const [slideInterval, setSlideInterval] = useState(null);

  useEffect(() => {
    if (!isHovered) {
      setSlideInterval(
        setInterval(() => {
          setActiveKey((prevKey) => (prevKey < 6 ? prevKey + 1 : 1));
        }, 3000)
      );
    } else {
      clearInterval(slideInterval);
    }

    return () => {
      clearInterval(slideInterval);
    };
  }, [isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleNavItemClick = (key) => {
    setActiveKey(key);
    setIsHovered(false);
    clearInterval(slideInterval);
  };
  return (
    <CContainer style={{ paddingBottom: "50px" }}>
      <CRow className="pb-4 pt-4">
        <CCol>
          <CNav className="justify-content-center gap-3 mb-4">
            <CNavItem>
              <CNavLink
                active
                onClick={() => handleNavItemClick(1)}
                className={`features-nav-link cursor-pointer ${
                  activeKey === 1 ? "features-nav-link-active" : null
                }`}
              >
                Enterprise-Dashboard
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                onClick={() => handleNavItemClick(2)}
                className={`features-nav-link cursor-pointer ${
                  activeKey === 2 ? "features-nav-link-active" : null
                }`}
              >
                Workload
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                onClick={() => handleNavItemClick(3)}
                className={`features-nav-link cursor-pointer ${
                  activeKey === 3 ? "features-nav-link-active" : null
                }`}
              >
                Budget
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                onClick={() => handleNavItemClick(4)}
                className={`features-nav-link cursor-pointer ${
                  activeKey === 4 ? "features-nav-link-active" : null
                }`}
              >
                Monitoring
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                onClick={() => handleNavItemClick(5)}
                className={`features-nav-link cursor-pointer ${
                  activeKey === 5 ? "features-nav-link-active" : null
                }`}
              >
                Forecasts
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                onClick={() => handleNavItemClick(6)}
                className={`features-nav-link cursor-pointer ${
                  activeKey === 6 ? "features-nav-link-active" : null
                }`}
              >
                Dashboards
              </CNavLink>
            </CNavItem>
          </CNav>
        </CCol>
      </CRow>
      {activeKey === 1 && (
        <CRow className="justify-content-center align-items-center gap-3 ">
          <CCol
            xs={12}
            sm={12}
            md={6}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={EnterpriseDashboard}
              alt="enterpriseDashboard"
              className="features-img"
            />
          </CCol>
          <CCol
            sm={4}
            className="features-text"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            A comparative dashboard in CostNomics™ allows users to compare and
            analyze cost data across multiple accounts. It provides a
            consolidated view of key cost metrics, trends, and performance
            indicators, enabling users to make informed decisions and identify
            areas for cost optimization.
          </CCol>
        </CRow>
      )}

      {activeKey === 2 && (
        <CRow className="justify-content-center align-items-center gap-3 ">
          <CCol
            sm={6}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img src={Workload} alt="workload" className="features-img" />
          </CCol>
          <CCol
            sm={4}
            className="features-text"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Maximize cost efficiency and enhance workload performance with our
            powerful platform, enabling you to achieve optimal results while
            minimizing expenses. Gain valuable insights and make data-driven
            decisions to optimize resource allocation and drive business growth.
          </CCol>
        </CRow>
      )}

      {activeKey === 3 && (
        <CRow className="justify-content-center align-items-center gap-3 ">
          <CCol sm={6} className="flip-container">
            <CCol
              className="flipper"
              sm={12}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img src={BudgetDetails} alt="budget" className="front" />
            </CCol>
            <CCol
              className="flipper"
              sm={12}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img src={Budget} alt="budgetDetails" className="back" />
            </CCol>
          </CCol>
          <CCol
            sm={4}
            className="features-text"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Efficiently manage your budgets with CostNomics™ comprehensive
            budget management features. Set budget goals, allocate funds, and
            track expenses in real-time to ensure optimal financial control.
            Receive notifications and alerts to stay informed and take proactive
            measures to maintain budget compliance.
          </CCol>
        </CRow>
      )}
      {activeKey === 4 && (
        <CRow className="justify-content-center align-items-center gap-3 ">
          <CCol
            sm={6}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img src={Monitoring} alt="monitoring" className="features-img" />
          </CCol>
          <CCol
            sm={4}
            className="features-text"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Stay on top of your costs with CostNomics™ powerful alert monitoring
            feature. Configure customized alerts based on cost thresholds,
            anomalies, or budget breaches to proactively address any
            cost-related issues. Choose your preferred alert types, frequency,
            and recipients, and seamlessly integrate alert monitoring with other
            features for comprehensive cost management.
          </CCol>
        </CRow>
      )}

      {activeKey === 5 && (
        <CRow className="justify-content-center align-items-center gap-3 ">
          <CCol
            sm={6}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img src={Forcasts} alt="forcasts" className="features-img" />
          </CCol>
          <CCol
            sm={4}
            className="features-text"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            The Forecasting feature in CostNomics™ offers insights into actual
            and projected monthly expenditure, empowering organizations to make
            informed financial decisions. It provides detailed information on
            total forecasted amount, total used amount, unused amount, and a
            monthly breakdown for accurate budget tracking and optimization.
          </CCol>
        </CRow>
      )}
      {activeKey === 6 && (
        <CRow className="p-0 m-0">
          <ImageSlider />
        </CRow>
      )}
    </CContainer>
  );
};
