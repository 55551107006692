import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
} from "@coreui/react";
// import CostnomicsImg from 'src/assets/images/CostNomics_Logo_doc.png'
// import companyLogo from 'src/assets/images/Atgeir_Logo.png'

function DataPopulationSteps() {
  return (
    <>
      <CCardText className="document-text">
        {" "}
        First-Time Data Population Steps.
      </CCardText>

      <CCard style={{ padding: 10 }}>
        <CCardText>
          This section contains the steps that native app users should execute
          after post installation steps of the native application to populate
          data to KPI tables.
        </CCardText>
        <CCardText>
          1) Create Budget : Users can create a budget using the following
          stored procedure. <br />
          <br />
          <CCardText className="costnomics-doc-text" style={{ paddingLeft: "20px" }}>
            {"//<BUDGET_NAME>"}: A budget name is an identifier for the budget and
            it must be unique.
            <br />
            {"//<BUDGET_SCOPE>"}: The budget you create will be assigned to the
            selected scope. The scope can be set to Account, Storage, KPI and
            Tag.
            <br />
            {"//<BUDGET_SUB_SCOPE>"}: The budget sub-scope defines the object name
            of the scope type. Example : If the budget scope is an account or
            storage, then the budget sub scope must be a valid account name.
            <br />
            {"//<RESET_PERIOD>"}: The reset period defines the duration for the
            budget. It can be either in months, quarters, or years.
            <br />
            {"//<RESET_PERIOD_DURATION>"}: This is the number of
            months/quarters/years that you have set the budget for.
            <br />
            {"//<BUDGET_AMOUNT>"}: The budget amount is the total allocated amount
            for a specified budget.
            <br />
            {"//[<EMAIL_ADDRESS1>, <EMAIL_ADDRESS2>,....]"}: The Email addresses
            to notify the user about daily budget usage. <br />
            
          </CCardText>
          <CCardText className="ps-3">User can also set the alerts using following parameters:</CCardText>
         
            <CCardText className="costnomics-doc-text" style={{ paddingLeft: "20px" }}>
              {"//<ALERT1>"}: The first alert is Yellow alert and it must be
              greater than zero.
              <br />
              {"//<ALERT2>"}: The second alert is Orange alert and it must be
              greater than the first alert.
              <br />
              {"//<ALERT3>"}: The third alert is Red alert and it must be greater
              than the above two alerts alerts <br />
            </CCardText>
        </CCardText>
        <CCardText className="costnomics-doc-text">
          CALL CODE.CREATE_BUDGET{" "}
          {
            "('<BUDGET_NAME>', '<BUDGET_SCOPE>', '<BUDGET_SUB_SCOPE>', '<RESET_PERIOD>', <RESET_PERIOD_DURATION>, <BUDGET_AMOUNT>, ['<EMAIL_ADDRESS1>', '<EMAIL_ADDRESS2>',....], <ALERT1>, <ALERT2>, <ALERT3>)"
          }
          ;
        </CCardText>
        <CCardText style={{ paddingLeft: "20px" }}>
          Example of a user created budget :
        </CCardText>
        <CCardText className="costnomics-doc-text">
          CALL code.CREATE_BUDGET('Account Budget', 'Account', 'AFA78268',
          'Quarterly',2,1500, ['user@gmail.com', 'test_user@gmail.com'], 50, 70,
          90);
        </CCardText>
        <CCardText>
          2) Run the following Stored Procedure for Data Population.
        </CCardText>
        <CCardText className="costnomics-doc-text">
          {/* //{"<BUDGET_NAME>"}: Name of the budget created in the previous step.
          <br />
          <br /> */}
          USE DATABASE {"<DB-NAME>"}; <br />
          <br />
          CALL CODE.CAPTURE_METRICES();
        </CCardText>

        {/* <CCardText style={{ paddingLeft: "20px" }}>
          Example of a user created budget :
        </CCardText>
        <CCardText className="costnomics-doc-text">
          CALL CODE.CAPTURE_METRICES('Account_Budget');
        </CCardText> */}
        <CCardText>
            3) Run following statements to verify if KPI tables are populated.
            The output of the following statements should be 0 or more than 0.
        </CCardText>
        
        <CCardText className="costnomics-doc-text">
          
          <CCard style={{ padding: 10 }}>
            // BILLING_METRICS_SP <br />
            SELECT COUNT(*) FROM APP.CUMULATIVE_BILLING_METRICS;
            <br />
            SELECT COUNT(*) FROM APP.WAREHOUSE_METERING_HISTORY;
            <br />
            <br />
            //CONFIGURATION_SP <br />
            SELECT COUNT(*) FROM APP.WAREHOUSES_WITHOUT_PROPER_CONFIGURATIONS;
            <br />
            <br />
            //FORCAST_OTIME_SP
            <br />
            SELECT COUNT(*) FROM APP.FORECASTING_USAGE_AND_BILLING;
            <br />
            <br />
            //PERFORMANCE_SP
            <br />
            SELECT COUNT(*) FROM APP.HEAVY_SCANNER;
            <br />
            <br />
            //USAGE_MONITORING_SP
            <br />
            SELECT COUNT(*) FROM APP.QUERY_HISTORY;
            <br />
            SELECT COUNT(*) FROM APP.CREDIT_CONSUMPTION_BY_USER;
            <br />
            <br />
            //RECOMMENDATIONS_SP
            <br />
            SELECT COUNT(*) FROM APP.RECOMMENDATIONS;
            <br />
          </CCard>
        </CCardText>
      </CCard>
    </>
  );
}
export default DataPopulationSteps;
