import React from "react";
import { CCard, CCardText } from "@coreui/react";
import { DocumentationNote } from "../component/DocumentationNote";
import { CodeView } from "../component/CodeView";
import DocumentationNoteInfo from "../component/DocumentationNoteInfo";
import { DocumentTitle } from "../component/DocumentTitle";
function PostInstallationSetupHawkeye() {
  return (
    <>
      <DocumentTitle title={"Post-Installation Setup"} />
      <CCard style={{ padding: 10 }}>
        <CCardText>
          1) Execute the following grant statement to grant application role to
          the created role.
        </CCardText>
        <DocumentationNote
          text={`Replace <APPLICATION_NAME> and <ROLE_NAME> with the name of application and role created previously. `}
        />
        <CodeView
          rows={1}
          codeText={`GRANT APPLICATION ROLE <APPLICATION_NAME>.HAWKEYE TO ROLE <ROLE_NAME>;`}
        />
        <br />
        <CCardText>
          2) Execute the following statements for additional grants on the
          database schema and table on which the user wants profiling, labeling
          and anomaly detection to be executed.
        </CCardText>
        <DocumentationNote
          text={`Execute below grant statements if the source table does not belong to the application.
          Replace <APPLICATION_NAME> with the installed application name.
          Replace <DB_NAME> with the database  name.
          Replace <SCHEMA_NAME> with the schema  name.`}
        />
        <CodeView
          rows={3}
          codeText={`GRANT USAGE ON DATABASE <DB_NAME> TO APPLICATION <APPLICATION_NAME>;
GRANT USAGE ON SCHEMA <DB_NAME>.<SCHEMA_NAME> TO APPLICATION <APPLICATION_NAME>;
GRANT SELECT ON TABLE <DB_NAME>.<SCHEMA_NAME>.<TABLE_NAME> TO APPLICATION <APPLICATION_NAME>;`}
        />
        <br />
        <CCardText>
          3) Execute the following statements for additional grants on the
          created role.
        </CCardText>
        <DocumentationNote
          text={`Execute below grant statements if the source table does not belong to the application.
          Replace <ROLE_NAME> with name of the role created previously. 
          Replace <DB_NAME> with the database name that contains the table for data profiling
          Replace <SCHEMA_NAME> with the schema name within the database.
          Replace <TABLE_NAME> with table name on which data profiling is to be obtained`}
        />

        <CodeView
          rows={3}
          codeText={`GRANT USAGE ON DATABASE <DB_NAME> TO APPLICATION <ROLE_NAME>;
GRANT USAGE ON SCHEMA <DB_NAME>.<SCHEMA_NAME> TO APPLICATION <ROLE_NAME>;
GRANT SELECT ON TABLE <DB_NAME>.<SCHEMA_NAME>.<TABLE_NAME> TO APPLICATION <ROLE_NAME>;`}
        />
        <br />
        <DocumentationNoteInfo
          text={`YOU HAVE COMPLETED ALL POST-INSTALLATION STEPS. NOW, RUN FIRST-TIME DATA POPULATION STEPS AS PER THE NEXT SECTION.`}
        />
      </CCard>
    </>
  );
}

export default PostInstallationSetupHawkeye;
