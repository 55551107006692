import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    SF_Output_ApiUrl: "",
    SF_Output_Warehouse: "",
    SF_Output_Role: "",
    SF_Output_Database: "",
    SF_Output_Schema: "",
    SF_Output_Configurations:{}
}

export const outputConfigDetailsSlice = createSlice({
    name: 'outputConfigDetailsSlice',
    initialState,
    reducers: {
        updateSFOutputApiUrl: (state, action) => {
            state.SF_Output_ApiUrl = action.payload
        },
        updateSFOutputWarehouse: (state, action) => {
            state.SF_Output_Warehouse = action.payload
        },
        updateSFOutputRole: (state, action) => {
            state.SF_Output_Role = action.payload
        },
        updateSFOutputDatabase: (state, action) => {
            state.SF_Output_Database = action.payload
        },
        updateSFOutputSchema: (state, action) => {
            state.SF_Output_Schema = action.payload
        },
        updateSFOutputConfigurations: (state, action) => {
            state.SF_Output_Configurations = action.payload
        },
    },
})

export const {
    updateSFOutputApiUrl,
    updateSFOutputWarehouse,
    updateSFOutputRole,
    updateSFOutputDatabase,
    updateSFOutputSchema,
    updateSFOutputConfigurations

} = outputConfigDetailsSlice.actions

export default outputConfigDetailsSlice.reducer