import React from "react";
import {
  CCard,
  CContainer,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CCardImage,
} from "@coreui/react";
// import CostnomicsImg from 'src/assets/images/CostNomics_Logo_doc.png'
// import companyLogo from 'src/assets/images/Atgeir_Logo.png'

function PostInstallationSetup() {
  return (
    <>
      <CCardText className="document-text"> Post-Installation setup.</CCardText>

      <CCard style={{ padding: 10 }}>
        <CCardText className="mb-0">
          This section contains steps that native app users should execute after
          subscribing the native application from Snowflake marketplace.
        </CCardText>
        <CCardText className="mb-0" style={{ padding: 15 }}>
          <b>
            <u>NOTES:</u>
            <br />
            <br />
            <u style={{ paddingLeft: "30px" }}>
              1. You Need To Replace {"<DB_NAME>"} In The Steps Below With The
              Name Used During Subscribing The Costnomics™ Native App.
              <br />
              <br />
            </u>
            <u style={{ paddingLeft: "30px" }}>
              2. Do Not Change Schema Names “app” And “code” In The Steps.
            </u>
          </b>
        </CCardText>
        <br />
        <CCardText>
          1) Execute the following command to Grant the Role
          <b> “datageir_costnomics_prod_role” </b> to the created Database.
        </CCardText>
        <CCardText className="costnomics-doc-text">
        GRANT ROLE DATAGEIR_COSTNOMICS_PROD_ROLE TO DATABASE {"<DB_NAME>"};
        </CCardText>
        <CCardText className="costnomics-doc-text">
        GRANT ROLE ACCOUNTADMIN TO DATABASE {"<DB_NAME>"};
        </CCardText>
        2) Execute the following statements for additional grants.
        <CCardText style={{ padding: 15, paddingBottom: "5px" }}>
          <b>
            <u>NOTE: Replace {"<USER_NAME>"} With Name Of The User.</u>
          </b>
        </CCardText>
        <CCardText className="costnomics-doc-text">
        GRANT ROLE DATAGEIR_COSTNOMICS_PROD_ROLE TO USER {"<USER_NAME>"}; <br />
        USE ROLE DATAGEIR_COSTNOMICS_PROD_ROLE; <br /> USE DATABASE {"<DB-NAME>"};{" "}
          <br /> CALL CODE.GRANT_REQUIRED_ACCESS();
        </CCardText>
        <CCardText>
          3) Execute the following Stored Procedure for additional setup.
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB-NAME>"};<br />
          CALL CODE.SETUP_SP(); <br />
          CALL CODE.SETUP_SP_V2(); <br />
          {/* CALL CODE.SETUP_SP_V3(); <br /> */}
          {/* CALL CODE.SETUP_SP_V4();<br /> */}
          CALL CODE.SETUP_SP_V5();
        </CCardText>
        <CCardText>
          4) Insert base parameters that will change on Snowflake account basis.
          Some parameters for KPI thresholds beyond which you want data to be
          captured. It’s a stored procedure call.
        </CCardText>
        <CCardText className="costnomics-doc-text">
          USE DATABASE {"<DB-NAME>"};<br />
          // {"<VALUE1>"}: Snowflake Per credit cost in Dollars{" "}
          <br />
          // {"<VALUE2>"}: Total amount to be allocated for snowflake term
         
          <br />
          // {"<VALUE3>"}: Define long running query by setting the value of
          threshold parameter in seconds
          <br />
          // {"<VALUE4>"}: Define long running task by setting the value of task
          threshold parameter in seconds
          <br />
          // {"<VALUE5>"}: Snowflake Storage Costs Per TB in Dollars (Range: 1 to 10000)
          <br />
          <br />
          CALL CODE.CREATE_CONFIG_PROPERTY{" "}
          {"('<VALUE1>', '<VALUE2>', '<VALUE3>', '<VALUE4>','<VALUE5>')"};
        </CCardText>
        {/* <CCardText>
          5) Execute the following Stored Procedure for additional setup.
        </CCardText>
        <CCardText className="costnomics-doc-text">
          use database {"<"}DB-NAME{">"}; <br />
          call costnomics_setup.SHARED_TABLES_SP();
        </CCardText> */}
        <CCardText style={{ padding: 15 }}>
          <b>
            <u>
              YOU HAVE COMPLETED ALL POST-INSTALLATION STEPS. THEN, RUN
              FIRST-TIME DATA POPULATION STEPS AS PER THE NEXT SECTION.
            </u>
          </b>
        </CCardText>
      </CCard>
    </>
  );
}

export default PostInstallationSetup;
