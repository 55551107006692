import atgeirLogoLight from "../../../assets/marketingAssets/Atgeir_Logo_Light.svg"
export const NavbrandLogo = (props) => {
  return (
    <>
      <img
        src={atgeirLogoLight}
        alt="atgeir logo"
        width="155"
        height="50"
        className="d-inline-block align-top p-1"
      />
    </>
  );
};
